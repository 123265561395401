import {API, Auth} from 'aws-amplify';

const apiName = 'PortfoliosApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class PortfolioService {

    create(data) {
        tickersInit.body = data;
        return API.post(apiName, `/addTicker`, tickersInit);
    }

    getPortfolio(username) {
        return API.get(apiName, `/${username}`, tickersInit);
    }

    getPublicPortfolio(username, followed) {
        return API.get(apiName, `/public/${username}?followed=${followed}`, tickersInit);
    }

    deleteTicker(id, username) {
        return API.del(apiName, `/delete/${id}/${username}`, tickersInit);
    }

    getTop10(type) {
        return API.get(apiName, `/top10?type=${type}`, tickersInit);
    }

    getUsernameRanking(type, username) {
        return API.get(apiName, `/usernameRanking?type=${type}&username=${username}`, tickersInit);
    }
}

export default new PortfolioService();
