import React from "react";
import Panel from "../Panel";
import TickerService from '../../services/TickerService';
import SentimentService from "../../services/SentimentService";
import SearchBarAuto from "../SearchBarAuto";
import TickerChartContainer from "./TickerChartContainer";
import News from "../News/News";
import {FiEyeOff, FiEye, FiRefreshCcw, FiThumbsDown, FiThumbsUp} from "react-icons/fi";
import {Auth} from "aws-amplify";
import WatchlistService from "../../services/WatchlistService";
import Alert from "../ui/Alert";
import {withRouter} from "react-router-dom";
import CommentBox from "../Comments/CommentBox";

//TODO display 15 minute delayed price
class ChartWrapper extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            username: '',
            filterText: '',
            index: 0,
            stats: [],
            lastData: [],
            sentiment: {},
            hasSymbolWatchlist: false,
            watchlistId: '',
            notFoundError: '',
            showNews: true,
            showComments: false
        }

        this.retrieveTickerStat = this.retrieveTickerStat.bind(this);
        this.retrieveTickerLast = this.retrieveTickerLast.bind(this);
        this.getUsername = this.getUsername.bind(this);
        // this.saveSentiment = this.saveSentiment.bind(this);
        this.getSentiment = this.getSentiment.bind(this);
        this.hasSymbolInWatchlist = this.hasSymbolInWatchlist.bind(this);
        this.addToWatchlist = this.addToWatchlist.bind(this);
        this.removeFromWatchlist = this.removeFromWatchlist.bind(this);
    }

    componentDidMount() {
        this.retrieveTickerLast(this.props.valSymbol).then(r => {
            if (this.state.notFoundError === '') {
                this.retrieveTickerStat(this.props.valSymbol);
                    this.getUsername().then(r => {
                            this.getSentiment();
                            this.hasSymbolInWatchlist();
                        }
                    );
                }
            }
        );
    }

    retrieveTickerLast = async (tickerSymbol)  => {
        if(tickerSymbol != null && tickerSymbol !== undefined) {
            const { match, location, history } = this.props;
            const urlParams = new URLSearchParams(location.search);

            await TickerService.getLast(tickerSymbol, urlParams.get('sector'))
                .then(data => {
                    this.setState({
                        lastData: data[0],
                        notFoundError: ''
                    });
                    console.log(data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response != null && error.response.status !== 404) {
                        this.setState({
                            notFoundError: "Unknown symbol"
                        });

                    }
                });
        }
    }

    retrieveTickerStat(tickerSymbol) {
        if(tickerSymbol != null && tickerSymbol !== undefined) {
            TickerService.getStats(tickerSymbol)
                .then(data => {
                    this.setState({
                        stats: data,
                        notFoundError: ''
                    });
                    console.log(data);
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response != null && error.response.status !== 404) {
                        // this.setState({
                            // notFoundError: "Unknown symbol"
                        // });
                        console.log(error.message);
                    }
                    this.setState({
                        stats: [],
                    });
                });
        }
    }

    refreshTickerLast(tickerSymbol, e) {
        e.preventDefault();
        this.retrieveTickerLast(tickerSymbol);
    }

    getUsername = async () => {

        await Auth.currentUserInfo()
            .then(userInfo => {
                console.log(userInfo);
                console.log(userInfo.attributes.email);
                this.setState({
                    username: userInfo.username
                });
            })
            .catch(err => {
                    console.log(err);
                }
            );
    }

    saveSentiment(value, e) {
        // 0 = down ; 1 = up
        console.log('saveSentiment value=' + value);
        e.preventDefault();
        SentimentService.saveSentiment(this.state.username, this.props.valSymbol, value)
            .then(data => {
                console.log(data);

                this.setState({
                    sentiment: data
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    getSentiment() {
        SentimentService.getSentiment(this.state.username, this.props.valSymbol)
            .then(data => {
                console.log(data);
                this.setState({
                    sentiment: data
                });
            })
            .catch(error => {
                console.log('failed');
                console.log(error.response);
            });
    }

    hasSymbolInWatchlist() {
        WatchlistService.hasSymbol(this.state.username, this.props.valSymbol)
            .then(data => {
                console.log(data);
                if (data) {
                    this.setState({
                        hasSymbolWatchlist: true,
                        watchlistId: data.id
                    });
                } else {
                    this.setState({
                        hasSymbolWatchlist: false,
                        watchlistId: ''
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    addToWatchlist() {
        WatchlistService.add(this.state.username, this.props.valSymbol)
            .then(data => {
                console.log(data);
                this.setState({
                    hasSymbolWatchlist: true,
                    watchlistId: data.id
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    removeFromWatchlist() {
        if (this.state.watchlistId) {
            console.log(this.state.watchlistId);
            WatchlistService.delete(this.state.watchlistId)
                .then(data => {
                    console.log(data);
                    this.setState({
                        hasSymbolWatchlist: false,
                        watchlistId: ''
                    });
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }

    render(){

        const titles = [
            {
                label: '52 Week High',
                text: `${this.state.stats && this.state.stats.technicals ? this.state.stats.technicals.week52high : ''}`
            },
            {
                label: '52 Week Low',
                text: `${this.state.stats && this.state.stats.technicals ? this.state.stats.technicals.week52low : ''}`
            },
            {
                label: 'Market Cap',
                text: `${this.state.stats && this.state.stats.highlights && this.state.stats.highlights.marketCapitalization !== undefined ?
                    new Intl.NumberFormat().format(this.state.stats.highlights.marketCapitalization) : '-'}`
            },
            // {label: 'UPCOMING EARNINGS', text: `${this.state.stats.nextEarningsDate}`},
            {
                label: 'Employees',
                text: `${this.state.stats && this.state.stats.general ? this.state.stats.general.fullTimeEmployees : ''}`
            },
            {
                label: 'Yield', text: `${this.state.stats && this.state.stats.highlights
                && this.state.stats.highlights.dividendYield != 0 ? this.state.stats.highlights.dividendYield : '-'}`
            },
            {
                label: 'Ex Dividend Date', text:
                    `${this.state.stats && this.state.stats.splitsDividends ? this.state.stats.splitsDividends.exDividendDate : '-'}`
            },
            {
                label: 'Next Dividend', text:
                    `${this.state.stats && this.state.stats.splitsDividends ? this.state.stats.splitsDividends.dividendDate : '-'}`
            },
            // {label: 'EPS', text: `${this.state.stats.ttmEPS}`},
            {
                label: 'P/E',
                text: `${this.state.stats && this.state.stats.highlights ? this.state.stats.highlights.peRatio : '-'}`
            },

        ];
        return (
            <div className="tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10">
                {this.state.notFoundError !== '' &&
                <Alert
                    message={this.state.notFoundError}
                    color="red" />
                }
                <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-pb-4 lg:tw-pb-8">
                    <span className="tw-text-2xl tw-font-extrabold"></span>
                    <SearchBarAuto />
                </div>
                {this.state.notFoundError === '' && <>
                <div className="tw-mx-auto">
                    <div className="tw-grid tw-gap-8 tw-grid-cols-1 lg:tw-grid-cols-4">

                        <div
                            className="lg:tw-col-span-1 tw-text-left tw-bg-white tw-rounded-3xl dark:tw-bg-gradient-blue tw-p-4">

                            {this.state.lastData && <>
                                <div className="tw-text-xl tw-font-semibold tw-text-gray-500 dark:tw-text-light">
                                    {this.state.lastData.symbol}
                                    <span
                                        className="tw-text-sm tw-font-light tw-text-gray-400 dark:tw-text-primary-light">
                                        {this.state.stats && this.state.stats.general ? ` - ${this.state.stats.general.name}` : ''}
                                    </span>
                                </div>

                                <div
                                    className="tw-font-semibold tw-text-gray-500 tw-mt-2 tw-flex dark:tw-text-light">
                                    <div className="tw-mr-4 tw-text-2xl">
                                        {new Intl.NumberFormat("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 3
                                        }).format(this.state.lastData.price)}
                                    </div>
                                    <div className="tw-mt-0.5 tw-text-lg">
                                        <button className=""
                                                onClick={this.refreshTickerLast.bind(this, this.props.valSymbol)}>
                                            <FiRefreshCcw className="tw-text-primary-yellowDark dark:tw-text-primary-yellow
                                                                    hover:tw-text-primary-yellow dark:hover:tw-text-primary-yellowDark" />
                                        </button>
                                    </div>
                                </div>
                                <div className="tw-text-sm tw-font-light tw-text-primary-purple">
                                    {/* FOR IEX use time*/}
                                    {/*multiplied by 1000 so that the argument is in milliseconds, not seconds.*/}
                                    Updated: {new Date(this.state.lastData.timestamp * 1000).toLocaleString()}
                                </div>
                            </>
                            }

                                <div className="tw-flex tw-text-lg tw-mt-2">
                                    <div className="tw-w-1/2 tw-bg-red-400 dark:tw-text-primary-pink tw-rounded-md tw-text-center">
                                        <div className="tw-h-2 tw-bg-green-400 dark:tw-text-primary-green tw-rounded-md tw-text-center"
                                             style={{width: `${(this.state.sentiment.totalUp / (this.state.sentiment.totalUp + this.state.sentiment.totalDown)) * 100}%`}}
                                        ></div>
                                    </div>
                                </div>

                            <div className="tw-w-1/2">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <div>
                                        <button className="tw-mt-2" onClick={this.saveSentiment.bind(this, 1)}><FiThumbsUp
                                        className="tw-text-green-500 dark:tw-text-primary-green
                                        hover:tw-text-green-200 dark:hover:tw-text-green-200" /></button>
                                        {/*<br />*/}
                                        {/*<div*/}
                                        {/*    className="tw-text-sm tw-font-light tw-text-gray-300 dark:tw-text-primary-light tw--mt-0.5">{this.state.sentiment.totalUp}</div>*/}
                                    </div>
                                    <div>
                                        <button className="tw-mt-2" onClick={this.saveSentiment.bind(this, 0)}><FiThumbsDown
                                            className="tw-text-red-400 dark:tw-text-primary-pink
                                            hover:tw-text-red-200 dark:hover:tw-text-red-200" /></button>
                                        {/*<br />*/}
                                        {/*<div*/}
                                        {/*    className="tw-text-sm tw-font-light tw-text-gray-300 dark:tw-text-primary-light tw--mt-0.5">{this.state.sentiment.totalDown}</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="tw-mt-2">
                                {this.state.hasSymbolWatchlist ?
                                    <button type="button" onClick={this.removeFromWatchlist}
                                            className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                    >
                                        <div className="tw-inline-block"><FiEyeOff /></div>
                                        <div className="tw-inline-block tw-ml-2">Unwatch</div>
                                    </button>
                                    :
                                    <button type="button" onClick={this.addToWatchlist}
                                            className="tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                    >
                                        <div className="tw-inline-block"><FiEye /></div>
                                        <div className="tw-inline-block tw-ml-2">Watch</div>
                                    </button>
                                }
                            </div>

                        </div>
                        <div className="lg:tw-col-span-3 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent tw-p-4">
                            <Panel key={`stats-container-id-${this.props.valSymbol}`} titles={titles} />
                            <div className="tw-mt-2
                            tw-text-sm tw-text-gray-400 dark:tw-text-light">
                                <span className="tw-overflow-ellipsis tw-overflow-hidden tw-leading-5 tw-break-words">
                                    {this.state.stats && this.state.stats.general ? <>
                                        <span className="tw-text-base tw-font-semibold tw-text-gray-400 dark:tw-text-primary-light">Profile:</span> {this.state.stats.general.description}</> : ''}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="tw-row-start-1 tw-row-span-1 tw-grid tw-grid-cols-1 tw-gap-8 tw-pt-8">
                        <div className="tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent tw-p-4 tw-h-76">
                            <div className="tw-mb-2 tw-text-gray-500 dark:tw-text-primary-purple"><span
                                className="tw-text-xl tw-font-semibold">{this.props.valSymbol} </span>
                            </div>
                            <TickerChartContainer key={`chart-container-id-${this.props.valSymbol}`} symbol={this.props.valSymbol} />
                        </div>
                    </div>
                    {/*<div className="tw-mt-8 tw-mx-auto">*/}
                    {/*    {this.state.stats && this.state.stats.length > 0 && <Panel key={`stats-container-id-${this.props.valSymbol}`} titles={titles} />}*/}
                    {/*</div>*/}
                    {/*<div className="tw-mt-8">*/}
                    {/*    <Panel titles={titles}/>*/}
                    {/*</div>*/}
                    <div className="tw-grid tw-gap-8 tw-grid-cols-1 lg:tw-grid-cols-2 tw-my-8">
                        <div className="tw-text-left tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent tw-p-4 tw-max-h-72">
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <span className="tw-text-lg tw-font-semibold tw-text-gray-500 dark:tw-text-primary-light">
                                    Recent News
                                </span>
                            </div>
                            <News key={`news-container-id-${this.props.valSymbol}`} symbol={this.props.valSymbol} />
                        </div>
                        <div className="tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent tw-p-4">
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <span className="tw-text-lg tw-font-semibold tw-text-gray-500 dark:tw-text-primary-light">
                                    Join the Discussion!
                                </span>
                            </div>
                            <CommentBox symbol={this.props.valSymbol} username={this.state.username} />
                        </div>
                    </div>
                </>}

            </div>
        )
    }

}

export default withRouter(ChartWrapper);
