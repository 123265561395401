import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Prices from "./Prices";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Billing = (props) => {
    return (
        <Elements stripe={stripePromise}>
            {
                (props.email && props.userId) &&
                <Prices key="subscription-prices" email={props.email} userId={props.userId} />
            }
        </Elements>
    );
};

export default Billing;