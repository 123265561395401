import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import {FiMenu} from "react-icons/fi";

export default function NavbarUnauthenticated() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
            <header className="tw-relative">

                <nav
                    className="tw-relative tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-p-2 lg:tw-px-20">
                    {/*<div className="tw-container tw-flex tw-flex-wrap tw-items-center tw-justify-between">*/}
                        <div
                            className="tw-w-full tw-relative tw-flex tw-justify-between lg:tw-w-auto lg:tw-static lg:tw-block lg:tw-justify-start">
                            <NavLink to="/">
                                <img src="/logo.svg" className="App-logo tw-inline-block" alt="logo" />
                                {/*<span className="tw-text-2xl tw-inline-block tw-text-primary-dark dark:tw-text-light">Trades League</span>*/}
                            </NavLink>
                            <button
                                className="tw-text-black dark:tw-text-primary-light tw-cursor-pointer tw-text-xl tw-leading-none tw-px-3 tw-py-1 tw-border tw-border-solid tw-border-transparent tw-rounded tw-bg-transparent tw-block lg:tw-hidden tw-outline-none focus:tw-outline-none"
                                type="button"
                                onClick={() => setNavbarOpen(!navbarOpen)}
                            >
                                <FiMenu />
                            </button>
                        </div>
                    <div
                        className={
                            "lg:tw-flex tw-flex-grow tw-items-center" +
                            (navbarOpen ? " tw-flex" : " tw-hidden")
                        }
                        id="navbar-danger"
                    >
                        <div className="tw-flex tw-flex-col tw-space-x-2 tw-items-center tw-text-center lg:tw-flex-row tw-list-none lg:tw-ml-auto">

                            <NavLink to="/login">
                                <div
                                    className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                    Log In
                                </div>
                            </NavLink>

                            <NavLink to="/signUp">
                                <div
                                    className="tw-p-2 tw-pr-0 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                    Sign Up
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}