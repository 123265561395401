import React from "react";
import {Price} from "../Price";
import {NavLink} from "react-router-dom";

export const QuoteItem = ({
                              symbol,
                              sector,
                              primaryExchange,
                              companyName,
                              price,
                              change
                          }) => {

    return (

        <div
            className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-white
                       dark:tw-bg-gradient-blue tw-bg-no-repeat tw-bg-right-bottom tw-rounded-3xl">
            <NavLink to={`/ticker/${primaryExchange !== null ? `${symbol}.${primaryExchange}` : symbol}`}
                     className="tw-group"> {/* sector  !== null && sector.toLowerCase() === 'cryptocurrency' ? symbol + '?sector=' + sector */}
                <div className="tw-flex tw-items-center tw-justify-between">
                    <h6 className="tw-text-xs tw-font-medium tw-leading-none tw-tracking-wider tw-text-gray-300 tw-uppercase dark:tw-text-primary-light">
                        <span className="tw-text-sm tw-font-semibold tw-text-primary-blueText dark:tw-text-light">{symbol}</span> {companyName ? `- ${companyName}` : ''}
                    </h6>
                </div>
                <span className="tw-text-xl tw-font-semibold tw-text-gray-500 dark:tw-text-light
                                 group-hover:tw-text-primary-lighter dark:group-hover:tw-text-primary-light">
                    <Price showSymbol={true} numberStyle={`currency`}>{price}</Price>
                </span>
                <span
                    className={`tw-inline-block tw-px-2 tw-py-px tw-ml-2 tw-text-xs
                                ${change < 0 ? 'tw-text-primary-pink' : 'tw-text-green-500 dark:tw-text-primary-green'}`}>
                    <Price showSymbol={false} numberStyle={`percent`}>
                        {change}
                    </Price>
                </span>
            </NavLink>
        </div>
    );
};