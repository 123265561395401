import React, {Suspense} from "react";
import {Price} from "../Price";
import {FriendStatuses, SubscriberStatus} from "./FriendEnums";
import {FiCheck, FiLoader, FiX} from "react-icons/fi";
const Avatar = React.lazy(() => import('../ui/Avatar'));

export const RankingItem = ({
                                       index,
                                       username,
                                       email,
                                       change,
                                       status,
                                       updateStatus
                                   }) => {

    const handleApprove = () => {
        console.log("approve");
        updateStatus(email, FriendStatuses.APPROVED);
    };

    const handleDeny = () => {
        console.log("deny");
        updateStatus(email, FriendStatuses.DENIED);
    };

    return (
        <>
            <div
                className="tw-grid tw-grid-cols-8 tw-gap-0 tw-text-sm tw-text-gray-500 tw-items-center
                           hover:tw-bg-primary-lighter dark:tw-text-light dark:hover:tw-bg-primary-light">
                <div className="tw-flex-none tw-col-span-4">
                    <div className="tw-justify-start tw-flex tw-flex-wrap tw-items-center tw-space-x-2">
                        <span className="tw-font-semibold">{index + 1}. </span>
                        <Suspense fallback={<FiLoader className="tw-animate-spin tw-h-6 tw-w-6 tw-text-yellow-500" />}>
                            <Avatar key={`avatar-friend-${username}`} username={username} className={`tw-w-8 tw-h-8`} />
                        </Suspense>
                        <span className="tw-font-semibold"> {username}</span>
                    </div>
                </div>
                <div className="tw-col-span-4 tw-text-right">

                    {status && status === FriendStatuses.SENT &&
                    <div className="">{FriendStatuses.SENT}</div>
                    }
                    {status && status === FriendStatuses.PENDING &&
                    <>
                        <div className="tw-flex tw-justify-end tw-text-xl">
                            <button onClick={handleApprove}>
                                <FiCheck className="tw-text-green-500 dark:tw-text-primary-green hover:tw-text-green-200" />
                            </button>
                            <button onClick={handleDeny}>
                                <FiX className="tw-ml-4 tw-text-red-600 hover:tw-text-red-400" />
                            </button>

                        </div>
                    </>
                    }
                    {status && (status === FriendStatuses.APPROVED || status === SubscriberStatus.ACTIVE) &&
                    <div className="">
                        <Price numberStyle={`percent`} showSymbol={false} className={`tw-text-green-500 dark:tw-text-primary-green`}
                               includeBrackets={false}>
                            {change}
                        </Price>
                    </div>
                    }

                </div>
            </div>
        </>);
};
