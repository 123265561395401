import React from "react";
import PropTypes from "prop-types";
import TickerService from "../../services/TickerService";
import Loader from "../ui/Loader";
import NewsListItem from "./NewsListItem";
import NewsList from "./NewsList";
import '../../index.css';

class News extends React.Component {
    _isMounted = false;

    constructor() {
        super();
        this.state = {
            loading: true,
            newsData: []
        };
    }

    getLatestNews() {

        if (this.props.symbol != null && this.props.symbol !== undefined) {
            TickerService.news(this.props.symbol)
                .then(data => {
                    this.setState({newsData: data});
                })
                .then(() => {
                    if (this._isMounted) {
                        this.setState({loading: false});
                    }
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getLatestNews();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <>
            {
                this.state.loading || (this.state.newsData == null || this.state.newsData.length === 0) ? <div></div> :

            <div className="tw-space-y-20 sm:tw-space-y-32 md:tw-space-y-40 lg:tw-space-y-44">
                {/*<div className="tw-grid"> */}
                <>
                    <div className="tw-relative tw-col-start-1 tw-col-end-3 lg:tw-col-end-3 tw-row-start-2 tw-row-end-3
                    lg:tw-row-start-3 lg:tw-row-end-4 tw-self-center tw-pl-8 sm:tw-px-6 md:tw-px-8 tw-pt-6 md:tw-pt-8 lg:tw-px-0 lg:tw-pt-0">
                        <div className="tw-relative tw-z-10 tw-rounded-tl-xl sm:tw-rounded-t-xl tw-divide-y tw-divide-gray-100">
                            <div className="tw-divide-y tw-divide-gray-100">
                                <NewsList>
                                    {this.state.newsData.map((news, index) => (
                                        <NewsListItem key={news.lang + '_' +index} news={news} />
                                    ))}
                                </NewsList>
                                {this.state.newsData.length === 0 && !this.state.loading && (
                                    <h3>Sorry, we couldn't find any related news.</h3>
                                )}
                            </div>
                        </div>
                    </div>
                </>
                {this.state.loading && <Loader />}
            </div>
            }
            </>
        );
    }
}

News.propTypes = {
    symbol: PropTypes.string,
};

export default News;
