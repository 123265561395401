import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {FiPlus, FiUserPlus} from "react-icons/fi";
import LoaderButton from "../loaderButton";
import UserService from "../../services/UserService";
import {useAppContext} from "../../libs/contextLib";
import {RankingList} from "./RankingList";
import {Audit} from "./Audit";
import {AuditTypes} from "./FriendEnums";

export function Friends() {
    const { isAuthenticated, authenticatedUser } = useAppContext();
    const [username, setUsername] = useState(authenticatedUser.username);
    const [user, setUser] = useState(null);
    const [showAddFriend, setShowAddFriend] = useState(false);
    const [emailFriend, setEmailFriend] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [friendsList, setFriendsList] = useState(false);
    const [addErrorMsg, setAddErrorMsg] = useState("")
    const [componentKey, setComponentKey] = useState("")
    const {pathname} = useLocation();

    async function retrieveFriends() {
        // get friends from user
        console.log("get friends from user fct " + username);
        const user = await UserService.retrieveFriends(username)
            .then(d => {return d;})
            .catch(err => {console.log(err); return null});
        // console.log(user);
        if (user) {
            setUser(user);
            setFriendsList(user.friends);
            if (user.friends) {
                createComponentKey(user.friends);
            }
        }
        return user;
    }

    useEffect(() => {
        async function fetchData() {
           // get friends from user
            console.log("get friends from user " + username);
            const user = await retrieveFriends();
            // console.log(user);
        }

        fetchData();

    }, [username]);

    const toggleFriendAdd = () => (
        setShowAddFriend(!showAddFriend)
    );

    const createComponentKey = (friends) => {
        const part1 = friends.map(e => e.status).join("-");
        console.log(part1);
        setComponentKey(part1 + friends.length);
    };

    const handleAddFriend = async(event) => {
        event.preventDefault();
        setIsLoading(true);
        if (username && user) {
            console.log("emailFriend=" + emailFriend);
            await UserService.addFriend(user.id, emailFriend)
                .then(d => {
                    setFriendsList(d.friends);
                    setUser(d);
                    setShowAddFriend(false);
                    setEmailFriend("");
                    setAddErrorMsg("");
                    createComponentKey(d.friends);
                })
                .catch(err => {
                    console.log(err.message);
                    setAddErrorMsg("Email doesn't exist");
                });
        } else {
            console.log("username missing");
        }
        setIsLoading(false);
    };

    return (
        <>
            <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                {/*header*/}
                <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                    <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText
                                     dark:tw-text-primary-light">
                        Friends
                    </span>
                    {/*<div className="tw-flex tw-text-white dark:tw-text-darker dark:hover:tw-text-light hover:tw-text-gray-500">*/}
                    {/*    <button type="button"*/}
                    {/*            onClick={toggleFriendAdd}>*/}
                    {/*        <FiUserPlus className="tw-text-2xl tw-text-yellow-400 hover:tw-text-yellow-200" />*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    <div className="tw-relative">
                        <div className="tw-w-8 tw-h-8 tw-bg-gradient-blue-light tw-rounded-full
                                        hover:tw-filter hover:tw-drop-shadow-blue-dark"
                        >
                            <button type="button"
                                    onClick={toggleFriendAdd}>
                                <FiPlus className="tw-w-6 tw-h-6 tw-absolute tw-m-auto tw-inset-0 tw-text-white"/>
                            </button>
                        </div>
                    </div>
                </div>
                {showAddFriend &&
                <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b dark:tw-border-primary">
                    <form onSubmit={handleAddFriend} className="tw-w-full">
                        <span className="tw-text-red-600"> {addErrorMsg} </span>
                        <div className="tw-grid tw-grid-cols-3 tw-gap-6">

                            <div className="tw-col-span-2">
                                <input
                                    className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                    type="email"
                                    id="emailFriend"
                                    name="emailFriend"
                                    placeholder="Email"
                                    value={emailFriend}
                                    onChange={(e) => setEmailFriend(e.target.value)}
                                    required
                                />
                            </div>
                                <div>
                                <LoaderButton type="submit"
                                              isLoading={isLoading}
                                              className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white
                                              tw-transition-colors tw-duration-200 tw-rounded-3xl
                                              disabled:hover:tw-bg-gradient-blue-light
                                              tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark
                                              focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                >
                                    Add
                                </LoaderButton>
                                </div>

                        </div>
                    </form>
                </div>
                }
                <div className="tw-relative tw-p-4 tw-h-72">
                    {/*Content*/}
                    {friendsList && friendsList.length > 0 &&
                    <RankingList key={`friends-home-id-${componentKey}`} friendsList={friendsList} userId={user.id}
                                 type={AuditTypes.FRIENDS}
                                 retrieveFriends={retrieveFriends}/>
                    }
                    {(!friendsList || friendsList.length === 0) &&
                    <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">
                        No Friends Yet
                    </div>}
                </div>
            </div>
            {/*{pathname.includes("league") ?*/}
                <div className="tw-col-span-2 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">

                    <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                            Friends' Latest Activity
                        </span>
                        <div
                            className="tw-flex tw-text-white dark:tw-text-darker dark:hover:tw-text-light hover:tw-text-gray-500">

                        </div>
                    </div>

                    <div className="tw-p-4">

                        {user != null && user.id ?
                            <Audit key={`friends-audit-id-${user.id}`}
                                   userId={user.id}
                                   type={AuditTypes.FRIENDS} /> : ''}
                    </div>
                </div>
                {/*: ''}*/}
        </>
    );
}