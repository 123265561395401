import React from 'react';
import {Auth} from 'aws-amplify';
import {NavLink, useHistory} from "react-router-dom";
import {useAppContext} from "../../libs/contextLib";

export default function SignOut() {
    const {isAuthenticated, userHasAuthenticated, authenticatedUser, setAuthenticatedUser} = useAppContext();
    const history = useHistory();

    async function signOut() {
        // const history = useHistory();
        await Auth.signOut();
        console.log(authenticatedUser);
        console.log(isAuthenticated);
        userHasAuthenticated(false);
        setAuthenticatedUser(null);

        //TODO see if needed, will sign out the user from FB too
        // window.FB.logout(function(response) {
        //     // user is now logged out
        //     console.log("FB logout");
        //     console.log(response);
        // });
        history.push("/login");
    }

    return (
        <NavLink to="/logout" onClick={signOut}>
            <div
                className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 tw-transition-colors hover:tw-bg-gray-100 dark:tw-text-light dark:hover:tw-bg-primary">
                Logout
            </div>
        </NavLink>
    )

}
