import React from "react";
import {NavLink} from "react-router-dom";
import {FiThumbsUp} from "react-icons/fi";

class SuccessMessage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (

            <div className="sm:tw-flex sm:tw-items-start">
                <div
                    className="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-green-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                    <FiThumbsUp className="tw-h-6 tw-w-6 tw-text-green-600" />
                </div>
                <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                    <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900">
                        {this.props.title}
                    </h3>
                    <div className="tw-mt-2">
                        <p className="tw-text-sm tw-text-gray-500">
                            {this.props.text ? this.props.text : ''}
                                Click <NavLink to="/login"
                                                   className="tw-text-sm tw-text-primary hover:tw-text-primary-lighter">here</NavLink> to
                                    login with your new credentials.

                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuccessMessage;