import React from "react";
import { WatchlistItem } from './WatchlistItem';
import { useState, useCallback } from 'react';
import update from 'immutability-helper';
import WatchlistService from "../../services/WatchlistService";

const style = {
    // width: 300,
    // minWidth: 300
};
export const Watchlist = ({watchlist}) => {
    {
        const [cards, setCards] = useState(watchlist);
        const moveCard = useCallback((dragIndex, hoverIndex) => {
            console.log(cards);
            const dragCard = cards[dragIndex];
            console.log("dragIndex="+dragIndex + " hoverIndex="+hoverIndex + " id="+dragCard.id);
            setCards(update(cards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }));
            console.log("after");
            console.log(cards);
        }, [cards]);

        const updateOrder = useCallback((id, username, symbol, newOrder) => {
            console.log("updateOrder" + id + " " + username + " " + symbol + " " + newOrder);
            WatchlistService.updateWatchlistOrder(id, username, symbol, newOrder)
                .then(data => {
                    console.log("updateOrder success");
                    setCards(data);
                })
                .catch(error => {
                    console.log(error.response);
                });

        }, [cards]);

        const renderCard = (card, index) => {
            return (<WatchlistItem key={card.id} index={index} moveCard={moveCard} updateOrder={updateOrder}
                                   id={card.id}
                                   username={card.username}
                                   symbol={card.symbol}
                                   text={card.quote ? card.quote.companyName : ""}
                                   currentPrice={card.quote ? card.quote.latestPrice : null}
                                   changePercent={card.quote ? card.quote.changePercent : null}
                                   changePrice={card.quote ? card.quote.change : null}
            />);
        };
        return (<>
            {/*tw-w-80 tw-min-w-min*/}
            <div style={style} className="tw-rounded tw-overflow-y-auto tw-h-60 tw-max-h-60">
                {cards.map((card, i) => renderCard(card, i))}
            </div>
        </>);

    }
};
