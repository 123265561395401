import React, {useEffect, useState} from 'react';
import {FiCast} from "react-icons/fi";
import {useAppContext} from "../../libs/contextLib";
import {RankingList} from "./RankingList";
import {Audit} from "./Audit";
import {AuditTypes} from "./FriendEnums";
import {retrieveUser} from "../../libs/getUsername";

export function Subscribers() {
    const { isAuthenticated, authenticatedUser } = useAppContext();
    const [username, setUsername] = useState(authenticatedUser.username);
    const [user, setUser] = useState(null);
    const [subscribersList, setSubscribersList] = useState(false);


    useEffect(() => {
        async function fetchData() {
            // get subscribers from user
            console.log("get subscribers from user " + username);
            const user = await retrieveUser(username, "");
            setUser(user);
            if (user) {
                setSubscribersList(user.subscribers);
            }
            console.log("subscribers", user);
        }

        fetchData();

    }, [username]);


    return (
        <>
            <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                {/*header*/}
                <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                    <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                        Following
                    </span>
                    <div className="tw-text-lg tw-font-semibold tw-text-gray-500 dark:tw-text-primary-light">
                        {/*<FiCast className="tw-text-2xl" />*/}
                    </div>
                </div>
                <div className="tw-relative tw-p-4 tw-h-72">
                    {/*Content*/}
                    {subscribersList && subscribersList.length > 0 &&
                    <RankingList friendsList={subscribersList}
                                 userId={user.id}
                                 type={AuditTypes.SUBSCRIBERS} />
                    }
                    {(!subscribersList || subscribersList.length === 0) &&
                    <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">You didn't subscribe to anyone yet</div>}
                </div>
            </div>

            <div className="tw-col-span-2 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">

                <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                    <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                        Subscribers' Latest Activity
                    </span>
                    <div
                        className="tw-flex tw-text-lg tw-font-semibold tw-text-gray-500 dark:tw-text-primary-light">

                    </div>
                </div>

                <div className="tw-p-4">

                    {user != null && user.id ?
                        <Audit key={`subscribers-audit-id-${user.id}`} userId={user.id}
                               type={AuditTypes.SUBSCRIBERS} /> : ''}
                </div>
            </div>

        </>
    );
}