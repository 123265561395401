import React from "react";
import {defaults} from "react-chartjs-2";
import "chartjs-plugin-annotation";

import Loader from "../ui/Loader";
import TickerChart from "./TickerChart";
import TickerService from "../../services/TickerService";
import {chartOptions} from "./ChartOptions";

defaults.global.defaultFontStyle = "Bold";
defaults.global.defaultFontFamily = "Nunito Sans";
defaults.global.animation.duration = 200;


var options = chartOptions;

const apiKeys = [
  "SAOS0Y8B63XM4DPK",
  "4LPH6E70R1XQR2L5",
  "NOBPQ2OPX7E1XRT3",
  "7V0Q0N46CBIPHA2K",
];

let symbol;

// CHARTS

let chartData1 = [];
let labels = [];
let closePrice;

let allChart = [];
let allLabels = [];

let oneYear = [];
let oneYearLabels = [];

let ytdChart = [];
let ytdLabels = [];

let oneMonth = [];
let oneMonthLabels = [];

let oneWeek = [];
let oneWeekLabels = [];

let oneDay = [];
let oneDayLabels = [];

export default class TickerChartContainer extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loaded: "",
      accountValue: "",
      changeColor: "",
      extendedColor: "",
      marketStatus: "",
      latestPrice: "",
      buyConfirmation: "",
    };
    this.results = React.createRef();
    this.day = React.createRef();
    this.month = React.createRef();
    this.year = React.createRef();
    this.years = React.createRef();
    this.ytd = React.createRef();
    this.allData = React.createRef();

    this.retrieveTickerHistory = this.retrieveTickerHistory.bind(this);
    this.changeFocus = this.changeFocus.bind(this);
    this.getAllDataChart = this.getAllDataChart.bind(this);
    this.getYTDChart = this.getYTDChart.bind(this);
    this.getOneMonthChart = this.getOneMonthChart.bind(this);
    this.getOneYearChart = this.getOneYearChart.bind(this);
    this.getOneWeekChart = this.getOneWeekChart.bind(this);
    this.getOneDayChart = this.getOneDayChart.bind(this);

    this.data1 = canvas => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 600, 10);
      gradient.addColorStop(0, "#7c83ff");
      gradient.addColorStop(1, "#7cf4ff");
      let gradientFill = ctx.createLinearGradient(0, 0, 0, 100);
      gradientFill.addColorStop(0, "rgba(124, 131, 255,.3)");
      gradientFill.addColorStop(0.2, "rgba(124, 244, 255,.15)");
      gradientFill.addColorStop(1, "rgba(255, 255, 255, 0)");
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 4;
      return {
        labels,
        datasets: [
          {
            lineTension: 0.1,
            label: "",
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            borderColor: gradient,
            backgroundColor: gradientFill,
            pointBackgroundColor: gradient,
            fill: true,
            borderWidth: 2,
            data: chartData1,
          },
        ],
      };
    };
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  retrieveTickerHistory(tickerSymbol, range, rangeChart, rangeLabels) {
    if (this.props.symbol != null && this.props.symbol !== undefined
        && range != null && range !== undefined) {

      labels = [];
      chartData1 = [];
      TickerService.getHistory(this.props.symbol, range)
          .then(data => {
            // this.setState({
            //   chartData: data
            // });

            data.map((row) => {
              if (row.close !== null) {
                chartData1.push(row.close.toFixed(2));
                labels.push(row.label);
              }
            });
          })
          .then(() => {
            if (this._isMounted) {
              this.setState({
                loaded: true,
              });

              chartData1.map(val => rangeChart.push(val));
              labels.map(val => rangeLabels.push(val));
            }
          })
          .catch(error => {
            console.log(error.response);
          });
    }
  }

  getOneDayChart() {
    const anno = {
      annotations: [
        {
          borderDash: [2, 2],
          drawTime: "afterDatasetsDraw",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: closePrice,
          borderColor: "#676976",
          borderWidth: 1,
        },
      ],
    };
    // labels = [];
    // chartData1 = [];

    if (oneDay.length === 0 || oneDay[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "dynamic", oneDay, oneDayLabels);
    } else {
      labels = oneDayLabels;
      chartData1 = oneDay;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = anno;
  }

  getAllDataChart() {
    if (allChart.length === 0 || allChart[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "max", allChart, allLabels);
    } else {
      labels = allLabels;
      chartData1 = allChart;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = "";
  }

  getYTDChart() {
    // labels = [];
    // chartData1 = [];
    if (ytdChart.length === 0 || ytdChart[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "ytd", ytdChart, ytdLabels);
    } else {
      labels = ytdLabels;
      chartData1 = ytdChart;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = "";
  }

  getOneYearChart() {
    labels = [];
    chartData1 = [];
    if (oneYear.length === 0 || oneYear[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "1y", oneYear, oneYearLabels);
    } else {
      labels = oneYearLabels;
      chartData1 = oneYear;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = "";
  }

  getOneWeekChart() {
    labels = [];
    chartData1 = [];
    if (oneWeek.length === 0 || oneWeek[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "5dm", oneWeek, oneWeekLabels);
    } else {
      labels = oneWeekLabels;
      chartData1 = oneWeek;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = "";
  }

  getOneMonthChart() {
    labels = [];
    chartData1 = [];
    if (oneMonth.length === 0 || oneMonth[0].symbol !== this.props.symbol) {
      this.retrieveTickerHistory(this.props.symbol, "1m", oneMonth, oneMonthLabels);
    } else {
      labels = oneMonthLabels;
      chartData1 = oneMonth;
      if (this._isMounted) {
        this.setState({
          loaded: true,
        });
      }
    }
    options.annotation = "";
  }
  /*
   * converts number to short version of milions, bilions etc.
   * @param {number} number to convert
   * @param {decPlaces} how many decimal places
   */
  abbrNum(number, decPlaces) {
    decPlaces = Math.pow(10, decPlaces);
    var abbrev = ["k", "m", "b", "t"];
    for (var i = abbrev.length - 1; i >= 0; i--) {
      var size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        number += abbrev[parseInt(i)];
        break;
      }
    }

    return number;
  }
  /*
   * check i value is in array
   * @param {arr} array
   * @param {val} value
   */
  isInArray(arr, val) {
    return arr.indexOf(val) > -1;
  }

  /*
   * changes look of buttons above chart
   * @param {option} selected option
   */

  changeFocus(option) {
    setTimeout(
      function() {
        // TODO replace document.querySelector by refs.
        //document.querySelector should be avoided with React. Use refs when you need to access DOM elements.
        var elems = document.querySelectorAll(".Chart__option");

        [].forEach.call(elems, function(el) {
          el.classList.remove("active");
        });
        switch (option) {
          case 1:
            this.day.current.classList.add("active");
            break;

          case 2:
            this.month.current.classList.add("active");
            break;

          case 3:
            this.year.current.classList.add("active");
            break;

          case 4:
            this.years.current.classList.add("active");
            break;

          case 5:
            this.ytd.current.classList.add("active");
            break;

          case 6:
            this.allData.current.classList.add("active");
            break;

          default:
            this.day.current.classList.add("active");
            break;
        }
      }.bind(this),
      200,
    );
  }

  rendering() {
    //TODO check if market open to get 1d chart

    // this.getOneDayChart();
    this.getOneWeekChart();
    // this.changeFocus(4);
  }

  componentDidMount() {
    this._isMounted = true;
    this.rendering();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <section className="stock">

          <div style={{display: "flex", height: "100%"}}>

            <div className="tw-w-full">
              {this.state.loaded ? (
                <div className="tw-w-full">
                  <TickerChart
                    changeFocus={this.changeFocus}
                    getAllDataChart={this.getAllDataChart}
                    getOneMonthChart={this.getOneMonthChart}
                    getOneYearChart={this.getOneYearChart}
                    getOneWeekChart={this.getOneWeekChart}
                    getYTDChart={this.getYTDChart}
                    getOneDayChart={this.getOneDayChart}
                    data1={this.data1}
                    year={this.year}
                    years={this.years}
                    allData={this.allData}
                    ytd={this.ytd}
                    month={this.month}
                    day={this.day}
                  />
                </div>
              ) : (
                <Loader />
              )}

            </div>
          </div>

      </section>
    );
  }
}
