import React from "react";
import {FiMoreVertical} from "react-icons/fi";
import {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {ItemTypes} from './ItemTypes';
import {Price} from "../Price";
import {NavLink} from "react-router-dom";

const style = {
    // border: '1px dashed gray',
    padding: '0.25rem 0.5rem',
    // marginBottom: '.5rem',
    // backgroundColor: 'white',
    // width: '20rem',
};
const handleStyle = {
    // backgroundColor: 'green',
    width: '1rem',
    height: '1rem',
    display: 'inline-block',
    marginRight: '0.75rem',
    cursor: 'move',
};
export const WatchlistItem = ({
                                  index, moveCard, updateOrder, id,
                                  username,
                                  symbol,
                                  text,
                                  currentPrice,
                                  changePercent,
                                  changePrice
                              }) => {

    const ref = useRef(null);
    const [{handlerId}, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
        drop: () => ({
            name: `Dustbin`
        }),
    });

    const [{isDragging}, drag, preview] = useDrag({
        item: {type: ItemTypes.CARD, id, index, symbol, username},
        end(item, monitor) {
            const dropResult = monitor.getDropResult();

            if (item) {
                console.log("dropResult=" + dropResult);
                updateOrder(item.id, item.username, item.symbol, item.index);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),

    });
    const opacity = isDragging ? 0 : 1;
    if (ref !== null && ref !== undefined) {
        drag(drop(ref));
    }
    return (
        <div ref={preview} style={{...style, opacity}}>
            <NavLink to={`/ticker/${symbol}`}>
                <div className="tw-grid tw-grid-cols-8 tw-gap-0 tw-text-sm tw-text-gray-500
                                hover:tw-bg-primary-lighter dark:tw-text-light dark:hover:tw-bg-primary-light">
                    <div className="tw-mt-2.5" ref={ref} style={handleStyle} data-handler-id={handlerId}><FiMoreVertical /> </div>
                    <div className="tw-flex-none tw-col-span-4">
                        <div className="tw-font-semibold">{symbol}</div>
                        <div className="tw-text-xs tw-text-gray-400 dark:tw-text-primary-purple tw-truncate">{text}</div>
                    </div>
                    <div className="tw-col-span-3 tw-text-right">
                        <div className="tw-font-semibold"><Price showSymbol={false}>{currentPrice}</Price></div>
                        <div className="tw-text-xs"><Price showSymbol={false} className={`tw-text-green-500 dark:tw-text-primary-green`}>{changePrice}</Price> <Price numberStyle={`percent`} showSymbol={false} className={`tw-text-green-500 dark:tw-text-primary-green`} includeBrackets={true}>{changePercent}</Price></div>
                    </div>
                </div>
            </NavLink>
        </div>);
};
