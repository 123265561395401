import {API, Auth} from 'aws-amplify';

const apiName = 'AuditsApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class AuditService {

    retrievePortfolioAudit(userId, type, page) {
        return API.get(apiName, `/portfolio?id=${userId}&type=${type}&page=${page}&size=20&sort=createdDate,desc`, tickersInit);
    }

}

export default new AuditService();
