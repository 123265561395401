import React from "react";
import useDarkMode from "../../libs/useDarkMode";
import {FiMoon, FiSun} from "react-icons/fi";

export default function DarkToggle({btnRef}) {
    const [colorTheme, setTheme] = useDarkMode();

    return (

            <button ref={btnRef} onClick={() => setTheme(colorTheme)}
                    className="tw-p-2 tw-transition-colors tw-duration-200
                               tw-flex tw-flex-col tw-items-center tw-justify-center
                               hover:tw-text-primary-blueText dark:hover:tw-text-primary-lighter
                               focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                               active:tw-text-primary-lighter dark:active:tw-text-primary-lighter
                               tw-text-primary-purple dark:tw-text-light dark:active:tw-text-primary-lighter
                               ">
                {colorTheme === 'tw-light' ? (
                    <FiSun className="tw-w-7 tw-h-7 tw-fill-current" />
                ) : (
                    <FiMoon className="tw-w-7 tw-h-7 tw-fill-current" />
                )}
            </button>

    );
}