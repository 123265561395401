import React, {useEffect, useState} from 'react';
import './App.css';
import './index.css';
import Header from "./components/Header/Header";
import Footer from "./components/footer";
import Amplify, {Auth} from "aws-amplify";
import {AppContext} from "./libs/contextLib";
import Routes from "./components/Routes/Routes";
import awsconfig from './aws-config';
import SidebarAuthenticated from "./components/Header/SidebarAuthenticated";


function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        loadFacebookSDK();
        try {
            console.log("in load first");
            await Auth.currentSession()
                .then(data => {
                    const transformSessionToUser = {
                        username: data.getAccessToken().payload.username,
                        attributes: {
                            email: data.getIdToken().payload.email,
                            given_name: data.getIdToken().payload.given_name,
                            family_name: data.getIdToken().payload.family_name
                        }
                    };
                    setAuthenticatedUser(transformSessionToUser);
                }); // or currentAuthenticatedUser()
            userHasAuthenticated(true);
        } catch (e) {
            if (e !== 'No current user' && e !== "not authenticated") {
                console.log(e);
                alert(e);
            }
            userHasAuthenticated(false);
            setAuthenticatedUser(null);
        }

        setIsAuthenticating(false);
    }

    function loadFacebookSDK() {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                autoLogAppEvents: true,
                cookie: true,
                xfbml: true,
                version: 'v10.0'
            });
            window.FB.AppEvents.logPageView();
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }


    const appContextValues = {
        isAuthenticated,
        userHasAuthenticated,
        authenticatedUser,
        setAuthenticatedUser
    };

    return (
        !isAuthenticating &&
        <>
            <AppContext.Provider value={appContextValues}>
                <div
                    className="tw-relative tw-flex tw-h-full md:tw-h-screen tw-antialiased tw-text-gray-900 tw-bg-blue-seventy
                    dark:tw-bg-gradient-blue-dark dark:tw-text-light tw-font-sans">

                    {isAuthenticated && <SidebarAuthenticated /> }

                    <div className="tw-flex tw-h-screen tw-flex-col tw-w-full tw-overflow-x-hidden tw-overflow-y-auto">

                        <Header isAuthenticated={isAuthenticated} userHasAuthenticated={userHasAuthenticated} />
                        <main>
                            <Routes />

                            {/*<div id="content">*/}
                            {/*</div>*/}
                        </main>
                        <Footer />
                    </div>
                </div>
            </AppContext.Provider>
        </>
    );
}


export default App;
