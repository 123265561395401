import React, {useCallback, useEffect, useState} from "react";
import {FiUser} from "react-icons/fi";
import {Auth} from "@aws-amplify/auth";
import {useFormFields} from "../libs/hooksLib";
import {getCurrentUserInfo, retrieveUser} from "../libs/getUsername";
import UserService from "../services/UserService";
import Alert from "../components/ui/Alert";
import AvatarEdit from "../components/ui/AvatarEdit";
import Billing from "../components/Subscription/Billing";
import {useLocation} from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";


function Settings() {
    const { pathname, search } = useLocation();
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState( "");
    const [userDB, setUserDB] = useState({});
    const [avatarImg, setAvatarImg] = useState(null);
    const [showAvatarUpdate, setShowAvatarUpdate] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        username: "",
        description: "",
        default_mode: "light",
        trading_type: ""
    });

    const [fieldsPwd, handleFieldPwdChange] = useFormFields({
        current_password: "",
        new_password: "",
        password_confirmation: "",
    });

    const fetchUserDB = useCallback(async () => {

        let response = await getCurrentUserInfo();
        console.log("getCurrentUserInfo=");
        console.log(response);
        if (response === null) {
            return undefined;
        }

        const userDbResponse = await retrieveUser(response.username, response.attributes.email);
        console.log("userDbResponse");
        console.log(userDbResponse);

        if (userDbResponse !== null && !userDbResponse.length) {
            setUserDB(userDbResponse);
            setAvatarImg(userDbResponse.avatar);
            handleFieldChange({
                username: userDbResponse.username,
                description: userDbResponse.description ? userDbResponse.description : "",
                default_mode: userDbResponse.darkModeDefault != null ? userDbResponse.darkModeDefault : 'light'
            });
        }
    }, []); // if userId changes, useEffect will run again
    // if you want to run only once, just leave array empty []

    useEffect(() => {
        async function fetchData() {
            await fetchUserDB();
        }

        fetchData();

    }, []);

    function changePassword(e) {
        e.preventDefault();
        Auth.currentAuthenticatedUser()
            .then(user => {
                // console.log(user);
                return Auth.changePassword(user, fieldsPwd.current_password, fieldsPwd.new_password);
            })
            .then(data => {setSuccessMsg("Password changed successfully"); setErrorMsg("") })
            .catch(err => {
                setErrorMsg(err.message);
                setSuccessMsg("");
            });
    }

    async function saveProfile(e) {
        e.preventDefault();
        console.log(fields);
        const usernameField = fields.username && (fields.username !== userDB.username) ? fields.username : userDB.username;

        let postData = {
            ...userDB,
            username: usernameField,
            description: fields.description,
            darkModeDefault: fields.default_mode,
            tradingType: fields.trading_type,
            // avatarBase64: avatarImg
            avatar: avatarImg ? `${usernameField}/avatar-${usernameField}.png` : null
        }
        console.log(postData);
        const newUser = await UserService.update(userDB.id, postData)
            .then(r => {
                console.log(r);
                return r;
            }).then(data => {
                setSuccessMsg("Profile saved successfully");
                setErrorMsg("");
                return data;
            })
            .catch(err => {
                console.log(err);
                setErrorMsg(err.message);
                setSuccessMsg("");
            });
        console.log("newUser", newUser);
        setUserDB(newUser);
    }

    const saveAvatar = useCallback((img) => {
        console.log(img);
        setAvatarImg(img);
        setShowAvatarUpdate(false);
    }, [avatarImg]);

    return (
        <>

            { errorMsg !== "" &&
            <Alert
                message={errorMsg}
                color="red" />
            }
            { successMsg !== "" &&
                <Alert
                    message={successMsg}
                    color="green" />
            }

            <div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-bg-gray-100 dark:tw-bg-white-transparent dark:tw-text-light tw-text-gray-500 dark:tw-text-light">
                <div className="md:tw-col-span-1">
                    <div className="tw-px-4 sm:tw-px-0">
                        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-500 dark:tw-text-light">Profile</h3>
                        <p className="tw-mt-1 tw-text-sm tw-text-gray-500 dark:tw-text-light">
                            This information will be displayed publicly.
                        </p>
                    </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                    <form action="#" method="POST" onSubmit={saveProfile} className="tw-border-0 tw-rounded-3xl">
                        <div className="sm:tw-overflow-hidden">
                            <div className="tw-px-4 tw-py-5  tw-space-y-6 sm:tw-p-6 ">
                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                    <div className="tw-col-span-3 sm:tw-col-span-2">
                                        <label htmlFor="user_name"
                                               className="tw-block tw-text-sm tw-font-medium">
                                            Username
                                        </label>
                                        <div className="tw-mt-1 tw-flex">
                                            <span id="user_name" className="tw-w-full tw-px-4 tw-py-2">
                                                {userDB ?
                                                    userDB.username && (userDB.username === userDB.email)?
                                                <input
                                                    className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-darker dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    value={fields.username}
                                                    onChange={handleFieldChange}
                                                    placeholder=""
                                                    required
                                                /> : userDB.username
                                                    : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                    <div className="tw-col-span-3 sm:tw-col-span-2">
                                        <label htmlFor="user_email"
                                               className="tw-block tw-text-sm tw-font-medium">
                                            Email
                                        </label>
                                        <div className="tw-mt-1 tw-flex">
                                            <span id="user_email" className="tw-w-full tw-px-4 tw-py-2">
                                                {userDB!= null? userDB.email : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="description" className="tw-block tw-text-sm tw-font-medium">
                                        About
                                    </label>
                                    <div className="tw-mt-1">
                                            <textarea id="description" name="description" rows="3"
                                                      value={fields.description} onChange={handleFieldChange}
                                                      // className="tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full sm:tw-w-text-sm tw-border-gray-300 tw-rounded-md"
                                                      className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-3xl
                                                      tw-mt-1 tw-block sm:tw-w-text-sm tw-border-gray-300
                                                      dark:tw-bg-white-transparent dark:tw-border-gray-700
                                                      focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                                      placeholder="my description"
                                                      maxLength="400"></textarea>
                                    </div>
                                    <p className="tw-mt-2 tw-text-sm">
                                        Brief description for your profile.
                                    </p>
                                </div>

                                <div>
                                    <label className="tw-block tw-text-sm tw-font-medium">
                                        Avatar
                                    </label>
                                    <div className="tw-mt-1 tw-flex tw-items-center">
                                        <span
                                            className="tw-inline-block tw-h-12 tw-w-12 tw-rounded-full tw-overflow-hidden tw-bg-gray-100">
                                            {avatarImg != null ?
                                                <img
                                                    src={avatarImg.includes("http") ? avatarImg : `${process.env.REACT_APP_AVATAR_URL}/${avatarImg}`} />
                                                :
                                                <FiUser className="tw-h-full tw-w-full tw-text-gray-300"
                                                        fill="currentColor" />
                                            }
                                        </span>
                                        <button type="button" onClick={() => setShowAvatarUpdate(true)}
                                                className="tw-ml-5 tw-px-3 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker">
                                            Change
                                        </button>
                                    </div>
                                </div>
                                {showAvatarUpdate ?
                                    <div>
                                        <label className="tw-block tw-text-sm tw-font-medium">
                                            Update Avatar
                                        </label>
                                        <div className="tw-mt-1 tw-block tw-items-center
                                                    tw-border tw-rounded-3xl
                                                    tw-border-gray-300 dark:tw-border-gray-700">
                                            <AvatarEdit saveImage={saveAvatar} />
                                        </div>
                                    </div>
                                    : ''
                                }

                                <fieldset>
                                    <div>
                                        <legend className="tw-block tw-text-sm tw-font-medium tw-mb-0">
                                            Default Mode
                                        </legend>
                                        <div className="tw-mt-4 tw-space-y-4">
                                            <div className="tw-flex tw-items-center">
                                                <input id="light_mode" name="default_mode" type="radio"
                                                       value="light"
                                                       checked={fields.default_mode === "light"}
                                                       onChange={handleFieldChange}
                                                       className="tw-h-4 tw-w-4 focus:tw-ring-primary dark:focus:tw-ring-offset-darker tw-text-primary dark:tw-text-primary-lighter tw-border-gray-300" />
                                                <label htmlFor="light_mode"
                                                       className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium">
                                                    Light
                                                </label>
                                            </div>
                                            <div className="tw-flex tw-items-center">
                                                <input id="dark_mode" name="default_mode" type="radio"
                                                       value="dark"
                                                       checked={fields.default_mode === "dark"}
                                                       onChange={handleFieldChange}
                                                       className="tw-h-4 tw-w-4 focus:tw-ring-primary dark:focus:tw-ring-offset-darker tw-text-primary tw-border-gray-300" />
                                                <label htmlFor="dark_mode"
                                                       className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium0">
                                                    Dark
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div>
                                        <legend className="tw-block tw-text-sm tw-font-medium tw-mb-0">
                                            Trading Type
                                        </legend>
                                        <div className="tw-mt-4 tw-space-y-4">
                                            <div className="tw-flex tw-items-center">
                                                <input id="day_trader" name="trading_type" type="radio"
                                                       value="day_trader"
                                                       checked={fields.trading_type === "day_trader"} onChange={handleFieldChange}
                                                       className="tw-h-4 tw-w-4 focus:tw-ring-primary dark:focus:tw-ring-offset-darker tw-text-primary dark:tw-text-primary-lighter tw-border-gray-300"/>
                                                <label htmlFor="day_trader"
                                                       className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium">
                                                    Day trader
                                                </label>
                                            </div>
                                            <div className="tw-flex tw-items-center">
                                                <input id="swing_trader" name="trading_type" type="radio"
                                                       value="swing_trader"
                                                       checked={fields.trading_type === "swing_trader"} onChange={handleFieldChange}
                                                       className="tw-h-4 tw-w-4 focus:tw-ring-primary dark:focus:tw-ring-offset-darker tw-text-primary tw-border-gray-300" />
                                                <label htmlFor="swing_trader"
                                                       className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium0">
                                                    Swing trader
                                                </label>
                                            </div>
                                            <div className="tw-flex tw-items-center">
                                                <input id="investor" name="trading_type" type="radio"
                                                       value="investor"
                                                       checked={fields.trading_type === "investor"} onChange={handleFieldChange}
                                                       className="tw-h-4 tw-w-4 focus:tw-ring-primary dark:focus:tw-ring-offset-darker tw-text-primary tw-border-gray-300" />
                                                <label htmlFor="investor"
                                                       className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium0">
                                                    Investor
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6 tw-rounded-b-3xl dark:tw-bg-white-transparent">
                                <button type="submit"
                                        className="tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="tw-hidden sm:tw-block dark:tw-bg-white-transparent" aria-hidden="true">
                <div className="tw-py-5">
                    <div className="tw-border-t dark:tw-border-darker"></div>
                </div>
            </div>

            {/* Subscription */}
            {/*<div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-bg-gray-100 dark:tw-bg-white-transparent dark:tw-text-light">*/}
            {/*    <div className="md:tw-col-span-1">*/}
            {/*        <div className="tw-px-4 sm:tw-px-0">*/}
            {/*            <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-500 dark:tw-text-light">Subscription</h3>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-2">*/}
            {/*        <div className="tw-rounded-3xl sm:tw-overflow-hidden">*/}

            {/*        {userDB && userDB.id !== null ?*/}
            {/*            <ErrorBoundary>*/}
            {/*                <Billing key={`billing-${userDB.id}`} userId={userDB.id} email={userDB.email} />*/}
            {/*            </ErrorBoundary>*/}
            {/*            : "No user registered"*/}
            {/*        }*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div className="tw-hidden sm:tw-block dark:tw-bg-white-transparent" aria-hidden="true">*/}
            {/*    <div className="tw-py-5">*/}
            {/*        <div className="tw-border-t dark:tw-border-darker"></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* Change Password   */}
            <div className="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-bg-gray-100 dark:tw-bg-white-transparent dark:tw-text-light">
                <div className="md:tw-col-span-1">
                    <div className="tw-px-4 sm:tw-px-0">
                        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-500 dark:tw-text-light">Change Password</h3>
                    </div>
                </div>
                <div className="tw-mt-5 md:tw-mt-0 md:tw-col-span-2">
                    <form action="#" method="POST" onSubmit={changePassword}>
                        <div className="tw-rounded-3xl sm:tw-overflow-hidden">
                            <div className="tw-px-4 tw-py-5 tw-bg-white tw-space-y-6 sm:tw-p-6 dark:tw-bg-white-transparent tw-text-gray-500 dark:tw-text-light">

                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                    <div className="tw-col-span-3 sm:tw-col-span-2">
                                        <label htmlFor="current_password"
                                               className="tw-block tw-text-sm tw-font-medium">
                                            Current Password
                                        </label>
                                        <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                            <input
                                                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                                type="password"
                                                id="current_password"
                                                name="current_password"
                                                value={fieldsPwd.current_password}
                                                onChange={handleFieldPwdChange}
                                                placeholder=""
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                    <div className="tw-col-span-3 sm:tw-col-span-2">
                                        <label htmlFor="new_password"
                                               className="tw-block tw-text-sm tw-font-medium">
                                            New Password
                                        </label>
                                        <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                            <input
                                                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                                type="password"
                                                id="new_password"
                                                name="new_password"
                                                value={fieldsPwd.new_password}
                                                onChange={handleFieldPwdChange}
                                                placeholder=""
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                                    <div className="tw-col-span-3 sm:tw-col-span-2">
                                        <label htmlFor="password_confirmation"
                                               className="tw-block tw-text-sm tw-font-medium">
                                            Confirm Password
                                        </label>
                                        <div className="tw-mt-1 tw-flex tw-rounded-md tw-shadow-sm">
                                            <input
                                                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                                type="password"
                                                id="password_confirmation"
                                                name="password_confirmation"
                                                value={fieldsPwd.password_confirmation}
                                                onChange={handleFieldPwdChange}
                                                placeholder=""
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right sm:tw-px-6 dark:tw-border-t dark:tw-border-primary-dark dark:tw-bg-white-transparent">
                                <button
                                    type="submit"
                                    className="tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                >
                                    Save password
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


        </>
    );

}

export default Settings;