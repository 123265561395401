import {useState} from "react";

export function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            if (event === undefined || event.target === undefined) {
                // set the value manually
                setValues(event);
            } else if (event.target.type === "radio") {
                setValues({
                    ...fields,
                    [event.target.name]: event.target.value
                });
            } else {
                setValues({
                    ...fields,
                    [event.target.id]: event.target.value
                });
            }
        }
    ];
}