import React from "react";

export default function TokenUsage() {


    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-mb-8">
                <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Token Usage and Terms</span>
            </div>

            <div className="tw-py-8 tw-px-2 tw-space-y-8 sm:tw-px-4 lg:tw-mr-20 lg:tw-ml-10 tw-text-justify
                            tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">

                <div>
                    The Terms and Conditions (the “T&C” or “Terms”) apply to the buyer of the TRALE Utility Token
                    (hereinafter “TRALE UTILITY TOKEN”) and future user of the Trades League platform and constitute an
                    agreement between Trades League and you. Please read these terms carefully before moving onto the
                    token usage. The T&C affect your obligations and legal rights, including, but not limited to, waiver
                    of rights and limitation of liability. If you do not agree to these terms of usage, do not
                    participate in the usage of the Tokens.
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">I. Applicability</p>
                    <div>
                        The following T&C constitute the agreement (the “Agreement”) between Trades League Ltd
                        (hereinafter
                        “Trades League” or the “Company” or “us” ), a BVI Limited Liability Company, and YOU
                        (hereinafter
                        “User” or “you”), with respect to the usage of the TRALE UTILITY TOKEN and the future use of the
                        services offered through the Trades League platform (hereinafter “Platform”).
                        <br />
                        By using our services, you are agreeing to be bound by the T&C in its latest version. You are
                        aware
                        that Trades League may change this T&C at any time, on our sole discretion without any prior
                        notice
                        to you. Any changes will be effective immediately upon posting on our Website. Your continued
                        use of
                        the Trades League Platform means that you accept any new or modified terms.

                    </div>
                </div>
                <div className="tw-mb-8">
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">II. Services</p>
                    <div>
                        <ul className="tw-list-decimal  tw-space-y-1 tw-pl-4">
                            <li>
                                Trades League is using the utility token TRALE for the users of the platform
                                tradesleague.com to subscribe to other users and reward the users of the platform.
                            </li>
                            <li>
                                TRALE Token does not have the legal qualification as a security, since it does not
                                give any rights on dividends or interest, any ownership right or stake share or
                                equivalent rights or any right to receive future revenue shares, or any other form of
                                participation in or relating to the Company, nor shall TRALE holders have any influence
                                or rights in the development or governance of the Company. TRALE, therefore, is not a
                                share and does not give any right to participate in the general meetings of the Company.
                                The TRALE Utility Token is final and non-refundable. The acquirement and use of TRALE
                                Utility Token shall not be done for speculative usage.
                            </li>
                            <li>
                                Trades League Ltd is not engaged in any form of token sales. The TRALE Utility Token
                                can be exchanged on https://pancakeswap.finance/ a fully decentralized protocol for
                                automated liquidity provision on Binance. Owners of the TRALE Token can use the TRALE
                                token to follow other users on tradesleague.com
                            </li>
                            <li>
                                Company gives no warranties that TRALE Utility Tokens will be exchangeable on
                                specific platforms or that they shall remain exchangeable on exchange platforms or that
                                they would be exchangeable on other exchanges.
                            </li>
                            <li>
                                TRALE Utility Token is not an investment advice, a security nor an investment product
                                and any action, notice, communication, message, decision, managerial act, or omission of
                                the mentioned shall not be understood and interpreted as such. Company gives no
                                guarantees as to the value of any of the TRALE Utility Tokens and explicitly warns users
                                that there is no reason to believe that TRALE Utility Token will increase in value, and
                                they might also decrease in value or lose their value entirely. You represent and
                                warrant, that you are acquiring TRALE Utility Tokens for your own personal use and for
                                your personal utility and not for investment or financial purposes. You also represent
                                and warrant that you do not consider Trades Leaque as a security and you understand that
                                TRALE Utility Tokens may lose all their value and that you are not purchasing TRALE
                                Utility Tokens as an investment and that you are aware of all other risks deriving from
                                holding TRALE Utility Token as specified in these Terms and you accept them.
                            </li>
                            <li>
                                TRALE Utility Tokens are unregulated. Trades League is not a financial institution
                                and is currently not under supervision of any financial supervisory authority. Trades
                                League does not provide any licensed financial services, such as investment services,
                                fund management or investment advice. None of the information or analysis presented
                                herein are intended to form the basis for any investment decision, no specific
                                recommendations are intended, and Trades League, website and TRALE Utility Tokens do not
                                offer, are not and shall not be construed as investment or financial products. In the
                                event of any regulatory changes would impact TRALE, Trades League reserves the right to
                                amend its Services, the Website and the TRALE Token.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="tw-mb-4">
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        III. Token Distribution
                    </p>
                    <div>
                        <ul className="tw-list-decimal  tw-space-y-1 tw-pl-4">
                            <li>
                                Token distribution is not a public offering of equity or debt and consequently does
                                not fall under the securities or any prospectus regulation.
                            </li>
                            <li>
                                Company reserves the right to amend acceptable digital assets at any time, based on
                                its sole discretion. All acquirement of TRALE Utility Tokens shall be publicly
                                accessible through the Binance blockchain.
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        IV. Knowledge Required
                    </p>
                    <div>
                        <ul className="tw-list-decimal  tw-space-y-1 tw-pl-4">
                            <li>
                                The User purchasing TRALE Utility Tokens expressly acknowledges and represents that
                                she/he has carefully reviewed the T&C and fully understands the risks, costs and
                                benefits associated with the acquisition of this token as indicated in the T&C.
                            </li>
                            <li>
                                The User undertaking to acquire TRALE Utility Token in relation to the token sale
                                should ensure that she/he understands and has significant experience of
                                cryptocurrencies, blockchain systems and services, and that she/he fully understands
                                the risks associated with the Token Sale as well as the mechanism related to the use
                                and custody of cryptocurrencies.
                            </li>
                            <li>
                                Trades League shall not be responsible for any loss of TRALE Utility Tokens or
                                situations making it impossible to access the TRALE Utility Token, which may result
                                in any actions or omissions of the future User or any person undertaking to acquire
                                TRALE Utility Tokens.
                            </li>
                            <li>
                                Trades League is not a provider of (a) exchange services between virtual currency
                                and fiat currency; (b) wallet or custodial services of credentials necessary to
                                access virtual currencies or (c) cryptocurrency mining services.
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        V. Risks
                    </p>
                    <div>
                        <ul className="tw-list-decimal  tw-space-y-1 tw-pl-4">
                            <li>
                                Development failure or abortion. Acquiring and storing TRALE Utility Tokens involves
                                various risks, in particular that Trades League may not be able to launch some
                                operations and continue developing its platform or community. Therefore, and prior
                                to acquiring or exchange TRALE, any user should carefully consider the risks, costs,
                                and benefits of acquiring TRALE and, if necessary, obtain independent advice in this
                                regard. Any interested person who is not in the position to accept nor to understand
                                the risks associated with the activity, including the risks related to the
                                non-development of the Trades League platform and operations, or any other risks as
                                indicated in the T&C, should not acquire TRALE.
                            </li>
                            <li>
                                Legal Risk concerning Security Regulation. There is a risk that in some
                                jurisdictions TRALE Utility Tokens might be considered as a security, now or in the
                                future. Company does not give warranties or guarantees that TRALE Utility Token are
                                not a security in all jurisdictions. Each user of Trades League shall bear its own
                                legal or financial consequences of TRALE Utility Token being considered a security
                                in their respective jurisdiction. The legal ability of Company to provide TRALE
                                Utility Tokens in some jurisdictions may be eliminated by future regulation or legal
                                actions. In the event, it turns out with a high degree of certainty that TRALE
                                Utility Tokens are not legal in certain jurisdiction, Company will base on its sole
                                discretion either (a) cease operations in that jurisdiction, or (b) adjust TRALE
                                Utility Tokens in a way to comply with the regulation should that be possible and
                                viable. It is your obligation to check if acquisition and disposal of TRALE Utility
                                Tokens is legal in your jurisdiction, and by accepting these Terms you expressly
                                agree and warrant that you will not use the Trades League platform should the use
                                not be legal in the relevant jurisdiction.
                            </li>
                            <li>
                                Risk of malfunction of blockchain. Nobody can guarantee the Binance source code used
                                by Company will be flaw-free. It may contain certain flaws, errors, defects and
                                bugs, which may disable some functionality for users, expose users’ information or
                                otherwise. Such flaw would compromise the usability and/or security of Company and
                                consequently bring adverse impact on the value of Company. Open-source codes rely on
                                transparency to promote community-sourced identification and solution of problems
                                within the code.
                            </li>
                            <li>
                                Update of the Source Code. The Binance source code could be updated, amended,
                                altered or modified from time to time by the developers and/or the community of
                                Binance. Nobody is able to foresee or guarantee the precise result of such update,
                                amendment, alteration or modification. As a result, any update, amendment,
                                alteration or modification could lead to an unexpected or unintended outcome that
                                adversely affects TRALE Utility Tokens and/or Company operation or market value.
                            </li>
                            <li>
                                Internet transmission risks. You acknowledge that there are risks associated with
                                utilizing Internet-based digital assets, products and website including, but not
                                limited to, the failure of hardware, software, and Internet connections. You
                                acknowledge that the Company shall not be responsible for any communication
                                failures, disruptions, errors, distortions or delays you may experience when using
                                our services, website, platform, TRALE Utility Tokens howsoever caused.
                            </li>
                            <li>
                                Cryptography risks. You acknowledge that there are risks associated with
                                cryptography, such as code cracking or technical advances such as the development of
                                quantum computers, could present risks to all crypto-currencies including TRALE
                                Utility Tokens. This could result in the theft, loss, disappearance, destruction or
                                devaluation of the Trades League Platform. To a reasonable extent, Company will be
                                prepared to take proactive or remedial steps to update the protocol underlying
                                Company in response to any advances in cryptography and to incorporate additional
                                reasonable security measures where appropriate. It is impossible to predict the
                                future of cryptography or the future of security innovations to an extent that would
                                permit the Company to accurately guide the
                                development of Company to take into account such unforeseeable changes in the
                                domains of cryptography or security.
                            </li>
                            <li>
                                Risk of unfavorable regulatory action in one or more jurisdictions. Blockchain
                                technologies have been the subject of scrutiny by various regulatory bodies around
                                the world. Trades League could be impacted by one or more regulatory inquiries or
                                actions, including but not limited to restrictions on the use or possession of
                                TRALE, which could impede or limit their existence, permissibility of their use and
                                possession, and their value.
                            </li>
                            <li>
                                Risk of theft and hacking. Hackers or other groups or organizations may attempt to
                                interfere with your third-party wallet, the Website or the availability of TRALE
                                Utility Tokens in any number of ways, including without limitation denial of service
                                attacks, Sybil attacks, spoofing, smurfing, malware attacks, or consensus-based
                                attacks.
                            </li>
                            <li>
                                Risk of mining attacks. Binance blockchain, which is used for the TRALE Utility
                                Tokens, is susceptible to mining attacks, including but not limited to double-spend
                                attacks, majority mining power attacks, “selfish-mining” attacks, and race condition
                                attacks. Any successful attacks present a risk to the TRALE Utility Token.
                            </li>
                            <li>
                                Risk of loss of value and uninsured losses. Value of TRALE Utility Tokens may
                                fluctuate and you may suffer loss in value of such acquired TRALE Utility Token. In
                                addition to loss of value risk, TRALE Utility Tokens are entirely uninsured and are
                                unlike bank accounts or accounts at some other financial institutions.
                            </li>
                            <li>
                                Unanticipated risks. Cryptocurrencies and blockchain technologies are new and
                                untested technology and in addition to the risks outlined in these Terms, there are
                                also unforeseeable risks that may materialize as unanticipated.
                            </li>
                            <li>
                                You acknowledge, agree and warrant that you have been warned of the potential risks
                                involved by using our services, website and product and other relevant technologies
                                mentioned herein as well as that there may be other risks involved, which are not
                                specified herein and that you fully accept such risks.

                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        VI. Disclaimer
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                The T&C shall not and cannot be considered as an invitation to enter into an investment.
                                They do not constitute or relate in any way nor should they be considered as an offering
                                of securities in any jurisdiction. The T&C do not include or contain any information or
                                indication that might be considered as a recommendation or that might be used to base
                                any investment decision. This document does not constitute an offer or an invitation to
                                sell shares, securities or rights belonging to Trades League or any related or
                                associated company. The TRALE Utility Token is not intended to be used as an investment.
                            </li>
                            <li>
                                Any information in the T&C is given for general information purpose only and Trades
                                League does not provide with any warranty as to the accuracy and completeness of this
                                information.
                            </li>
                            <li>
                                The offering of TRALE Utility Tokens is done in order to allow users to access other
                                users portfolios in the website tradesleague.com and to reward users of the website and
                                not for speculative purposes.
                            </li>
                            <li>
                                Trades League is an entity managing the Trades Leagues website. Trades League is not a
                                financial intermediary and is not required to obtain any authorization for Anti Money
                                Laundering purpose.
                            </li>
                            <li>
                                Regulatory authorities are carefully scrutinizing businesses and operations associated
                                to cryptocurrencies in the world. In that respect, regulatory measures, investigations
                                or actions may affect the Trades League business and even limit or prevent it from
                                developing its operations in the future.
                            </li>
                            <li>
                                Any person undertaking to acquire TRALE Utility Token must be aware that the Trades
                                League business model and the T&C may change or need to be modified because of new
                                regulatory and compliance requirements from any applicable laws in any jurisdictions. In
                                such case, any person undertaking to acquire TRALE acknowledge and understand that
                                neither Trades League nor any of its affiliates shall be held liable for any direct or
                                indirect loss or damages caused by such changes.
                            </li>
                            <li>
                                Trades League will do its best to launch its operations and develop the TradesLeague
                                website(s). Any person undertaking to acquire TRALE acknowledges and understands that
                                Trades League does not provide any guarantee that it will manage to achieve it.
                            </li>
                            <li>
                                Acquiring TRALE shall not grant any right or influence over the Trades League
                                organization and governance to the users. These tokens will be issued by a technical
                                process referred to as a «blockchain». This is an open source IT protocol over which the
                                Company has no rights or liability in terms of its development and operation. The token
                                distribution mechanism will be controlled by a Smart Contract; this involves a computer
                                program that can be executed on the Binance network or on another «blockchain» network
                                that is compatible with the Smart Contract programming language. User acknowledges and
                                understands therefore that Trades League, including its bodies and employees, assumes no
                                liability or responsibility for any loss or damage that would result from or relate to
                                the incapacity to use the TRALE Utility Token expected in case of intentional misconduct
                                or gross negligence.
                            </li>
                            <li>
                                The TRALE Utility Token is based on the Binance protocol. Therefore, any malfunction,
                                unplanned function or unexpected operation of the Binance protocol may cause the Trale
                                Utility Token network to malfunction or operate in a way that is not expected.
                            </li>
                            <li>
                                Employees of Trades League are allowed to operate with TRALE Utility Token at market
                                price if they are not in knowledge of information that may modify the price of the
                                Token.

                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        VII. Eligibility, Representation, and Warranties
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                You must be at least 18 years old to use our services, product and website. By using our
                                services, products and accessing our website you represent and warrant that you: (a) are
                                at least 18 years old; (b) you have full power and authority to enter into this
                                agreement (c) have not been previously suspended or removed from using our services (b)
                                have not been placed on any of the sanctions lists, published and maintained by the
                                United Nations, European Union, any EU country, UK Treasury and US Office of Foreign
                                Assets Control (OFAC);
                            </li>
                            <li>
                                If you are using the services on behalf of a legal entity, you represent and warrant
                                that: (a) such legal entity is duly organized and validly existing under the applicable
                                laws of the jurisdiction of its organization; (b) you are duly authorized by such legal
                                entity to act on its behalf (c) any beneficial owner of the legal entity, director,
                                employee, services provider or any other individual in any way connected with the
                                Company has not been placed on any of the sanctions lists, published and maintained by
                                the United Nations, European Union, any EU country, UK Treasury and US Office of Foreign
                                Assets Control (OFAC);
                            </li>
                            <li>
                                By participating in the Token Sale, the User agrees to the T&C and in particular, they
                                represent and warrant that they:
                                <ul className="tw-list-none tw-space-y-1 tw-pl-4">
                                    <li>
                                        A. are authorized and have full power to acquire TRALE Utility Token according
                                        to the laws that apply in their jurisdiction of domicile;
                                    </li>
                                    <li>
                                        B. are not a citizen, resident or entity of Afghanistan, Australia, Belarus,
                                        Bosnia & Herzegovina, Burma, Burundi, Central African Republic, Cuba, Democratic
                                        Republic of Congo, Estonia, Egypt, Guinea, Guinea-Bissau, Iran, Iraq, Lebanon,
                                        Libya, Mali, Moldova, Montenegro, Myanmar (Burma), Nicaragua, North Korea,
                                        Somalia, Sudan, Syria, Tunisia, Turkey, Ukraine, United States of America and
                                        its territories (American Samoa, Guam, the Northern Mariana Islands, Puerto
                                        Rico, and the U.S. Virgin Islands), Venezuela, Yemen, and/or Zimbabwe nor are
                                        purchasing TRALE Utility Token or signing on behalf of a resident in the
                                        jurisdictions listed;
                                    </li>
                                    <li>
                                        C. are familiar with all related regulations in the specific jurisdiction in
                                        which they are based and that purchasing cryptographic tokens in that
                                        jurisdiction is not prohibited, restricted or subject to additional conditions
                                        of any kind;
                                    </li>
                                    <li>
                                        D. are not acting for the purpose of speculative investment;
                                    </li>
                                    <li>
                                        E. will not use the Token Sale for any illegal activity, including but not
                                        limited to money laundering and the financing of terrorism;
                                    </li>
                                    <li>
                                        F. are solely responsible for determining whether the acquisition of TRALE
                                        Utility Token is appropriate for them;
                                    </li>
                                    <li>
                                        G. are acquiring TRALE Utility Token exclusively for use of the Trades Leagues
                                        websites;
                                    </li>
                                    <li>
                                        H. understand the risks associated with the Token Sale, including the risks
                                        related to the non- development of the Trades League website and operations, and
                                        understand the use of cryptocurrencies and its associated risks.
                                    </li>
                                    <li>
                                        I. acknowledges and accepts that the TRALE Utility Token crowd sale is taking
                                        place within an industry legal environment that is still under development,
                                        shall not violate or attempt to violate the security of the website; services,
                                        platform and TRALE Utility Token and you will not hack into, interfere with,
                                        disrupt, disable, over-burden, modify, publish, reverse engineer, participate in
                                        the transfer or sale, create derivative works, or otherwise impair the website,
                                        platform and TRALE Utility Token.
                                    </li>
                                    <li>
                                        J. acknowledge the Company shall have the right to implement necessary measures
                                        to monitor compliance of this Section.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        VIII. Usage
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                User Responsibility. By using the Trades League website, the User agrees to the T&C and
                                in particular, they represent and warrant the following:
                                <ul className="tw-list-none tw-space-y-1 tw-pl-4">
                                    <li>
                                        A. User is solely responsible for confirming and ensuring their compliance with
                                        any local prohibitions and/or regulations that may be applicable to their
                                        activities on the website, including those of your country or jurisdiction of
                                        residence.
                                    </li>
                                    <li>
                                        B. All information received from the user is true and accurate. The user will
                                        immediately notify Trades League of any changes to the information provided.
                                    </li>
                                    <li>
                                        C. User is solely responsible for any and all taxes or other amounts payable in
                                        their resident country or jurisdiction relating to any rewards made by the user.
                                    </li>
                                    <li>
                                        D. User will only use wallet addresses that are valid and legally under the
                                        user’s custody and control.
                                    </li>
                                    <li>
                                        J. User is solely responsible to keep their login details and crypto wallets
                                        secure and ensure that they are not disclosed to anyone. Trades League is not
                                        responsible for any misuse of wallets.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Malicious Use. Users shall not perform any of the following acts in connection with the
                                use of the services provided by the company:
                                <ul className="tw-list-none tw-space-y-1 tw-pl-4">
                                    <li>
                                        A. When applying for use or changing member information, use other person's
                                        information or enter false information;
                                    </li>
                                    <li>
                                        B. The act of impersonating executives, employees, operators, and other related
                                        parties of the company;
                                    </li>
                                    <li>
                                        C. Changing the information posted by the company;
                                    </li>
                                    <li>
                                        D. Unauthorized collection, storage, posting, or dissemination of other users’
                                        personal information;
                                    </li>
                                    <li>
                                        E. The act of producing, distributing, using, or advertising computer programs
                                        or devices or devices not provided or approved by the company;
                                    </li>
                                    <li>
                                        F. Intentionally sending, posting, distributing or using viruses, computer
                                        codes, files, programs, etc. designed to interfere with or destruct normal
                                        operation of information, computer programs, or computer software/hardware or
                                        regular communications equipment;
                                    </li>
                                    <li>
                                        I. Unauthorized reproduction, distribution, promotion, or commercial use of
                                        information obtained using the company's services, and the use of services by
                                        exploiting known or unknown bugs;
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Refunds and Rewards. Under no circumstances will the TRALE Utility Token be refunded.
                                The company will not refund TRALE Utility Token losses incurred due to user negligence
                                and lack of knowledge of service usage of the service. TRALE Utility Token rewards are
                                automatically distributed through smart contracts. The company is not responsible for
                                any transfer loss or delay caused by delayed transactions on the blockchain network.
                            </li>
                            <li>
                                Change of Service. Users may use the game service provided by the company in accordance
                                with these terms of services, policies, and game rules established by the company.
                                Trades League can change the service according to operational or technical needs to
                                provide smooth services at the company’s discretion.

                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        IX. Intellectual Property Rights
                    </p>
                    <div>
                        To the extent that copyright or other intellectual property rights exist on the Trades League
                        platform, website, TRALE Utility Token and services such as software, know-how, analysis or
                        programs, those copyrights and other intellectual and industrial rights belong to Trades League
                        or third-party affiliates. Under no circumstances will these Terms be construed as granting, by
                        implication, estoppel or otherwise, a license to any Intellectual Property or components thereof
                        other than as specifically granted in these terms. You agree that the Intellectual Property may
                        be used only as provided in these terms. All rights not expressly granted herein are reserved.
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        X. Disclaimer of Warranties
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                To the fullest extent permissible under applicable law, our service, website, TRALE
                                Utility Tokens are provided on an “as is,” and “as available” basis, without warranties
                                of any kind, express, implied or statutory, including implied warranties of condition,
                                uninterrupted use, merchantability, satisfactory quality, fitness for a particular
                                purpose, non-infringement of third-party rights, and warranties (if any) arising from a
                                course of dealing, usage, or trade practice, is given in conjunction to website,
                                services, platform, TRALE Utility Token, any software or product contained in website,
                                TRALE Utility Token and services and in general.
                            </li>
                            <li>
                                Company does not warrant that the service, TRALE token or website will be error-free or
                                uninterrupted or that any defects will be corrected in reasonable time. You acknowledge
                                that your use of the service, platform and website is at your sole risk.
                            </li>
                            <li>
                                While every effort is made to ensure that information contained in the website is
                                correct, Company disclaims all warranties regarding the accuracy, adequacy, correctness,
                                completeness, reliability, timeliness, non-infringement, title, merchantability or
                                fitness of any information on the Website.
                            </li>
                            <li>
                                Company does not guarantee the confidentiality or privacy of any communication or
                                information transmitted through services, on the Website s or any site linked to the
                                website. We will not be liable for the privacy or security of information, e-mail
                                addresses, registration and any identification information (if applicable),
                                communications, confidential or proprietary information, or any other content
                                transmitted to us in any manner, or otherwise connected with your use of our services,
                                website or platform.
                            </li>
                            <li>
                                Company shall not bear any liability, whatsoever, for any damage or interruptions caused
                                by any computer viruses, spyware, scareware, trojan horses, worms or other malware that
                                may affect your computer or other equipment, or any phishing, spoofing or other attack.
                                We advise the regular use of a reputable and readily available virus screening and
                                prevention software. You should also be aware that communication services are vulnerable
                                to spoofing and phishing attacks and should use care in reviewing messages purporting to
                                originate from the Company and/or users on the platform.

                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XI. Limitation of Liability
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                Trades League, as well as its officers, directors, agents, joint ventures, employees,
                                suppliers and advisors, assumes no liability or responsibility for any loss raised from
                                the token sale, arising out of or related to the use of the Trades League platform or
                                any technical, interruption or malfunction of the Trades League platform, website,
                                token, services or otherwise. In no event shall Trades League , as well as its officers,
                                directors, agents, joint ventures, employees, suppliers and advisors, be liable for any
                                special, indirect, incidental, punitive or consequential damages of any kind whatsoever,
                                including without limitation any damages caused by or resuming from reliance by any user
                                or any information obtained from the Company, or that result from mistakes, omissions,
                                interruptions, deletion of files or email, errors, defects, viruses, delays in operation
                                or transmission or any failure of performance, whether or not resulting from a force
                                majeure event, suspension of Binance network communication failure, theft, destruction
                                or unauthorized access to company’s records, services, website, TRALE Utility Token. In
                                addition to the above, company shall not be liable for any loss of profit, loss of
                                business, trading losses, loss in value, or any other loss. This applies even if such
                                damages are foreseeable.
                            </li>
                            <li>
                                The limitation of liability set out above shall not be applicable in the event that
                                Trades League, or a Trades League employee, has caused the damage by intentional
                                misconduct or by gross negligence.
                            </li>
                            <li>
                                Each party acknowledges that the fees payable hereunder reflects the allocation of risk
                                set forth in the agreement and that each party would not enter into the agreement
                                without these limitations on liability.
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XII. Indemnification
                    </p>
                    <div>

                        You will defend, indemnify and hold Company, its affiliates and licensors and its
                        officers, directors, contractors, employees, agents and representatives, harmless from
                        and against all claims, actions, demands, liabilities, damages, losses, costs and
                        expenses (including legal fees) relating to or arising from (a) your use of our
                        services, website, platform, TRALE Utility Token (b) your breach of these terms or any
                        other liabilities arising out of your use of the services, website and product or the
                        use by any other person accessing the website, service, platform, TRALE Utility Token
                        using your computer and/or your personal information (c) your violation of any rights of
                        any other person or entity in connection with these terms (d) third-party claims
                        concerning these terms or you thereof. You will promptly notify us of any third-party
                        claims, actions, or proceedings which you become aware of that involves company as a
                        result of these terms. Company may participate with counsel of its own choosing in any
                        proceedings describing above. Company will be required to respond to compulsory legal
                        order, you will reimburse company for reasonable attorney and contractors fees for their
                        time and material spent responding to obligatory legal orders. You will not agree to any
                        settlement which imposes any obligation or liability for company without company’s prior
                        written consent.

                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XIII. Links to other Websites
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                Our services, website, platform, TRALE Utility Token may include links to third party
                                websites, third party wallet providers or other service providers (“Third Party Sites”).
                                If you use any links made available through our services, website, platform, application
                                to reach other websites not maintained by the company you will leave our services,
                                website, platform, application. The linked sites are not under the control of the
                                company and company is not responsible for the content of any linked site or any link
                                contained in a linked site. The linked sites are governed by the terms and conditions of
                                that site.
                            </li>
                            <li>
                                Company shall not be responsible or liable, either directly or indirectly, for any
                                damage or loss caused or alleged to be caused in connection with your use or reliance on
                                any such content, information, statements, representations, advertising, products,
                                services or other materials available on or through third party sites. Company provides
                                these links to you only as a convenience and the inclusion of any link does not imply
                                recommendation, approval or endorsement by company of third-party sites, nor does it
                                imply that the linked third-party sites recommends, approves of, or endorses Company.

                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XIV. Feedback
                    </p>
                    <div>

                        In connection with your use of our services, website, platform, you may provide us with
                        your feedback (suggestions, ideas, reviews, comments, material and questions or other
                        information and/or material). We will own exclusive rights, including all intellectual
                        property rights, to any feedback, suggestions, ideas or other information or materials
                        you provide to us, whether by email, posting through our services, website, platform or
                        otherwise. You waive any rights you may have to the feedback (including any copyrights
                        to the extent permitted by law). And Feedback you provide to us is non-confidential,
                        non-proprietary and is not subject to a third-party license and we have no liability for
                        any loss or damage suffered by you as a result of the disclosure or use of such
                        feedback. We have the right to disclose your identity to any third-party who is claiming
                        that any content posted by you constitutes a violation of their intellectual property
                        rights, or of their right to privacy. We have the right to remove any posting you make
                        on our website if, in our opinion, your post does not comply with the content standards
                        set out in this section.

                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XV. Term and Termination
                    </p>
                    <div>


                        This agreement is entered into for unlimited duration. We reserve the right to terminate and/or
                        suspend your access to our services/website, without giving you prior written notice and delete
                        all related information and files related to your use of our services, website, platform, mobile
                        application, without liability to you, at any time for any reason, including, but not limited
                        to, if based on our sole discretion believe: (i) You have violated these Terms, (ii) You create
                        risk or possible legal exposure for us.

                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XVI. Taxes
                    </p>
                    <div>
                        You are solely responsible and liable to declare, bear and pay all taxes, duties, imposts,
                        levies, tariffs and surcharges that might be imposed by the laws and regulations of any
                        jurisdiction as a result of or in connection with the use of services, website, platform, TRALE
                        Utility Token or any other Company product. Company is not giving any advice regarding tax
                        issues and is not responsible for determining any tax implications connected with its Services,
                        Website, Platform, and the TRALE Utility Token. Company makes no representation as to the tax
                        implication of any jurisdiction. You shall be solely liable for all such penalties, claims,
                        fines, punishments, liabilities or otherwise arising from his/her underpayment, undue payment or
                        belated payment of any applicable tax.
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XVII. Severability
                    </p>
                    <div>
                        If any of the provisions of the T&C or of the Agreement are deemed to be invalid, void or
                        unenforceable, the remaining provisions shall continue in full force and effect.
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XVIII. Notices
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                You agree and consent to receive electronically all communications, agreements,
                                documents,
                                receipts, notices and disclosures (collectively, Communications”) that Company provides
                                in
                                connection with your use of Company Services, Website, Platform and mobile application.
                                You
                                acknowledge and agree that Company may provide these communications to you by posting
                                them on
                                its Website or by emailing them to you at the email address you provide. Any email
                                communication
                                will be considered to be received by you within 24 hours after we email it to you and
                                Communication via our Website will be effective upon posting. You should maintain copies
                                of
                                electronic Communications by printing a paper copy or saving an electronic copy.
                            </li>
                            <li>

                                It is your responsibility to keep your email address with the Company up to date so
                                that the
                                Company can communicate with you electronically. You understand and agree that if the
                                Company
                                sends you an electronic communication but you do not receive it because your email
                                address is
                                incorrect, out of date, blocked by your service provider, or you are otherwise unable to
                                receive
                                electronic communications, Company will be deemed to have provided the communication to
                                you.
                                Please note that if you use a spam filter that blocks or re-routes emails from senders
                                not
                                listed in your email address book, you must add the Company to your email address book
                                so that
                                you will be able to receive the communications we send to you. You may also contact us
                                by
                                sending an email to info[at]tradesleague.com with general questions or for assistance.
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XIX. Applicable Law and Jurisdiction
                    </p>
                    <div>
                        The T&C are subject to and governed by British Virgin Island Law. Any User of Trades League
                        agree to seek an amicable settlement prior to bringing any legal action. All disputes arising
                        from or under these T&C shall be resolved by arbitration in accordance with The British Virgin
                        Islands International Arbitration Centre on the date when the Notice of Arbitration is submitted
                        in accordance with these Rules. The arbitration panel shall consist of one arbitrator only. The
                        seat of the arbitration shall be Road Town. The arbitral proceedings shall be conducted in
                        English.
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XX. Privacy
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                User Blockchain Wallets. The company classifies users through the user-connected
                                Blockchain wallet address for the convenience of the users’ information protection and
                                service information, and performs all the user management tasks in the service. The user
                                shall manage his or her blockchain wallet address with his or her duty. The company
                                shall not take any responsibility for damages caused by neglecting the information of
                                the blockchain wallet or accepting the use of the information to a third-party, and all
                                responsibilities shall be borne by the user. The company may refuse to use a blockchain
                                wallet that is restricted to use.
                            </li>
                            <li>
                                Sharing of personal data. We take your privacy very seriously, and will only share your
                                personal information as needed with the entities within the ecosystem to perform the
                                services and in the event if we are legally to meet any legal, regulatory, or
                                governmental request, including but not limited to events when we are compelled to do so
                                by a subpoena, court order, or similar legal procedure.
                            </li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className="tw-text-2xl tw-font-medium tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-4">
                        XXI. Miscellaneous
                    </p>
                    <div>
                        <ul className="tw-list-decimal tw-space-y-1 tw-pl-4">
                            <li>
                                Entire Agreement. These terms represent the entire agreement, and supersede all prior
                                and contemporaneous understandings between you and the Company regarding the subject
                                matter, especially regarding the services, website, platform and TRALE Utility Token. In
                                the event of any conflict between these terms and any other agreement you may have with
                                the Company, the terms of that other agreement will prevail only if these terms are
                                specifically identified and declared to be overridden by such other agreement.
                            </li>
                            <li>
                                Act of God Events. Company shall not be liable for (1) any inaccuracy, error, delays or
                                omission of (i) any information, or (ii) the transmission or delivery of information;
                                (2) any loss or damage arising from flood, extraordinary weather conditions, earthquake,
                                or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of
                                government, communications, power failure, or equipment or software malfunction or any
                                other cause beyond Company’s reasonable control, also known as “Force Majeure Event”.
                            </li>
                            <li>
                                Waiver. Company failure or delay in exercising any right, power or privilege under these
                                Terms shall not operate as a waiver thereof.
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </>
    );
}