import React, {useMemo, useState} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    currencyFormatter,
    currencyFormatterColour, dateFormatter,
    getPercentSum, getPercentSumRows, getReturnUSDPercent, getReturnUSDPercentSumRows,
    getSum,
    percentFormatter, percentWithoutColourFormatter,
    symbolFormatter
} from "./TableFormatters";


function getColumns(dataRows) {
    return [
        {dataField: "id", text: "ID", sort: true, hidden: true, footer: ''},
        {
            dataField: "tickerSymbol", text: "Symbol", sort: true, footer: '',
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            classes: 'dark:tw-text-primary-light hover:tw-text-primary dark:hover:tw-text-lighter focus:tw-outline-none focus:tw-ring-primary-darker',
            formatter: symbolFormatter
        },
        {
            dataField: "currency", text: "Currency", sort: true, footer: '',
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0', classes: 'd-sm-table-cell',
            footerClasses: 'd-none d-sm-table-cell'
        },
        {
            dataField: "tradeDate", text: "Trade date", sort: true, footer: '',
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0', classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: dateFormatter
        },
        {
            dataField: "purchasePrice", text: "Avg price", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0', classes: 'd-sm-table-cell', footerClasses: 'd-sm-table-cell',
            formatter: currencyFormatter,
            footer: columnData => getSum(columnData)
        },
        {
            dataField: "latestPrice", text: "Price", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0', classes: 'd-sm-table-cell', footerClasses: 'd-sm-table-cell',
            formatter: currencyFormatter,
            footer: columnData => getSum(columnData)
        },
        {
            dataField: "percentOfPortfolio", text: "% Portfolio", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: percentWithoutColourFormatter,
            footer: columnData => getPercentSum(columnData)
        },
        {
            dataField: "dailyChange", text: "Change", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: currencyFormatterColour,
            footer: columnData => getSum(columnData)
        },
        {
            dataField: "dailyChangePercent", text: "% Change", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: percentFormatter,
            footer: columnData => getPercentSumRows(columnData, dataRows)
        },
        {
            dataField: "totalReturnUSDPercent", text: "% Return USD", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: getReturnUSDPercent,
            footer: columnData => getReturnUSDPercentSumRows(columnData, dataRows)
        }
    ];
}


export function PortfolioPublicTable(props) {
    const [rows, setRows] = useState(props.dataRows ? props.dataRows : []);
    const columns = useMemo(() => getColumns(props.dataRows ? props.dataRows : []), []);


    const defaultSorted = [{
        dataField: 'tickerSymbol',
        order: 'asc'
    }];

    return (
        <div>

            <BootstrapTable bootstrap4
                            keyField='id'
                            data={rows}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            noDataIndication="Table is Empty"
                            bordered={false}
                            classes={`dark:tw-text-white`}
                            headerClasses={`tw-text-primary-purple`}
                            wrapperClasses="table-responsive"
                            rowClasses="text-nowrap"
                            // hover
                // condensed
            />
        </div>
    );
}