export const chartOptions = {
    layout: {
        padding: {
            right: 4,
            left: 4,
        },
    },
    tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
            label(tooltipItems, data) {
                return `$${tooltipItems.yLabel}`;
            },
        },
        displayColors: false,
    },
    hover: {
        mode: "index",
        intersect: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
        display: false,
    },
    scales: {
        xAxes: [
            {
                display: true, // display: false,
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                    fontColor: '#90A0C8'
                }
            },
        ],
        fontStyle: "bold",
        yAxes: [
            {
                gridLines: {
                    color: "rgba(0, 0, 0, 0)",
                },
                fontStyle: "bold",
                ticks: {
                    callback(value) {
                        return value.toFixed(2);
                    },
                    fontColor: '#90A0C8'
                },
            },
        ],
    },
    elements: {
        point: {
            radius: 0,
        },
        line: {
            borderCapStyle: "round",
            borderJoinStyle: "round",
        },
    },
};