import {API, Auth} from 'aws-amplify';

const apiName = 'UsersApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
    headers: () => {
        return {
            'Access-Control-Allow-Origin': `*`,
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
            'Content-Type': 'application/json'
        }
    }
};

class UserService {

    async getToken() {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    save(data) {
        // no Authorization as User won't be signed in yet
        tickersInit.body = data;
        return API.post(apiName, `/save`, tickersInit);
    }

    async subscribe(username, subscriberUsername) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        tickersInit.body = {username: subscriberUsername};
        return API.post(apiName, `/subscribe/${username}`, tickersInit);
    }

    async unsubscribe(username, subscriberUsername) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        tickersInit.body = {username: subscriberUsername};
        return API.post(apiName, `/unsubscribe/${username}`, tickersInit);
    }

    async update(id, data) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        tickersInit.body = data;
        return API.put(apiName, `/update/${id}`, tickersInit);
    }

    async retrieveUser(username, email) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        return API.get(apiName, `/find?username=${username}&email=${encodeURI(email)}`, tickersInit);
    }

    async updateFriendStatus(userId, friendEmail, status) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        tickersInit.body = {email: friendEmail, status: status};
        return API.put(apiName, `/updateFriendStatus/${userId}`, tickersInit);
    }

    async addFriend(userId, email) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        tickersInit.body = {email:email};
        return API.post(apiName, `/addFriend/${userId}`, tickersInit);
    }

    async retrieveFriends(username) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        console.log("in api retrieveFriends");
        return API.get(apiName, `/friends?username=${username}`, tickersInit);
    }

}

export default new UserService();
