import React, {useState} from "react";
import {TopFiveItem} from "./TopFiveItem";

export const TopFive = ({top5List}) => {
    {
        const [cards, setCards] = useState(top5List);

        const renderCard = (quote, index) => {
            return (<TopFiveItem key={quote.symbol + "" + index} index={index}
                                   symbol={quote.symbol}
                                   text={quote.companyName}
                                   currentPrice={quote.latestPrice}
                                   changePercent={quote.changePercent}
                                   changePrice={quote.change}
            />);
        };
        return (<>
            {/*tw-w-80 tw-min-w-min */}
            <div className="tw-rounded">
                {cards.map((card, i) => renderCard(card, i))}
            </div>
        </>);

    }
};
