import React from "react";
import {Line} from "react-chartjs-2";
import PropTypes from "prop-types";
import {chartOptions} from "./ChartOptions";


const options = chartOptions;

const TickerChart = ({
                       allData,
                       ytd,
                       month,
                       day,
                       year,
                       data1,
                       years,
                       changeFocus,
                       getYTDChart,
                       getOneWeekChart,
                       getOneYearChart,
                       getOneMonthChart,
                       getOneDayChart,
                       getAllDataChart
                     }) => (
  <>
    <div className="tw-w-full tw-relative">
    <div style={{height: '40vh'}}>
      <Line data={data1} options={options} />
    </div>


    <div className="Chart__timers tw-pl-16 tw-absolute tw--top-7 tw-right-0">
      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker"
          ref={allData}
          id="allData"
          onClick={() => {
            getAllDataChart();
            changeFocus(6);
          }}>
        ALL
      </h6>

      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker"
          ref={year}
          id="1y"
          onClick={() => {
            getOneYearChart();
            changeFocus(3);
          }}>
        1 Y
      </h6>

      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker"
          ref={ytd}
          id="ytd"
          onClick={() => {
            ytd.current.classList.add("active");
            changeFocus(5);
            getYTDChart();
          }}>
        YTD
      </h6>
      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker"
          ref={month}
          id="1m"
          onClick={() => {
            changeFocus(2);
            getOneMonthChart();
          }}>
        1 M
      </h6>
      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker
              active"
          ref={years}
          id="1w"
          onClick={() => {
            getOneWeekChart();
            changeFocus(4);
          }}>
        5 D
      </h6>
      <h6
          className="Chart__option tw-mr-2 tw-py-2 tw-px-4 tw-transition-colors tw-duration-200 tw-rounded-3xl
              tw-text-primary-lighter dark:tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-white-transparent
              hover:tw-text-primary hover:tw-bg-primary-100 dark:hover:tw-text-light dark:hover:tw-bg-gradient-blue-light
              focus:tw-outline-none focus:tw-bg-primary-100 dark:focus:tw-bg-primary-dark focus:tw-ring-primary-darker"
          ref={day}
          id="1d"
          onClick={() => {
            changeFocus(1);
            getOneDayChart();
          }}>
        1 D
      </h6>
    </div>
    </div>
  </>
);

TickerChart.propTypes = {
  changeFocus: PropTypes.func,
  getAllDataChart: PropTypes.func,
  getOneMonthChart: PropTypes.func,
  getOneYearChart: PropTypes.func,
  getOneWeekChart: PropTypes.func,
  getYTDChart: PropTypes.func,
  getOneDayChart: PropTypes.func,
  data1: PropTypes.func,
  year: PropTypes.object,
  allData: PropTypes.object,
  years: PropTypes.object,
  ytd: PropTypes.object,
  month: PropTypes.object,
  day: PropTypes.object,
};

export default TickerChart;
