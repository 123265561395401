import React from "react";
import "../Cdn.scss";
import "../LoaderButton.css";
import Loader from "./ui/Loader";

export default function LoaderButton({
                                         isLoading,
                                         className = "",
                                         disabled = false,
                                         ...props
                                     }) {
    return (
        <button
            className={`LoaderButton disabled:tw-opacity-50 tw-flex tw-items-center tw-justify-center ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {isLoading && <Loader />}
            {props.children}
        </button>
    );
}