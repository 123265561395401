import React from "react";
import {NavLink} from "react-router-dom";
import {ReactComponent as Twitter} from '../images/twitter.svg';
import {ReactComponent as Telegram} from '../images/telegram.svg';

function Footer() {
    return (

        <footer className=" tw-mt-auto tw-flex tw-items-center tw-justify-between tw-py-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10">
            <div className="tw-text-primary-blueText dark:tw-text-light">
                &copy; Trades League {new Date().getFullYear()} | All rights reserved
            </div>
            <div className="tw-flex tw-items-center tw-justify-center">
                <div className="tw-px-4">
                    <NavLink to="/terms" className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none focus:tw-ring-primary-darker">Terms of Use</NavLink>
                </div>
                <div className="tw-px-4">
                    <NavLink to="/privacyPolicy" className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none focus:tw-ring-primary-darker">Privacy policy</NavLink>
                </div>
                <div className="tw-pl-4">
                    <NavLink to="/tokenUsage" className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none focus:tw-ring-primary-darker">Token Usage</NavLink>
                </div>
                <div className="tw-pl-4">
                    <a href={`${process.env.REACT_APP_WHITE_PAPER_URL}`}
                       target="_blank"
                       className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none focus:tw-ring-primary-darker">White Paper</a>
                </div>
                <div className="tw-pl-4">
                    <a href="https://twitter.com/TradesLeagueCom" target="_blank" className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none">
                        <Twitter />
                    </a>
                </div>
                <div className="tw-pl-4">
                    <a href="https://t.me/tradesleague" target="_blank" className="tw-transition-colors tw-duration-200
                            tw-text-primary-blueText dark:tw-text-light
                            hover:tw-text-primary dark:hover:tw-text-primary-light
                            focus:tw-outline-none">
                        <Telegram />
                    </a>
                </div>
            </div>

        </footer>
    );
}

export default Footer;