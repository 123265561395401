import React from 'react';
import { CSVReader } from 'react-papaparse'
import { Auth } from 'aws-amplify';
import PortfolioService from '../../services/PortfolioService'
import Alert from "react-bootstrap/Alert";
import {CSVLink} from "react-csv";

const buttonRef = React.createRef()
const csvData = [
    ['Symbol','Trade Date','Purchase Price','Currency','Quantity']
];

class ImportPortfolio extends React.Component {

    constructor(props) {
        super(props);
        this.handleOnDrop = this.handleOnDrop.bind(this);
        this.savePortfolio = this.savePortfolio.bind(this);
        this.handleOnError = this.handleOnError.bind(this);

        this.state = {
            csvData: null,
            // errorMessage: null
        };
    }

    handleOnDrop = (data) => {
        console.log('------------data---------------');
        this.setState({
            csvData: data
        });

        console.log(data);
        console.log('---------------------------');

    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
        this.setState({
            errorMessage: "Error during import. Try again!"
        });
    }

    handleOnRemoveFile = (data) => {
        console.log('---------------------------');
        console.log(data);
        this.setState({
            csvData: null
        });
        console.log('---------------------------');
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }
    }

    savePortfolio = async (event) => {
        if (this.state.csvData === null) {
            event.preventDefault();
            this.setState({
                errorMessage: "No data, please import CSV."
            });
            return;
        }

        let username = "";
        await Auth.currentUserInfo()
            .then(userInfo => {
                console.log(userInfo);
                console.log(userInfo.username);
                username = userInfo.username;
                let importMsg = [];
                this.setState({
                    errorMessage: null
                });

                this.state.csvData.map((row, index) => {
                        let postData = {
                            username: username,
                            tickerSymbol: row.data["Symbol"],
                            tradeDate: row.data["Trade Date"],
                            purchasePrice: row.data["Purchase Price"],
                            currency: row.data["Currency"],
                            quantity: parseInt(row.data["Quantity"]) // TODO Allow fractional shares?
                        }
                        console.log(postData);

                        if (postData.quantity === null || postData.quantity === 0) {
                            importMsg.push(`Error importing ticker ${postData.tickerSymbol} with no quantity, line ${index + 1}.`);
                        } else if (postData.tickerSymbol === null || postData.tickerSymbol.length === 0) {
                            importMsg.push(`Error importing ticker with no symbol, line ${index + 1}.`);
                        } else {
                            PortfolioService.create(postData)
                                .then(r => {
                                    console.log(r);
                                })
                                .catch(e => {
                                    importMsg.push(`Error importing ticker ${postData.tickerSymbol}, line ${index + 1}.`);
                                    console.log(e);
                                });
                        }
                    }
                );

                console.log(importMsg);

                this.props.displayErrorMessage(importMsg);


            }).then(() => {
                // TODO call getPortfolio and update the state instead
                // window.location.reload(false);
                this.props.retrievePortfolio();
            })
            .catch(err => {
                    console.log(err);
                    this.setState({
                        errorMessage: "Error importing portfolio. Try again!"
                    });
                }
            );
    }


    render() {
        return (
            <>

                {this.state.errorMessage ?
                    <div className="tw-mt-2">
                        <Alert variant="danger" onClose={() => this.setState({
                            errorMessage: null
                        })} dismissible>
                            <p>
                                {this.state.errorMessage}
                            </p>
                        </Alert>
                    </div>
                    : <div></div>
                }

                <div className="lg:tw-grid lg:tw-grid-cols-3 lg:tw-gap-6 tw-p-4 tw-rounded-3xl
                            tw-bg-white dark:tw-bg-white-transparent dark:tw-text-light tw-text-gray-500 dark:tw-text-light">
                    <div className="lg:tw-col-span-1">
                        <h3 className="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-500 dark:tw-text-light">
                            It looks like you don't have a portfolio yet
                        </h3>
                        <div className="tw-mt-1 tw-text-sm tw-text-gray-500 dark:tw-text-light">
                            <span>Upload your portfolio or add tickers manually</span>
                            <div className="tw-px-4 tw-text-left">
                                <ol className="tw-list-decimal">
                                    <li>Upload your Yahoo portfolio with a CSV format, add Currency column if the price is not in USD <br />OR</li>
                                    <li>Download the following <CSVLink data={csvData} separator={","} enclosingCharacter={``}
                                                                        filename={"trades-share-template.csv"}
                                                                        className="tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker"
                                    >Template</CSVLink> </li>
                                    <li>Fill it (TRADE DATE is with format yyyyMMdd, Currency should be like EUR, GBP, CAD, CNY,...)</li>
                                    {/*<li>All Price will be converted to USD</li>*/}
                                    <li>Upload the CSV</li>
                                    <li>Click Save</li>
                                </ol>
                            </div>
                        </div>

                    </div>
                    <div className="lg:tw-col-span-2 tw-h-full">
                        <div className="tw-mt-4 lg:tw-mt-0 tw-w-full tw-h-3/4">
                            <CSVReader
                                onDrop={this.handleOnDrop}
                                onError={this.handleOnError}
                                addRemoveButton
                                onRemoveFile={this.handleOnRemoveFile}
                                config={{header: true}}
                            >
                                <span>Drop CSV file here or click to upload.</span>
                            </CSVReader>
                        </div>
                        <div className="tw-mt-4 tw-text-right dark:tw-border-t dark:tw-border-primary-dark ">
                            <button onClick={this.savePortfolio}
                                    className="tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker">
                                Save
                            </button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

export default ImportPortfolio;