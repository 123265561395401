import {API, Auth} from 'aws-amplify';

const apiName = 'NotificationsApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
    headers: () => {
        return {
            'Access-Control-Allow-Origin': `*`,
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
            'Content-Type': 'application/json'
        }
    }
};

class NotificationService {

    async getToken() {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    saveNotification(username, title, description) {
        let postData = {
            target: username,
            title: title,
            description: description,
            status: "READY"
        };
        tickersInit.body = postData;
        return API.post(apiName, `/save`, tickersInit);
    }

    async retrieveNotifications(username) {
        tickersInit.headers['Authorization'] = `Bearer ${await this.getToken()}`;
        return API.get(apiName, `/search?target=${username}`, tickersInit);
    }
}

export default new NotificationService();
