import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import {TableAction} from "./TableAction";
import {TickerModal} from "./TickerModal";
import {
    currencyFormatter,
    currencyFormatterColour, dateFormatter,
    getPercentSum, getPercentSumRows, getReturnUSDPercent, getReturnUSDPercentSumRows,
    getSum,
    percentFormatter, percentWithoutColourFormatter,
    symbolFormatter, totalFormatter
} from "./TableFormatters";
import {ExchangesModal} from "./ExchangesModal";
import {FiArrowRight, FiEye} from "react-icons/fi";


function getColumns(rankFormatter, dataRows) {
    return [
        {dataField: "id", text: "ID", sort: true, hidden: true, footer: ''},
        {dataField: "username", text: "USERNAME", hidden: true, footer: ''},
        {dataField: "tickerName", text: "Name", hidden: true, footer: ''},
        {dataField: "tickerSymbol", text: "Symbol", sort: true, footer: '',
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            classes: 'dark:tw-text-primary-light hover:tw-text-primary dark:hover:tw-text-lighter focus:tw-outline-none focus:tw-ring-primary-darker',
            formatter: symbolFormatter
        },
        {dataField: "currency", text: "Currency", sort: true, footer: '',
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell border-bottom-0', footerClasses: 'd-none d-sm-table-cell'
        },
        {
            dataField: "tradeDate", text: "Trade date", sort: true, footer: '',
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: dateFormatter
        },
        {
            dataField: "purchasePrice", text: "Avg price", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: currencyFormatter,
            footer: ''
        },
        {
            dataField: "latestPrice", text: "Price", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-sm-table-cell', footerClasses: 'd-sm-table-cell',
            formatter: currencyFormatter,
            footer: ''
        },
        {
            dataField: "quantity", text: "Quantity", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            columnClassName: 'hidden-xs', footer: ''
        },
        {
            dataField: "total", text: "Total", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: totalFormatter,
            footer: ''
        },
        {
            dataField: "totalUSD", text: "Total USD", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: currencyFormatter,
            footer: columnData => getSum(columnData)
        },
        {
            dataField: "dailyChange", text: "Change", sort: true,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            classes: 'd-none d-sm-table-cell', footerClasses: 'd-none d-sm-table-cell',
            formatter: currencyFormatterColour,
            footer: ''
        },
        {
            dataField: "dailyChangePercent", text: "% change", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: percentFormatter,
            footer: columnData => getPercentSumRows(columnData, dataRows)
        },
        {
            dataField: "totalReturn", text: "Return", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: currencyFormatterColour,
            footer: ''
        },
        {
            dataField: "totalReturnUSD", text: "Return USD", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: currencyFormatterColour,
            footer: columnData => getSum(columnData)
        },
        {
            dataField: "totalReturnUSDPercent", text: "% Return USD", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: getReturnUSDPercent,
            footer: columnData => getReturnUSDPercentSumRows(columnData, dataRows)
        },
        {
            dataField: "percentOfPortfolio", text: "% Portfolio", sort: true,
            headerClasses: 'd-sm-table-cell border-bottom-0 border-top-0',
            formatter: percentWithoutColourFormatter,
            footer: columnData => getPercentSum(columnData)
        },
        {
            dataField: "edit", text: "", sort: false,
            headerClasses: 'd-none d-sm-table-cell border-bottom-0 border-top-0',
            formatter: rankFormatter,
            footer: "",
            headerAttrs: {width: 80},
            // attrs: { width: 50, className: "EditRow" }
        }
    ];
}


export function PortfolioTable(props) {
    const [rows, setRows] = useState(props.dataRows ? props.dataRows : []);
    const columns = useMemo(() => getColumns(rankFormatter, props.dataRows ? props.dataRows : []), []);

    const updateDeletedRows = useCallback((rowId) => {
        const newList = rows.filter((item) => item.id !== rowId);
        // setRows(newList);
        props.setDataRows(newList);

    }, [rows]);

    const updateAddedRows = useCallback((rowIndex, newTicker) => {
        console.log("updateAddedRows");
        const updatedRows = [...rows];
        updatedRows.push(newTicker);
        setRows(updatedRows);
        props.setDataRows(updatedRows);
    }, [rows]);

    const updateUpdatedRow = useCallback((rowIndex, newTicker) => {
        console.log("updateAddedRows");

        const newList = rows.map((item) => {
            if (item.id === newTicker.id) {
                return newTicker;
            }

            return item;
        });

        setRows(newList);
        props.setDataRows(newList);

    }, [rows]);

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <TableAction row={row} updateRows={updateDeletedRows} rowIndex={rowIndex} updateUpdatedRow={updateUpdatedRow}></TableAction>
        );
    }

    const defaultSorted = [{
        dataField: 'tickerSymbol',
        order: 'asc'
    }];
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true
    };

    return (
        <>

            <div className="tw-inline-block tw-flex tw-items-center tw-float-left tw-mt-2">
                <ExchangesModal />
            </div>
            <div className="tw-inline-block tw-flex tw-items-center tw-float-right tw-mb-2">

                <TickerModal updateAddedRows={updateAddedRows} username={props.username} />
                {rows &&
                <button onClick={props.toggleShowImportPortfolio}
                        className="tw-ml-4 tw-flex tw-items-center tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                >
                    <div className="tw-inline-block tw-mr-2">Import</div>
                    <div className="tw-inline-block"><FiArrowRight className="tw-text-lg" /></div>
                </button>

                }
            </div>

            <BootstrapTable bootstrap4
                            keyField='id'
                            data={rows}
                            columns={columns}
                            defaultSorted={defaultSorted}
                // selectRow={selectRow}
                            noDataIndication="Table is Empty"
                            bordered={false}
                            classes={`dark:tw-text-white`}
                            headerClasses={`tw-text-primary-purple`}
                            wrapperClasses="table-responsive"
                            rowClasses="text-nowrap"
                            // hover
                // condensed
            />
        </>
    );
}