import {API} from 'aws-amplify';

const apiName = 'SentimentsApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class SentimentService {

    saveSentiment(username, stock, value) {
        tickersInit.body = {
            username: username,
            tickerSymbol: stock,
            value: value
        }

        return API.post(apiName, `/save`, tickersInit);
    }

    getSentiment(username, symbol) {
        return API.get(apiName, `/search?username=${username}&tickerSymbol=${symbol}`, tickersInit);
    }
}

export default new SentimentService();
