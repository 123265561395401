import React from "react";
import Roadmap from "../components/roadmap";

export default function Token() {


    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-mb-8">
                <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Token</span>
            </div>

            <div className="tw-py-8 tw-px-2 tw-space-y-8 sm:tw-px-4 lg:tw-mr-20 lg:tw-ml-10 tw-text-justify
                            tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">

                <div>
                    <p className="tw-text-3xl tw-font-medium tw-text-center tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-8">What is the TRALE Token?</p>
                    <div>
                        The TRALE token is the utilities token for the platform, it allows to follow members outside
                        your network, to get access to portfolios, to receive alerts when there is sell or a buy.
                        We are working on other functions which will be implemented later on.
                        We are using the Binance Smart Chain for these reasons:
                        <ul className="tw-list-disc tw-list-inside tw-space-y-1 tw-pl-4">
                            <li>minimum cost per transactions,</li>
                            <li>a skyrocketing ecosystem with a booming PancakeSwap,</li>
                            <li>transactions are much faster compared to other blockchain network (few seconds compare
                                to several minutes with other networks)
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <p className="tw-text-3xl tw-font-medium tw-text-center tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-8">How do I get some TRALE?</p>
                    <div>
                        <ol className="tw-list-decimal tw-list-inside tw-space-y-1">
                            <li>Register to <a href="https://www.binance.com/en/register?ref=JSN1YL39" target="_blank"
                                               className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">Binance.com </a>
                            </li>
                            <li>Buy some some Binance coins: BNB</li>
                            <li>Install the <a href="https://metamask.io/download.html" target="_blank"
                                                         className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">Metamask wallet
                            </a>
                                {/*or <a href="https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp" target="_blank"*/}
                                {/*       className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">*/}
                                {/*Binance Chain Wallet</a>*/}
                            </li>
                            <li>
                                Create your wallet
                            </li>
                            <li>Transfer your BNB from Binance to your wallet</li>
                            <li>Go to
                                <a href="https://pancakeswap.finance/swap?outputCurrency=0x85eed1605ccd82c1b1ba8b1530d40c5940b1476f" target="_blank"
                                   className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light"> PancakeSwap
                                </a>, approve the addition of the Token and swap some BNB to TRALE!
                            </li>
                            <li>Login to TradesLeague.com and connect your wallet
                                {/*<ul className="tw-list-disc tw-list-inside tw-space-y-1 tw-pl-8">*/}
                                {/*    <li>*/}
                                {/*        <a href="https://metamask.io/download" target="_blank"*/}
                                {/*           className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">*/}
                                {/*            Metamask*/}
                                {/*        </a>*/}
                                {/*    </li>*/}
                                {/*    <li>*/}
                                {/*        <a href="https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp?hl=en"*/}
                                {/*           className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">*/}
                                {/*            Binance Chain Wallet*/}
                                {/*        </a>*/}
                                {/*    </li>*/}
                                {/*    <li>*/}
                                {/*        <a href="https://atomicwallet.io/downloads"*/}
                                {/*           className="tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-primary-light">*/}
                                {/*            Atomic Wallet*/}
                                {/*        </a>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                            </li>
                        </ol>
                        That’s it, you are all set!

                    </div>
                </div>
                <Roadmap />
            </div>

        </>
    );
}