import React from 'react';
import {NavLink} from "react-router-dom";
import {Auth} from "aws-amplify";
import {onError} from "../libs/errorLib";
import withUseAppContext from "../components/contextComponent";
import LoaderButton from "../components/loaderButton";
// import FacebookButton from "../components/auth/FacebookButton";
// import ErrorBoundary from "../components/ErrorBoundary";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isLoading: false,
            validated: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    async handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        console.log('A email was submitted: ' + this.state.email);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            this.setState({isLoading: true});
            // const { userHasAuthenticated } = useAppContext();
            // const history = useHistory();
            try {
                await Auth.signIn(this.state.email, this.state.password).then(u => this.props.setAuthenticatedUser(u));
                console.log("login");
                this.props.userHasAuthenticated(true);
                // this.props.history.push("/");
            } catch (error) {
                onError(error);
                this.setState({isLoading: false});
            }
        }
        this.setState({validated: true});
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleFbLogin = (response) => {
        console.log("inhandleFBLogin");
        console.log(response);
        this.props.userHasAuthenticated(true);
    };

    componentDidMount() {
        console.log("in guigui");
        console.log(this.props.isAuthenticated);
        console.log(this.props.authenticatedUser);
    }

    render() {

        return (
            <>
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-py-20">

                    <div
                        className="tw-w-full tw-max-w-sm tw-px-4 tw-py-6 tw-space-y-6 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                        <h1 className="tw-text-xl tw-font-semibold tw-text-center">Login To Trades League</h1>
                        <form
                            // noValidate
                            // validated={this.state.validated}
                            className="padding-form border-blue"
                            onSubmit={this.handleSubmit}
                            className="tw-space-y-6">
                            <input
                                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                type="email"
                                name="email"
                                placeholder="Email address"
                                value={this.state.email}
                                onChange={this.handleChange}
                                required
                            />
                            <input
                                className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                required
                            />
                            <div className="tw-flex tw-items-center tw-justify-between">
                                <label className="tw-flex tw-items-center">

                                </label>

                                <NavLink to="/login/reset"
                                         className="tw-text-sm tw-text-primary hover:tw-text-primary-lighter dark:hover:tw-text-light">
                                    Forgot your password?
                                </NavLink>
                            </div>
                            <div>
                                <LoaderButton type="submit" isLoading={this.state.isLoading}
                                              disabled={!this.validateForm()}
                                              className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center
                                                        tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light
                                                        hover:tw-filter hover:tw-drop-shadow-blue-dark
                                                        disabled:hover:tw-bg-primary hover:tw-bg-primary-dark focus:tw-outline-none
                                                        focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                >
                                    Login
                                </LoaderButton>
                            </div>
                        </form>

                        {/*<div className="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-flex-nowrap">*/}
                        {/*    <span className="tw-w-20 tw-h-px tw-bg-gray-300"></span>*/}
                        {/*    <span>OR</span>*/}
                        {/*    <span className="tw-w-20 tw-h-px tw-bg-gray-300"></span>*/}
                        {/*</div>*/}


                        {/* Brand icons src https://boxicons.com */}
                        {/*<a*/}
                        {/*    href="#"*/}
                        {/*    className="tw-flex items-center tw-justify-center tw-px-4 tw-py-2 tw-space-x-2 tw-text-white tw-transition-all tw-duration-200 tw-bg-black tw-rounded-md hover:tw-bg-opacity-80 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-black focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"*/}
                        {/*>*/}
                        {/*    <FiFacebook className="tw-w-6 tw-h-6 tw-text-white" fill="currentColor" />*/}
                        {/*    <span> Login with Facebook </span>*/}
                        {/*</a>*/}
                        {/*<ErrorBoundary>*/}
                        {/*    <FacebookButton*/}
                        {/*        onLogin={this.handleFbLogin}*/}
                        {/*    />*/}
                        {/*</ErrorBoundary>*/}
                        <div className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
                            Don't have an account yet? <NavLink to="/signUp"
                                                                className="tw-transition-colors tw-duration-200 tw-text-primary hover:tw-text-primary-lighter dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker active">
                            Sign Up!</NavLink>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default withUseAppContext(Login);
