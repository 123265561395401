import React, {useCallback, useEffect, useRef, useState} from "react";
import {FiMenu, FiBell} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import DarkToggle from "../ui/DarkToggle";
import ProfileRender from "./Profile";
import SlidePanel from "../SlidePanel";
import {getCurrentUserInfo, retrieveUser} from "../../libs/getUsername";
import Notifications from "../Notifications/Notifications";

export default function NavbarAuthenticated(props) {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [avatarImg, setAvatarImg] = useState(null);
    const [username, setUsername] = useState(null);
    const darkModeBtnRef = useRef(null)

    const fetchUserDB = useCallback(async () => {

        let response = await getCurrentUserInfo();
        console.log("in header getUsername=");
        if (response === null) {
            return undefined;
        }

        const userDbResponse = await retrieveUser(response.username, response.attributes.email);

        if (userDbResponse !== null && !userDbResponse.length) {

            if (userDbResponse.darkModeDefault !== null
                && userDbResponse.darkModeDefault === "dark"
                && (!localStorage || !localStorage.getItem("theme"))
            ) {
                // There is probably a best way to do that but
                // default is light theme, so if profile setting is dark click to switch
                // use localStorage.getItem("theme")
                darkModeBtnRef.current.click();
            }
            setUsername(userDbResponse.username);
            setAvatarImg(userDbResponse.avatar);

        }
    }, []);

    useEffect(() => {
        fetchUserDB().then();
    }, [fetchUserDB]);

    const drawerToggleClickHandler = () => {
        setDrawerIsOpen(!drawerIsOpen);
    };

    const backdropClickHandler = () => {
        setDrawerIsOpen(false);
    };


    return (
        <>
            <header className="tw-relative tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10">

                <nav className="tw-relative tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-pt-4 tw-pb-8">

                    <div className="tw-w-full tw-relative tw-flex tw-justify-between tw-items-center">
                        <button
                            className="tw-text-black dark:tw-text-primary-light tw-cursor-pointer tw-text-xl tw-leading-none tw-px-3 tw-py-1 tw-border tw-border-solid tw-border-transparent tw-rounded tw-bg-transparent tw-block md:tw-hidden tw-outline-none focus:tw-outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <FiMenu />
                        </button>
                        <NavLink to="/">
                            <img src="/logo.svg" className="App-logo tw-hidden lg:tw-inline-block" alt="logo" />
                            <img src="/favicon.svg" className="tw-w-14 tw-inline-block lg:tw-hidden tw-cursor-default"
                                 alt="logo" />
                        </NavLink>
                        <div className="nav-item tw-flex">
                            {/* User avatar button */}
                            <ProfileRender userHasAuthenticated={props.userHasAuthenticated} history={props.history}
                                           avatar={avatarImg} />
                        </div>
                    </div>
                    <div
                        className={
                            "md:tw-flex tw-flex-grow tw-items-center" +
                            (navbarOpen ? " tw-flex" : " tw-hidden")
                        }
                        id="navbar-danger"
                    >
                        <ul className="tw-flex tw-flex-col tw-space-x-2 tw-items-center md:tw-hidden tw-list-none">
                            <li className="nav-item">
                                <NavLink to="/">
                                    <div
                                        className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                        Dashboard
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/league">
                                    <div
                                        className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                        The League
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/portfolio">
                                    <div
                                        className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                        Portfolio
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/stocks">
                                    <div
                                        className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                        Stocks
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/token">
                                    <div
                                        className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">
                                        Token
                                    </div>
                                </NavLink>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <NavLink to="/forum">*/}
                            {/*        <div*/}
                            {/*            className="tw-p-2 tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker">*/}
                            {/*            Forum*/}
                            {/*        </div>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            <li className="nav-item">
                                {/* Dark theme button */}
                                <div aria-hidden="true" className="tw-relative focus:tw-outline-none">
                                    <span className="tw-sr-only">Toggle Dark Mode</span>
                                    <DarkToggle btnRef={darkModeBtnRef} />
                                </div>
                            </li>
                            <li className="nav-item">
                                {/* Notification button */}
                                <button onClick={drawerToggleClickHandler}
                                        className="tw-p-2 tw-transition-colors tw-duration-200
                                               focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                               active:tw-text-primary-lighter dark:active:tw-text-primary-lighter">
                                    <span className="tw-sr-only">Open Notification</span>
                                    <FiBell
                                        className="tw-w-7 tw-h-7 tw-fill-current tw-text-primary-purple dark:tw-text-light hover:tw-text-primary-lighter dark:hover:tw-text-primary-lighter dark:active:tw-text-primary-lighter"
                                        fill="currentColor" />
                                </button>
                            </li>
                        </ul>
                    </div>

                </nav>
            </header>

            <SlidePanel show={drawerIsOpen} close={backdropClickHandler}>
                {username ?
                    <Notifications username={username} />
                    : ""
                }
            </SlidePanel>
        </>
    );
}