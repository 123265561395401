import React, {useCallback, useEffect, useRef, useState} from "react";
import {FiBell} from "react-icons/fi";
import {NavLink} from "react-router-dom";
import DarkToggle from "../ui/DarkToggle";
import SlidePanel from "../SlidePanel";
import {getCurrentUserInfo, retrieveUser} from "../../libs/getUsername";
import Notifications from "../Notifications/Notifications";

export default function NSidebarAuthenticated(props) {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [avatarImg, setAvatarImg] = useState(null);
    const [username, setUsername] = useState(null);
    const darkModeBtnRef = useRef(null)

    const fetchUserDB = useCallback(async () => {

        let response = await getCurrentUserInfo();
        console.log("in header getUsername=");
        if (response === null) {
            return undefined;
        }

        const userDbResponse = await retrieveUser(response.username, response.attributes.email);

        if (userDbResponse !== null && !userDbResponse.length) {

            if (userDbResponse.darkModeDefault !== null
                && userDbResponse.darkModeDefault === "dark"
                && (!localStorage || !localStorage.getItem("theme"))
            ) {
                // There is probably a best way to do that but
                // default is light theme, so if profile setting is dark click to switch
                // use localStorage.getItem("theme")
                darkModeBtnRef.current.click();
            }
            setUsername(userDbResponse.username);
            setAvatarImg(userDbResponse.avatar);

        }
    }, []);

    useEffect(() => {
        fetchUserDB().then();
    }, [fetchUserDB]);

    const drawerToggleClickHandler = () => {
        setDrawerIsOpen(!drawerIsOpen);
    };

    const backdropClickHandler = () => {
        setDrawerIsOpen(false);
    };


    return (
        <>
            <aside
                // x-show="isSidebarOpen"
                //    x-transition:enter="transition-all transform duration-300 ease-in-out"
                //    x-transition:enter-start="-translate-x-full opacity-0"
                //    x-transition:enter-end="translate-x-0 opacity-100"
                //    x-transition:leave="transition-all transform duration-300 ease-in-out"
                //    x-transition:leave-start="translate-x-0 opacity-100"
                //    x-transition:leave-end="-translate-x-full opacity-0" x-ref="sidebar"
                tabIndex="-1"
                className="tw-hidden md:tw-block tw-inset-y-0 tw-z-10 tw-flex tw-flex-shrink-0
                        tw-bg-white md:tw-static dark:tw-bg-gradient-blue-darker focus:tw-outline-none">
                {/*Mini column*/}
                <nav
                    className="tw-flex tw-flex-col tw-flex-shrink-0 tw-h-full tw-px-2 tw-py-4">
                    {/* Notification button */}
                    <div className="tw-flex-shrink-0">
                        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                            <button onClick={drawerToggleClickHandler}
                                    className="tw-p-2 tw-transition-colors tw-duration-200
                                               focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                               active:tw-text-primary-lighter dark:active:tw-text-primary-lighter">
                                <span className="tw-sr-only">Open Notification</span>
                                <FiBell
                                    className="tw-w-7 tw-h-7 tw-fill-current tw-text-primary-purple dark:tw-text-light hover:tw-text-primary-blueText dark:hover:tw-text-primary-lighter dark:active:tw-text-primary-lighter"
                                    fill="currentColor" />
                            </button>
                        </div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-1 tw-space-y-4">
                        {/*Dashboard -->*/}
                        <NavLink to="/" exact activeClassName="tw-font-extrabold"
                                 className="tw-group tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center
                                            focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                            tw-text-primary-purple ">
                            <span className="sr-only">Dashboard</span>
                            <svg
                                className="group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200"
                                width="24" height="23" viewBox="0 0 24 23" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.5033 11.1447L12.5988 0.248201C12.5203 0.169532 12.427 0.10712 12.3244 0.0645359C12.2217 0.0219519 12.1116 3.26352e-05 12.0005 3.26158e-05C11.8893 3.25964e-05 11.7793 0.0219518 11.6766 0.0645358C11.574 0.10712 11.4807 0.169532 11.4022 0.248201L0.497702 11.1447C0.180019 11.4624 -2.0796e-06 11.8939 -2.15829e-06 12.344C-2.32168e-06 13.2785 0.759791 14.0383 1.69431 14.0383L2.84327 14.0383L2.84326 21.8136C2.84326 22.2822 3.22184 22.6608 3.69042 22.6608L10.3062 22.6608L10.3062 16.7307L13.2712 16.7307L13.2712 22.6608L20.3106 22.6608C20.7791 22.6608 21.1577 22.2822 21.1577 21.8136L21.1577 14.0383L22.3067 14.0383C22.7567 14.0383 23.1882 13.8609 23.5059 13.5406C24.1651 12.8788 24.1651 11.8066 23.5033 11.1447Z" />
                            </svg>

                            <div
                                className="tw-pt-1 tw-text-sm dark:tw-text-light group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200">
                                Dashboard
                            </div>
                        </NavLink>

                        {/*League -->*/}
                        <NavLink to="/league" exact activeClassName="tw-font-extrabold"
                                 className="tw-group tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center
                                            focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                            tw-text-primary-purple active:tw-text-primary-lighter">
                            <span className="sr-only">The League</span>
                            <svg
                                className="group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200"
                                width="28" height="29" viewBox="0 0 28 29" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M20.563 27.0201H19.7427V25.2701H8.2583V27.0201H7.43799C6.98457 27.0201 6.61768 27.387 6.61768 27.8405C6.61768 28.2939 6.98457 28.6608 7.43799 28.6608H20.563C21.0164 28.6608 21.3833 28.2939 21.3833 27.8405C21.3833 27.387 21.0164 27.0201 20.563 27.0201Z" />
                                <path
                                    d="M14.1166 7.24011L14.0005 7.0078L13.8891 7.23049C13.687 7.64666 13.2778 7.67925 13.2723 7.68472L13.0143 7.72317L13.1914 7.89778C13.3856 8.08279 13.4813 8.36181 13.4317 8.63481L13.3909 8.88232L13.6224 8.76217C13.8547 8.64126 14.1388 8.63743 14.3786 8.76217L14.6101 8.88232L14.5676 8.62518C14.5235 8.36164 14.6109 8.09323 14.8008 7.90659L14.9866 7.72317L14.7286 7.68472C14.4643 7.64463 14.236 7.47882 14.1166 7.24011Z" />
                                <path
                                    d="M27.1802 2.30139H23.0065C23.0133 2.02861 23.0239 1.75867 23.0239 1.48108C23.0239 1.02766 22.657 0.660767 22.2036 0.660767H5.79736C5.34395 0.660767 4.97705 1.02766 4.97705 1.48108C4.97705 1.75867 4.98766 2.02872 4.9945 2.30139H0.820801C0.367387 2.30139 0.000488281 2.66829 0.000488281 3.1217V5.19972C0.000488281 9.7793 3.66838 13.4994 8.18912 13.74C9.13188 15.0367 10.2545 15.9995 11.5252 16.5463C11.3388 20.2894 9.35282 22.8819 8.71035 23.6295H19.2893C18.6471 22.8871 16.6615 20.31 16.4751 16.5463C17.7461 15.9996 18.8693 15.037 19.8121 13.74C24.3326 13.4993 28.0005 9.77925 28.0005 5.19972V3.1217C28.0005 2.66829 27.6336 2.30139 27.1802 2.30139ZM1.64111 5.19972V3.94202H5.0762C5.32093 7.06735 6.03197 9.80736 7.11878 11.9651C4.02095 11.2676 1.64111 8.50531 1.64111 5.19972ZM17.3074 7.73995L16.2556 8.77573L16.4975 10.2321C16.5488 10.5389 16.4222 10.8482 16.1707 11.03C15.9183 11.2133 15.5866 11.2377 15.3103 11.0949L14.0005 10.4148L12.6907 11.0949C12.4136 11.2359 12.0819 11.2127 11.8304 11.03C11.5788 10.8482 11.4523 10.539 11.5035 10.2321L11.7454 8.77573L10.6936 7.73995C10.4679 7.51765 10.3954 7.18958 10.4894 6.90203C10.5855 6.60721 10.8403 6.39092 11.1479 6.34449L12.6074 6.12498L13.2667 4.80559C13.5439 4.24963 14.4572 4.24963 14.7343 4.80559L15.3937 6.12498L16.8532 6.34449C17.1608 6.39098 17.4156 6.60727 17.5117 6.90203C17.6078 7.19762 17.5285 7.52208 17.3074 7.73995ZM26.3599 5.19972C26.3599 8.5052 23.98 11.2675 20.8823 11.965C21.969 9.80725 22.6802 7.06746 22.9248 3.94202H26.3599V5.19972Z" />
                            </svg>

                            <div
                                className="tw-pt-1 tw-text-sm dark:tw-text-light group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200">
                                The League
                            </div>
                        </NavLink>

                        {/* Portfolio -->*/}
                        <NavLink to="/portfolio" activeClassName="tw-font-extrabold"
                                 className="tw-group tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center
                                            focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                            tw-text-primary-purple active:tw-text-primary-lighter">
                            <span className="sr-only">Portfolio</span>
                            <svg
                                className="group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200"
                                width="28" height="29" viewBox="0 0 28 29" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path
                                        d="M22.0738 5.34916H7.63142C7.18402 5.34916 6.82133 4.98647 6.82133 4.53907V4.13373C6.82133 3.67758 6.45154 3.30779 5.99539 3.30779H1.65238C0.740082 3.30779 0.000488281 4.04732 0.000488281 4.95968V18.8592L2.12001 8.5475C2.19745 8.17082 2.52896 7.9005 2.91353 7.9005H23.7257V7.00105C23.7257 6.0887 22.9861 5.34916 22.0738 5.34916Z" />
                                    <path
                                        d="M26.3483 9.52069H3.57398L0.38641 25.0282C0.281847 25.5371 0.670457 26.0137 1.18993 26.0137H24.8891C25.7312 26.0137 26.4384 25.3804 26.531 24.5434L27.9902 11.3543C28.0984 10.3759 27.3325 9.52069 26.3483 9.52069Z" />
                                </g>
                                <rect x="6.53381" y="10.9274" width="17.7333" height="14" rx="1" fill="#061842" />
                                <path
                                    d="M22.5443 10.7408H8.25657C6.72271 10.7408 5.47601 11.9875 5.47601 13.5213V23.0802C5.47601 24.6141 6.72271 25.8608 8.25657 25.8608H22.5443C24.0782 25.8608 25.3249 24.6141 25.3249 23.0802V13.5213C25.3249 11.9875 24.0782 10.7408 22.5443 10.7408ZM12.7366 16.6052V11.6265C15.1632 11.9956 17.0338 14.0774 17.0338 16.6052C17.0338 19.3928 14.7659 21.6608 11.9782 21.6608C10.8579 21.6608 9.83165 21.2826 8.99242 20.6628L12.5141 17.1411C12.6567 16.9995 12.7366 16.8064 12.7366 16.6052ZM11.2199 11.6265V16.2918L7.92065 19.591C7.30083 18.7518 6.92268 17.7255 6.92268 16.6052C6.92268 14.0774 8.79323 11.9956 11.2199 11.6265ZM23.1199 24.9469H7.68101C7.26241 24.9469 6.92268 24.6071 6.92268 24.1885C6.92268 23.7699 7.26241 23.4302 7.68101 23.4302H23.1199C23.5385 23.4302 23.8782 23.7699 23.8782 24.1885C23.8782 24.6071 23.5385 24.9469 23.1199 24.9469ZM23.1199 21.6608H18.8032C18.3846 21.6608 18.0449 21.321 18.0449 20.9024C18.0449 20.4838 18.3846 20.1441 18.8032 20.1441H23.1199C23.5385 20.1441 23.8782 20.4838 23.8782 20.9024C23.8782 21.321 23.5385 21.6608 23.1199 21.6608ZM23.1199 18.8802H18.8032C18.3846 18.8802 18.0449 18.5405 18.0449 18.1219C18.0449 17.7033 18.3846 17.3635 18.8032 17.3635H23.1199C23.5385 17.3635 23.8782 17.7033 23.8782 18.1219C23.8782 18.5405 23.5385 18.8802 23.1199 18.8802ZM23.1199 15.8469H18.8032C18.3846 15.8469 18.0449 15.5071 18.0449 15.0885C18.0449 14.6699 18.3846 14.3302 18.8032 14.3302H23.1199C23.5385 14.3302 23.8782 14.6699 23.8782 15.0885C23.8782 15.5071 23.5385 15.8469 23.1199 15.8469ZM23.1199 13.0663H18.8032C18.3846 13.0663 18.0449 12.7266 18.0449 12.308C18.0449 11.8894 18.3846 11.5497 18.8032 11.5497H23.1199C23.5385 11.5497 23.8782 11.8894 23.8782 12.308C23.8782 12.7266 23.5385 13.0663 23.1199 13.0663Z" />
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="28" height="28" fill="white"
                                              transform="translate(0.000488281 0.660767)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <div
                                className="tw-pt-1 tw-text-sm dark:tw-text-light group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200">
                                Portfolio
                            </div>
                        </NavLink>

                        {/* Stocks -->*/}
                        <NavLink to="/stocks" exact activeClassName="tw-font-extrabold"
                                 className="tw-group tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center
                                            focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                            tw-text-primary-purple active:tw-text-primary-lighter">
                            <span className="sr-only">Stocks</span>
                            <svg
                                className="group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200"
                                width="26" height="27" viewBox="0 0 26 27" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M22.7383 26.6354H3.25733C2.36107 26.6354 1.59586 26.3183 0.961717 25.6842C0.327571 25.05 0.010498 24.2848 0.010498 23.3886V3.9076C0.010498 3.01134 0.327571 2.24614 0.961717 1.61199C1.59586 0.977847 2.36107 0.660774 3.25733 0.660774H22.7383C23.6346 0.660774 24.3998 0.977847 25.0339 1.61199C25.6681 2.24614 25.9851 3.01134 25.9851 3.9076V23.3886C25.9851 24.2848 25.6681 25.05 25.0339 25.6842C24.3998 26.3183 23.6346 26.6354 22.7383 26.6354ZM22.7383 7.15443C22.7383 6.25817 22.4212 5.49297 21.7871 4.85882C21.1529 4.22468 20.3877 3.9076 19.4915 3.9076H6.50416C5.6079 3.9076 4.84269 4.22468 4.20855 4.85882C3.5744 5.49297 3.25733 6.25817 3.25733 7.15443V20.1418C3.25733 21.038 3.5744 21.8032 4.20855 22.4374C4.84269 23.0715 5.6079 23.3886 6.50416 23.3886H19.4915C20.3877 23.3886 21.1529 23.0715 21.7871 22.4374C22.4212 21.8032 22.7383 21.038 22.7383 20.1418V7.15443ZM19.9227 15.2208L18.4007 13.6988L13.632 18.4676C13.5305 18.6536 13.4375 18.7889 13.3529 18.8735C13.0485 19.1778 12.6807 19.33 12.2495 19.33C11.8183 19.33 11.4505 19.1778 11.1461 18.8735L9.75098 17.4783L7.51879 19.6852C7.2144 19.9896 6.84659 20.1418 6.41538 20.1418C5.98416 20.1418 5.61635 19.9896 5.31196 19.6852C5.00757 19.3808 4.85538 19.013 4.85538 18.5817C4.85538 18.1505 5.00757 17.7827 5.31196 17.4783L8.40659 14.3837C8.52497 14.2146 8.58416 14.1216 8.58416 14.1047C8.88855 13.8003 9.25635 13.6481 9.68757 13.6481C10.1188 13.6481 10.4866 13.8003 10.791 14.1047L12.1607 15.4998L16.1685 11.492L14.6212 9.94468V9.79248C14.6212 9.55573 14.6255 9.37817 14.6339 9.2598C14.6424 9.14142 14.6931 9.03151 14.7861 8.93004C14.8791 8.82858 15.0186 8.77785 15.2046 8.77785H19.9227C20.2609 8.77785 20.5442 8.89199 20.7724 9.12029C21.0007 9.34858 21.1149 9.62338 21.1149 9.94468V14.6374C21.1149 14.8234 21.0642 14.9629 20.9627 15.0559C20.8612 15.1489 20.7513 15.1996 20.6329 15.2081C20.5146 15.2165 20.337 15.2208 20.1003 15.2208H19.9227Z" />
                            </svg>

                            <div
                                className="tw-pt-1 tw-text-sm dark:tw-text-light group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200">
                                Stocks
                            </div>
                        </NavLink>

                        <NavLink to="/token" exact activeClassName="tw-font-extrabold"
                                 className="tw-group tw-p-2 tw-flex tw-flex-col tw-items-center tw-justify-center
                                           focus:tw-outline-none focus:tw-ring-primary-darker focus:tw-text-primary-lighter
                                           tw-text-primary-purple active:tw-text-primary-lighter">
                            <span className="sr-only">Token</span>
                            {/*<img src="/icons/Group-2.svg" className="tw-w-7 tw-h-7 st-icon group-hover:tw-text-primary-lighter"/>*/}
                            <svg
                                className="group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200"
                                width="24" height="25" viewBox="0 0 24 25" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M3.87877 -1.63984H11.291L7.61623 4.72462H0.204163L3.87877 -1.63984Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M15.8758 4.77937L12.1697 -1.63984L8.49518 4.72484L12.2012 11.1439L15.8758 4.77937Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M20.4923 -1.64006H13.0802L16.7548 4.72462H24.1669L20.4923 -1.64006Z"
                                      fill="currentColor" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.6611 11.9577L7.95506 5.53854L4.28046 11.9032L7.98647 18.3224L11.6611 11.9577Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M15.8758 19.2581L12.1697 12.8389L8.49518 19.2036L12.2012 25.6226L15.8758 19.2581Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M20.0424 11.9577L16.3366 5.53918L12.6618 11.9037L16.3679 18.3224L20.0424 11.9577Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M22.4113 -3.28895H1.67801L-2.54248 4.02132L13.0647 32.2236L-5.75958 4.0268L0.0781116 -6.08459L22.4113 -3.28895Z" />
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M12.3272 29.7316L27.0799 4.02154L22.4526 -3.99334L30.2967 4.02662L12.3272 29.7316Z" />
                            </svg>

                            <div
                                className="tw-pt-1 tw-text-sm dark:tw-text-light group-hover:tw-text-primary-blueText dark:group-hover:tw-text-primary-lighter tw-transition-colors tw-duration-200">
                                Token
                            </div>
                        </NavLink>
                    </div>
                    {/*Sidebar footer -->*/}

                    <div className="tw-flex tw-items-center tw-justify-center tw-flex-shrink-0">
                        {/* Dark mode button -->*/}
                        <span className="tw-sr-only">Toggle Dark Mode</span>
                        <DarkToggle btnRef={darkModeBtnRef} />
                    </div>
                </nav>
            </aside>

            <SlidePanel show={drawerIsOpen} close={backdropClickHandler}>
                {username ?
                    <Notifications username={username} />
                    : ""
                }
            </SlidePanel>
        </>
    );
}