export const CurrencyOptions = [
    {
        label: "USD", value: "USD"
    },
    {
        label: "EUR", value: "EUR"
    },
    {
        label: "GBP", value: "GBP"
    },
    {
        label: "CAD", value: "CAD"
    },
    {
        label: "CHF", value: "CHF"
    },
    {
        label: "JPY", value: "JPY"
    },
    {
        label: "ARS", value: "ARS"
    },
    {
        label: "AUD", value: "AUD"
    },
    {
        label: "BGN", value: "BGN"
    },
    {
        label: "BHD", value: "BHD"
    },
    {
        label: "BRL", value: "BRL"
    },
    {
        label: "BWP", value: "BWP"
    },
    {
        label: "CDF", value: "CDF"
    },
    {
        label: "CLP", value: "CLP"
    },
    {
        label: "CNY", value: "CNY"
    },
    {
        label: "CZK", value: "CZK"
    },
    {
        label: "DKK", value: "DKK"
    },
    {
        label: "DZD", value: "DZD"
    },
    {
        label: "EGP", value: "EGP"
    },
    {
        label: "FJD", value: "FJD"
    },
    {
        label: "GHS", value: "GHS"
    },
    {
        label: "HKD", value: "HKD"
    },
    {
        label: "HRK", value: "HRK"
    },
    {
        label: "HUF", value: "HUF"
    },
    {
        label: "IDR", value: "IDR"
    },
    {
        label: "INR", value: "INR"
    },
    {
        label: "JOD", value: "JOD"
    },
    {
        label: "KES", value: "KES"
    },
    {
        label: "KRW", value: "KRW"
    },
    {
        label: "KWD", value: "KWD"
    },
    {
        label: "LBP", value: "LBP"
    },
    {
        label: "LKR", value: "LKR"
    },
    {
        label: "LRD", value: "LRD"
    },
    {
        label: "MAD", value: "MAD"
    },
    {
        label: "MDL", value: "MDL"
    },
    {
        label: "MKD", value: "MKD"
    },
    {
        label: "MUR", value: "MUR"
    },
    {
        label: "MVR", value: "MVR"
    },
    {
        label: "MXN", value: "MXN"
    },
    {
        label: "MYR", value: "MYR"
    },
    {
        label: "NZD", value: "NZD"
    },
    {
        label: "NOK", value: "NOK"
    },
    {
        label: "OMR", value: "OMR"
    },
    {
        label: "PHP", value: "PHP"
    },
    {
        label: "PLN", value: "PLN"
    },
    {
        label: "QAR", value: "QAR"
    },
    {
        label: "RON", value: "RON"
    },
    {
        label: "RSD", value: "RSD"
    },
    {
        label: "RUB", value: "RUB"
    },
    {
        label: "SAR", value: "SAR"
    },
    {
        label: "SCR", value: "SCR"
    },
    {
        label: "SEK", value: "SEK"
    },
    {
        label: "SGD", value: "SGD"
    },
    {
        label: "SLL", value: "SLL"
    },
    {
        label: "SZL", value: "SZL"
    },
    {
        label: "THB", value: "THB"
    },
    {
        label: "TND", value: "TND"
    },
    {
        label: "TRY", value: "TRY"
    }
];
