import React, {useState} from "react";
import {TopRankingItem} from "./TopRankingItem";

export const TopRanking = ({topRankingList}) => {
    {
        const [items, setItems] = useState(topRankingList);

        const renderItem = (item, index) => {
            return (<TopRankingItem key={item.username + "-" + index} index={index}
                                    username={item.username}
                                    change={item.change}
            />);
        };
        return (<>
            <div className="tw-rounded">
                {items.map((it, i) => renderItem(it, i))}
            </div>
        </>);

    }
};
