import React from "react";

class Panel extends React.Component {
    render () {
        const titles = this.props.titles;
        const cols = [];

        titles.map((item, index) => {
            cols.push(
                <div key={`${item.label}_${item.text}`}>
                    <span className="tw-font-semibold tw-text-base tw-text-gray-500 dark:tw-text-primary-light">{item.label}</span><br /><span className="tw-text-base dark:tw-text-light">{item.text && item.text !== "null" ? item.text : "-"}</span>
                </div>
            );
        });

        return (
            <div className="tw-container tw-mx-auto">
                <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-4 tw-gap-4">
                    {cols}
                </div>
            </div>
        );
    }
}

export default Panel;
