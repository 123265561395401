import React, {useEffect, useState} from "react";
import {FiAward, FiInfo} from "react-icons/fi";
import {TopRanking} from "../components/TopRanking/TopRanking";
import PortfolioService from "../services/PortfolioService";
import {RankingTypes} from "../components/TopRanking/RankingTypes";
import Alert from "react-bootstrap/Alert";
import Loader from "../components/ui/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {PersonalRankingItem} from "../components/TopRanking/PersonalRankingItem";
import {useAppContext} from "../libs/contextLib";
import {useLocation} from "react-router-dom";
import {Friends} from "../components/Friends/Friends";
import {Subscribers} from "../components/Friends/Subscribers";
import {ReactComponent as AwardIcon} from '../images/award.svg';

export default function League() {
    const [isLoading, setIsLoading] = useState(true);
    const [top10Daily, setTop10Daily] = useState([]);
    const [top10Weekly, setTop10Weekly] = useState([]);
    const [top10Monthly, setTop10Monthly] = useState([]);
    const [personalDaily, setPersonalDaily] = useState(null);
    const [personalWeekly, setPersonalWeekly] = useState(null);
    const [personalMonthly, setPersonalMonthly] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const { isAuthenticated, authenticatedUser } = useAppContext();
    const {pathname} = useLocation();

    async function retrieveTopRanking(type) {
        const list = await PortfolioService.getTop10(type)
            .then(r => {
                    console.log(r);
                    return r;
                }
            )
            .catch(e => {
                if (e.response != null && e.response.status !== 404) {
                    console.log(e);
                    setErrorMessage("Error getting ranking.");

                }
                return [];
            });
        return list;
    }

    async function retrieveUsernameRanking(type){
        console.log("log app context");
        console.log(isAuthenticated);
        console.log(authenticatedUser);
        if (isAuthenticated && authenticatedUser) {
            const list = await PortfolioService.getUsernameRanking(type, authenticatedUser.username)
                .then(r => {
                        console.log(r);
                        return r;
                    }
                )
                .catch(e => {
                    if (e.response != null && e.response.status !== 404) {
                        console.log(e);
                        setErrorMessage("Error getting username ranking.");
                    }
                    return [];
                });
            return list;
        }
        return null;
    }

    useEffect(() => {
        async function fetchData() {
            const daily = await retrieveTopRanking(RankingTypes.DAILY);
            console.log("daily");
            console.log(daily);
            setTop10Daily(daily);
            setTop10Weekly(await retrieveTopRanking(RankingTypes.WEEKLY));
            console.log("weekly");
            setTop10Monthly(await retrieveTopRanking(RankingTypes.MONTHLY));
            console.log("monthly");
            setPersonalDaily(await retrieveUsernameRanking(RankingTypes.DAILY));
            setPersonalWeekly(await retrieveUsernameRanking(RankingTypes.WEEKLY));
            setPersonalMonthly(await retrieveUsernameRanking(RankingTypes.MONTHLY));
            setIsLoading(false);

        }
console.log(pathname);
        fetchData();


    }, []);

    const renderTooltipDaily = (props) => (
        <Tooltip id={props.id}
                 {...props}>
            The daily ranking is calculated from the change of a portfolio at the market's close value
        </Tooltip>
    );

    const renderTooltipWeekly = (props) => (
        <Tooltip id={props.id}
                 {...props}>
            The weekly ranking is calculated from the average of the last 5 daily change of a portfolio at the market's close value
        </Tooltip>
    );

    const renderTooltipMonthly = (props) => (
        <Tooltip id={props.id}
                 {...props}>
            The monthly ranking is calculated from the average of the last 4 weekly change of a portfolio at the market's close value
        </Tooltip>
    );

    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-pb-4">
                <span className="tw-pb-2 sm:tw-pb-0 tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">The League</span>
            </div>

            {errorMessage !== "" && <Alert message={errorMessage} color="red" />}

            {isLoading ? <div className="tw-text-center tw-py-20"><Loader /></div> :
                <>
                    {isAuthenticated && authenticatedUser ? <>
                        {/* User ranking */}
                        <div
                            className="tw-grid tw-grid-cols-1 tw-p-4 tw-pt-8 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">
                            {/* Daily */}
                            {personalDaily &&
                            <PersonalRankingItem key={`p-daily-ranking-${personalDaily.ranking}`}
                                                 type="Daily" ranking={personalDaily.ranking}
                                                 change={personalDaily.change} />}
                            { /* Weekly */}
                            {personalWeekly &&
                            <PersonalRankingItem key={`p-weekly-ranking-${personalWeekly.ranking}`}
                                                 type="Weekly" ranking={personalWeekly.ranking}
                                                 change={personalWeekly.change} />}
                            { /* Monthly */}
                            {personalMonthly &&
                            <PersonalRankingItem key={`p-monthly-ranking-${personalMonthly.ranking}`}
                                                 type="Monthly" ranking={personalMonthly.ranking}
                                                 change={personalMonthly.change} />}
                        </div>
                    </> : ''}
                    { /* Top Ranking */}
                    <div
                        className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">

                        <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                            {/*Daily*/}
                            <div
                                className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <span>
                                        Top 10 Daily
                                    </span>
                                    <OverlayTrigger
                                        id="daily-tooltip-id"
                                        placement="right"
                                        delay={{show: 250, hide: 350}}
                                        overlay={renderTooltipDaily}
                                    >
                                        <div>
                                            <FiInfo className="tw-ml-2 tw-text-xl" />
                                        </div>
                                    </OverlayTrigger>
                                </div>

                                <div className="tw-flex tw-items-center tw-space-x-2">
                                    <span className="tw-text-xl tw-text-primary-yellowDark dark:tw-text-primary-yellow">
                                        <AwardIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="tw-relative tw-p-4 tw-h-72">
                                {top10Daily && top10Daily.length > 0 &&
                                <TopRanking key={`top-ten-daily-id`} topRankingList={top10Daily} />}

                                {(!top10Daily || top10Daily.length === 0) &&
                                <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Not enough data yet</div>}
                            </div>
                        </div>
                        { /* Weekly */}
                        <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                            <div
                                className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <span>
                                        Top 10 Weekly
                                    </span>
                                    <OverlayTrigger
                                        id="weekly-tooltip-id"
                                        placement="right"
                                        delay={{show: 250, hide: 350}}
                                        overlay={renderTooltipWeekly}
                                    >
                                        <div className="tw-ml-2 tw-text-xl">
                                            <FiInfo />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="tw-flex tw-items-center tw-space-x-2">
                                    <span className="tw-text-xl tw-text-primary-yellowDark dark:tw-text-primary-yellow">
                                        <AwardIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="tw-relative tw-p-4 tw-h-72">
                                {top10Weekly && top10Weekly.length > 0 &&
                                <TopRanking key={`top-ten-weekly-id`} topRankingList={top10Weekly} />}

                                {(!top10Weekly || top10Weekly.length === 0) &&
                                <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Not enough data yet</div>}
                            </div>
                        </div>
                        { /* Top 10 Monthly */}
                        <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                            <div
                                className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                                <div className="tw-flex tw-items-center tw-justify-between">
                                    <span>
                                        Top 10 Monthly
                                    </span>
                                    <OverlayTrigger
                                        id="monthly-tooltip-id"
                                        placement="right"
                                        delay={{show: 250, hide: 350}}
                                        overlay={renderTooltipMonthly}
                                    >
                                        <div className="tw-ml-2 tw-text-xl">
                                            <FiInfo className="tw-ml-2" />
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="tw-flex tw-items-center tw-space-x-2">
                                    <span className="tw-text-xl tw-text-primary-yellowDark dark:tw-text-primary-yellow">
                                        <AwardIcon />
                                    </span>
                                </div>
                            </div>
                            <div className="tw-relative tw-p-4 tw-h-72">
                                {top10Monthly && top10Monthly.length > 0 &&
                                <TopRanking key={`top-ten-monthly-id`} topRankingList={top10Monthly} />}

                                {(!top10Monthly || top10Monthly.length === 0) &&
                                <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Not enough data yet</div>
                                }
                            </div>
                        </div>
                    </div>
                    {isAuthenticated && authenticatedUser &&
                        <>
                            <div
                                className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">
                                <Friends />
                            </div>
                            <div
                                className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">
                                <Subscribers />
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
}