import React, {useEffect, useState} from "react";

export default function Alert(props) {
    const [showAlert, setShowAlert] = useState(true);

    useEffect(() => {
        setShowAlert(true)

        // Hides the message after 2 default seconds (configurable)
        // setTimeout(() => setShowAlert(false), 2000)
    }, [])

    return (
        <>
            {showAlert ? (
                <div className="tw-p-4">
                    <div
                        className={
                            "tw-text-white tw-px-6 tw-py-4 tw-border-0 tw-rounded tw-relative tw-mb-4 tw-bg-" +
                            props.color + "-400"
                        }
                    >
                        <div className="tw-inline-block tw-align-middle tw-mr-8">
                            {props.message}
                        </div>
                        {/*<button*/}
                        {/*    className="tw-absolute tw-bg-transparent tw-text-2xl tw-font-semibold tw-leading-none tw-right-0 tw-top-0 tw-mt-4 tw-mr-6 tw-outline-none focus:tw-outline-none"*/}
                        {/*    onClick={() => setShowAlert(false)}*/}
                        {/*>*/}
                        {/*    <div>x</div>*/}
                        {/*</button>*/}
                    </div>
                </div>
            ) : null}
        </>
    );
};