import React from 'react';
import '../../header.css';

import withUseAppContext from "../contextComponent";
import NavbarAuthenticated from "./NavbarAuthenticated";
import NavbarUnauthenticated from "./NavbarUnauthenticated";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {/*<div>*/}
                {/*    <AuthStateApp/>*/}
                {/*</div>*/}

                {this.props.isAuthenticated ?

                    <>
                        <NavbarAuthenticated userHasAuthenticated={this.props.userHasAuthenticated}
                                             history={this.props.history} />
                    </>
                    :
                    <>
                        <NavbarUnauthenticated />
                    </>
                }

                {/*</AmplifyAuthenticator>*/}

            </>
        );
    }
}

export default withUseAppContext(Header);