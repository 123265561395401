import React from 'react';
import {Auth} from 'aws-amplify';
import PortfolioService from '../../services/PortfolioService'
import Alert from "react-bootstrap/Alert";
import ImportPortfolio from "./ImportPortfolio";

import Loader from "../ui/Loader";
import {PortfolioTable} from "./PortfolioTable";
import {NavLink} from "react-router-dom";
import {getUsername} from "../../libs/getUsername";


class ShowPortfolio extends React.Component {

    constructor(props) {
        super(props);
        this.getPortfolio = this.getPortfolio.bind(this);
        this.getUsername = this.getUsername.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.toggleShowImportPortfolio = this.toggleShowImportPortfolio.bind(this);

        this.state = {
            username: null,
            errorMessage: null,
            dataRows: null,
            isLoading: true,
            selectedRows: new Set(),
            showImport: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.getPortfolio();
    }

    getUsername = async () => {
        const username = await getUsername();
        if (username === null) {
            this.setState({
                errorMessage: "Error retrieving username. Try again!"
            });
        }else {
            this.setState({
                username: username
            });
        }
    }

    getPortfolio = async () => {
        await this.getUsername();
        if (this.state.username) {
            let username = this.state.username;
            console.log("username=" + username);
            await PortfolioService.getPortfolio(username)
                .then(r => {
                        console.log("r=" + r[0].id);
                        this.setState({
                            dataRows: r,
                            showImport: false
                        })

                    }
                )
                .catch(e => {
                    if (e.response != null && e.response.status !== 404) {
                        this.setState({
                            errorMessage: "Error getting portfolio. refresh page!"
                        });
                    } else {
                        this.setState({
                            showImport: true
                        })
                    }
                    console.log(e);
                });
        }
        this.setState({
            isLoading: false
        });
    }

    setDataRows = (rows) => {
        this.setState({
            dataRows: rows
        });
    }

    toggleShowImportPortfolio() {
        const reimport = this.state.showImport;
        this.setState({
            showImport: !reimport
        });
    }

    displayErrorMessage = (msg) => {
        this.setState({
            errorMessage: msg
        });
    }

    render() {

        return (
            <div className="tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8">

                {this.state.errorMessage ?
                    <div className="tw-mt-2">
                        <Alert variant="danger" onClose={() => this.setState({
                            errorMessage: null
                        })} dismissible>

                                {Array.isArray(this.state.errorMessage)? this.state.errorMessage.map((item, idx) => {
                                    return <p key={`item-err-msg-${idx}`}>{item}</p>;
                                }) : <p>{this.state.errorMessage}</p>}

                        </Alert>
                    </div>
                    : <div></div>
                }

                {this.state.isLoading ?
                    <div className="tw-text-center tw-py-20"><Loader /></div>:
                    <>
                        {this.state.showImport ? <ImportPortfolio retrievePortfolio={this.getPortfolio}
                                                                  displayErrorMessage={this.displayErrorMessage} />
                            : ''
                        }
                        {this.state.username ? <>
                            <div className="tw-text-gray-500 dark:tw-text-light">
                                The portfolio totals and returns are only visible to you, others users are not able to see them.
                            </div>
                            <div className="tw-p-4 tw-rounded-3xl tw-bg-white dark:tw-bg-white-transparent">
                                <PortfolioTable
                                    key={`bportfolio-table-id-${this.state.dataRows ? this.state.dataRows.length : 0}`}
                                    setDataRows={this.setDataRows}
                                    dataRows={this.state.dataRows}
                                    username={this.state.username}
                                    toggleShowImportPortfolio={this.toggleShowImportPortfolio} />
                            </div>
                            </>
                            : <div
                                className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-border-b lg:tw-py-6 dark:tw-border-primary-darker">

                                <span className="tw-text-xl tw-font-semibold">
                                    Welcome to Trades Shares, before you can use the application, please create a username in the
                                    <NavLink to="/settings" className="tw-transition-colors tw-duration-200 tw-text-primary-lighter
                                    hover:tw-text-primary dark:hover:tw-text-light"> settings page</NavLink>.
                                </span>
                            </div>}
                    </>
                }
            </div>
        );
    }
}

export default ShowPortfolio;