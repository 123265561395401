import React from "react";

export default function NewsListItem({ news: item }) {

    const itemDate = Date(item.dateTime).toString().split(" ");


    return (
        <a href={item.url} target="_blank">
        <article className="tw-py-2 tw-flex tw-space-x-4 tw-no-underline
                            hover:tw-bg-primary-lighter dark:tw-text-light dark:hover:tw-bg-primary-light hover:tw-no-underline">

            {item.image &&
            <img src={item.image} alt=""
                 className="tw-flex-none tw-rounded-full tw-w-16 tw-h-16 tw-object-cover tw-bg-gray-100" />
            }
            <div className="tw-min-w-0 tw-relative tw-flex-auto">
                <h2 className="tw-text-lg tw-text-left tw-font-semibold tw-text-gray-500 tw-mb-0.5 tw-no-underline hover:tw-no-underline
                               dark:tw-text-light">
                    {item.headline}
                </h2>
                <dl className="tw-flex tw-flex-wrap tw-text-sm tw-whitespace-pre tw-text-primary-purple tw-no-underline hover:tw-no-underline">
                    <div>
                        <dt className="tw-sr-only">Date</dt>
                        <dd>
                            <abbr title={`${itemDate[1]}/${itemDate[2]}/${itemDate[3]}`}>{`${itemDate[1]} ${itemDate[2]} ${itemDate[3]}`}</abbr>
                        </dd>
                    </div>
                    <div>
                        <dt className="tw-sr-only">Source</dt>
                        <dd> · {item.source}</dd>
                    </div>
                    {/*<div>*/}
                    {/*    <dt className="sr-only">Servings</dt>*/}
                    {/*    <dd> · {item.servings} servings</dd>*/}
                    {/*</div>*/}
                    {/*<div className="tw-flex-none tw-w-full tw-mt-0.5 tw-font-normal tw-text-left">*/}
                    {/*    <dt className="tw-inline">Url:</dt>{' '}*/}
                    {/*    <dd className="tw-inline tw-text-black">{item.url}</dd>*/}
                    {/*</div>*/}
                    {/*<div className="tw-absolute tw-top-0 tw-right-0 tw-rounded-full tw-bg-yellow-200 tw-text-yellow-600 tw-px-2 tw-py-0.5 tw-hidden sm:tw-flex lg:tw-hidden xl:tw-flex tw-items-center tw-space-x-1">*/}
                    {/*    <dt className="tw-text-amber-500">*/}
                    {/*        <span className="tw-sr-only">Rating</span>*/}
                    {/*        <svg width="16" height="20" fill="currentColor">*/}
                    {/*            <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />*/}
                    {/*        </svg>*/}
                    {/*    </dt>*/}
                    {/*    <dd>{item.lang}</dd>*/}
                    {/*</div>*/}
                </dl>
            </div>

        </article>
        </a>
    )
}