import { useContext, createContext } from "react";

export const AppContext = createContext(
    {
        isAuthenticated: false,
        userHasAuthenticated: (auth) => {},
        authenticatedUser: null,
        setAuthenticatedUser: (auth) => {}
    }
);

export function useAppContext() {
    return useContext(AppContext);
}