import React from "react";
import {FiBell} from "react-icons/fi";


export default function NotificationItem({ notification: item }) {

    return (
        <a href="#" className="tw-block">
            <div className="tw-flex tw-px-4 tw-space-x-4">
                <div className="tw-relative tw-flex-shrink-0">
                              <span
                                  className="tw-z-10 tw-inline-block tw-p-2 tw-overflow-visible tw-rounded-full tw-bg-primary-50 tw-text-primary-light dark:tw-bg-primary-darker"
                              >
                                  <FiBell className="tw-w-7 tw-h-7" fill="none" />
                              </span>
                    <div
                        className="tw-absolute tw-h-24 tw-p-px tw--mt-3 tw--ml-px tw-bg-primary-50 tw-left-1/2 dark:tw-bg-primary-darker"></div>
                </div>
                <div className="tw-flex-1 tw-overflow-hidden">
                    <h5 className="tw-mb-0 tw-text-sm tw-font-semibold tw-text-gray-600 dark:tw-text-light">
                        {item.title}
                    </h5>
                    <p className="tw-mb-0 tw-text-sm tw-font-normal tw-text-gray-400 dark:tw-text-primary-lighter">
                        {item.description}
                    </p>
                    <span className="tw-text-sm tw-font-normal tw-text-gray-400 dark:tw-text-primary-light">
                        {new Date(item.createdDate).toLocaleString()}
                    </span>
                </div>
            </div>
        </a>
    )
}
