import React from 'react';
import ShowPortfolio from "../components/Portfolio/ShowPortfolio";
import withUseAppContext from "../components/contextComponent";


class Portfolio extends React.Component {
    render() {
        return (
             <>
                <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                                tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-pb-4 lg:tw-pb-8">
                    <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Portfolio</span>
                </div>

                 <ShowPortfolio />

            </>
        );
    }
}

export default withUseAppContext(Portfolio);