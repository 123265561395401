import React from "react";
import {Price} from "../Price";
import {NavLink} from "react-router-dom";

const style = {
    // border: '1px dashed gray',
    padding: '0.25rem 0rem',
    // marginBottom: '.5rem',
    // backgroundColor: 'white',
    // width: '20rem',
};
const handleStyle = {
    // backgroundColor: 'green',
    width: '1rem',
    height: '1rem',
    display: 'inline-block',
    marginRight: '0.75rem',
    cursor: 'move',
};
export const TopFiveItem = ({
                                symbol,
                                text,
                                currentPrice,
                                changePercent,
                                changePrice
                            }) => {

    return (
        <div style={{...style}}>
            <NavLink to={`/ticker/${symbol}`}>
                <div className="tw-grid tw-grid-cols-8 tw-gap-0 tw-text-sm tw-text-gray-500
                                hover:tw-bg-primary-lighter dark:tw-text-light dark:hover:tw-bg-primary-light">
                    <div className="tw-flex-none tw-col-span-4">
                        <div className="tw-font-semibold">{symbol}</div>
                        <div className="tw-text-xs tw-text-gray-400 dark:tw-text-primary-purple tw-truncate">{text}</div>
                    </div>
                    <div className="tw-col-span-4 tw-text-right">
                        <div className="tw-font-semibold"><Price showSymbol={false}>{currentPrice}</Price></div>
                        <div className="tw-text-xs"><Price showSymbol={false}
                                                           className={`tw-text-green-500 dark:tw-text-primary-green`}>{changePrice}</Price> <Price
                            numberStyle={`percent`} showSymbol={false} className={`tw-text-green-500 dark:tw-text-primary-green`}
                            includeBrackets={true}>{changePercent}</Price></div>
                    </div>
                </div>
            </NavLink>
        </div>);
};
