import React, {Suspense} from "react";
import {Price} from "../Price";
import {NavLink} from "react-router-dom";
import {FiLoader} from "react-icons/fi";
const Avatar = React.lazy(() => import('../ui/Avatar'));

const style = {
    // border: '1px dashed gray',
    padding: '0.25rem 0rem',
    // marginBottom: '.5rem',
    // backgroundColor: 'white',
    // width: '20rem',
};

export const TopRankingItem = ({
                                   index,
                                   username,
                                   change
                               }) => {

    return (
        <div style={{...style}}>
            <NavLink to={`/portfolio/public?followed=${username}&from=sub`}>
                <div
                    className="tw-grid tw-grid-cols-8 tw-gap-0 tw-text-sm tw-text-gray-500 tw-items-center hover:tw-bg-blue-200 dark:tw-text-light dark:hover:tw-bg-primary-dark">
                    <div className="tw-flex-none tw-col-span-4">
                        <div className="tw-justify-start tw-flex tw-flex-wrap tw-items-center tw-space-x-2">
                            <span className="tw-font-semibold">{index + 1}. </span>
                            <Suspense fallback={<FiLoader className="tw-animate-spin tw-h-6 tw-w-6 tw-text-yellow-500" />}>
                                <Avatar key={`avatar-friend-${username}`} username={username} className={`tw-w-8 tw-h-8`} />
                            </Suspense>
                            <span className="tw-font-semibold"> {username}</span>
                        </div>
                    </div>
                    <div className="tw-col-span-4 tw-text-right">
                        <div className="">
                            <Price numberStyle={`percent`} showSymbol={false} className={`tw-text-green-500 dark:tw-text-primary-green`}
                                   includeBrackets={false}>
                                {change}
                            </Price>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>);
};
