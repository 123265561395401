export const SupportedExchanges = [
    {label: "Athens Exchange", exchangeId: "AT"},
    {label: "Australia Exchange", exchangeId: "AU"},
    {label: "Berlin Exchange", exchangeId: "BE"},
    {label: "Bolsa de Valores de Lima", exchangeId: "LIM"},
    {label: "Bombay Exchange", exchangeId: "BSE"},
    {label: "Bond Virtual Exchange", exchangeId: "BOND"},
    {label: "Borsa Italiana", exchangeId: "MI"},
    {label: "Borsa Italiana Certificates", exchangeId: "ETLX"},
    {label: "Budapest Stock Exchange", exchangeId: "BUD"},
    {label: "Buenos Aires Exchange", exchangeId: "BA"},
    {label: "Canadian Securities Exchange", exchangeId: "CN"},
    {label: "Chilean Stock Exchange", exchangeId: "SN"},
    {label: "Commodities", exchangeId: "COMM"},
    {label: "Copenhagen Exchange", exchangeId: "CO"},
    {label: "Cryptocurrencies", exchangeId: "CC"},
    {label: "Dusseldorf Exchange", exchangeId: "DU"},
    {label: "Euronext Amsterdam", exchangeId: "AS"},
    {label: "Euronext Brussels", exchangeId: "BR"},
    {label: "Euronext Lisbon", exchangeId: "LS"},
    {label: "Euronext Paris", exchangeId: "PA"},
    {label: "Europe Fund Virtual Exchange", exchangeId: "EUFUND"},
    {label: "First North Denmark", exchangeId: "NFN"},
    {label: "FOREX", exchangeId: "FOREX"},
    {label: "Frankfurt Exchange", exchangeId: "F"},
    {label: "Government Bonds", exchangeId: "GBOND"},
    {label: "Hamburg Exchange", exchangeId: "HM"},
    {label: "Hanover Exchange", exchangeId: "HA"},
    {label: "Helsinki Exchange", exchangeId: "HE"},
    {label: "Hong Kong Exchange", exchangeId: "HK"},
    {label: "Iceland Exchange", exchangeId: "IC"},
    {label: "Indices", exchangeId: "INDX"},
    {label: "Irish Exchange", exchangeId: "IR"},
    {label: "Istanbul Stock Exchange", exchangeId: "IS"},
    {label: "Jakarta Exchange", exchangeId: "JK"},
    {label: "Johannesburg Exchange", exchangeId: "JSE"},
    {label: "Karachi Stock Exchange", exchangeId: "KAR"},
    {label: "Korea Stock Exchange", exchangeId: "KO"},
    {label: "KOSDAQ", exchangeId: "KQ"},
    {label: "Kuala Lumpur Exchange", exchangeId: "KLSE"},
    {label: "London Exchange", exchangeId: "LSE"},
    {label: "London IL", exchangeId: "IL"},
    {label: "Luxembourg Stock Exchange", exchangeId: "LU"},
    {label: "Madrid Exchange", exchangeId: "MC"},
    {label: "Mexican Exchange", exchangeId: "MX"},
    {label: "MICEX Moscow Russia", exchangeId: "MCX"},
    {label: "Money Market Virtual Exchange", exchangeId: "MONEY"},
    {label: "Munich Exchange", exchangeId: "MU"},
    {label: "Nasdaq Baltic", exchangeId: "NB"},
    {label: "NEO Exchange", exchangeId: "NEO"},
    {label: "NSE (India)", exchangeId: "NSE"},
    {label: "Oslo Stock Exchange", exchangeId: "OL"},
    {label: "Philippine Stock Exchange", exchangeId: "PSE"},
    {label: "Riga Exchange", exchangeId: "RG"},
    {label: "Sao Paolo Exchange", exchangeId: "SA"},
    {label: "Saudi Arabia Exchange", exchangeId: "SR"},
    {label: "Shanghai Exchange", exchangeId: "SHG"},
    {label: "Shenzhen Exchange", exchangeId: "SHE"},
    {label: "Singapore Exchange", exchangeId: "SG"},
    {label: "SIX Swiss Exchange", exchangeId: "SW"},
    {label: "Stockholm Exchange", exchangeId: "ST"},
    {label: "Stuttgart Exchange", exchangeId: "STU"},
    {label: "Swiss Exchange", exchangeId: "VX"},
    {label: "Taiwan Exchange", exchangeId: "TW"},
    {label: "Taiwan OTC Exchange", exchangeId: "TWO"},
    {label: "Tallinn Exchange", exchangeId: "TL"},
    {label: "Tel Aviv Exchange", exchangeId: "TA"},
    {label: "Thailand Exchange", exchangeId: "BK"},
    {label: "Toronto Exchange", exchangeId: "TO"},
    {label: "TSX Venture Exchange", exchangeId: "V"},
    {label: "USA Stock (Nasdaq and NYSE)", exchangeId: "US"},
    {label: "Vienna Exchange", exchangeId: "VI"},
    {label: "Vietnam Stocks", exchangeId: "VN"},
    {label: "Vilnus Exchange", exchangeId: "VS"},
    {label: "Warsaw Stock Exchange", exchangeId: "WAR"},
    {label: "XETRA Exchange", exchangeId: "XETRA"},
    {label: "Zagreb Stock Exchange", exchangeId: "ZSE"}
];