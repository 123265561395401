import React, {useEffect, useRef, useState} from "react";
import {FiUser} from "react-icons/fi";
import useIsMountedRef from "../../libs/useIsMountedRef";


const Avatar = (props) => {

    const [displayS3Image, setDisplayS3Image] = useState(false);
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        async function fetchImage() {

            const img = await fetch(`${process.env.REACT_APP_AVATAR_URL}/${props.username}/avatar-${props.username}.png`);
            // console.log("avatar", img);
            if (isMountedRef.current) {
                if (img && img.status === 403) {
                    setDisplayS3Image(false);
                } else {
                    setDisplayS3Image(true);
                }
            }
        }

        fetchImage();
    }, [props.username, isMountedRef]);

    return (
        <>
            {displayS3Image ?
                <img className={`tw-rounded-full ${props.className}`}
                     src={`${process.env.REACT_APP_AVATAR_URL}/${props.username}/avatar-${props.username}.png`} />
                :
                <div
                    className={`${props.className} tw-rounded-full tw-text-primary-lighter tw-bg-primary-50 dark:tw-bg-dark
                        tw-flex tw-items-center tw-justify-center`}>
                    <FiUser className="tw-w-7 tw-h-7 tw-rounded-full tw-text-primary-lighter" />
                </div>}
        </>
    );
};

export default Avatar;