import React, {useState} from 'react';

import Modal from "react-bootstrap/Modal";
import {useFormFields} from "../../libs/hooksLib";
import {FiEdit2, FiPlus, FiPlusCircle} from "react-icons/fi";
import PortfolioService from "../../services/PortfolioService";
import Autosuggest from 'react-autosuggest';
import TickerService from "../../services/TickerService";
import {CurrencyOptions} from "./CurrencyOptions";

const theme = {
    container: {
        // position: 'relative'
    },
    input: "tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md",
    inputFocused: {
        outline: 'none'
    },
    inputOpen: 'tw-rounded-bl-none tw-rounded-br-none tw-ring-transparent',
    suggestionsContainer: {
        display: 'none'
    },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'relative',
        // top: 58,
        width: 'auto',
        border: '1px solid #ced4da',
        backgroundColor: '#fff',
        // fontFamily: 'Helvetica, sans-serif',
        // fontWeight: 300,
        // fontSize: 16,
        // borderBottomLeftRadius: 9999,
        // borderBottomRightRadius: 9999,
        zIndex: 100
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        zIndex: 100
    },
    suggestion: {
        cursor: 'pointer',
        padding: '10px',
        zIndex: 100
    },
    suggestionHighlighted: {
        backgroundColor: '#ddd'
    }
};

function formatToyyyymmdd(date) {
    if (date !== null && date !== undefined) {
        return date.replace(/-/g, '')
    }
    // const y = date.getFullYear();
    // let m = date.getMonth() + 1;
    // let d = date.getDate();
    // const mm = m < 10 ? '0' + m : m;
    // const dd = d < 10 ? '0' + d : d;
    // return '' + y + mm + dd;
}

function formatToDate(dateString) {
    if (dateString === null || dateString === undefined) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return year + "-" + month + "-" + day
    // return new Date(year, month - 1, day);
}

export function TickerModal(props) {
    const [fields, handleFieldChange] = useFormFields({
        id: props.row ? props.row.id : null,
        tickerSymbol: props.row ? props.row.tickerSymbol : "",
        // tradeDate: props.row ? formatToDate(props.row.tradeDate) : "",
        purchasePrice: props.row ? props.row.purchasePrice : 0,
        currency: props.row ? props.row.currency : "",
        quantity: props.row ? props.row.quantity : 0,

    });

    const [tradeDate, setTradeDate] = useState(props.row ? formatToDate(props.row.tradeDate) : "");
    const [showEdit, setShowEdit] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);


    async function handleAdd(event) {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            console.log('Error validation');
            event.preventDefault();
            event.stopPropagation();
        } else {
            console.log(fields);
            let postData = {
                id: props.row ? props.row.id : null,
                username: props.username,
                tickerSymbol: fields.tickerSymbol,
                tradeDate: formatToyyyymmdd(tradeDate),
                purchasePrice: fields.purchasePrice,
                // currency: props.mode !== "edit" ? fields.currency: null,
                currency: fields.currency,
                quantity: fields.quantity
            }
            console.log(postData);
            const newPortfolioTicker = await PortfolioService.create(postData)
                .then(r => {
                    console.log(r);
                    return r;
                })
                .catch(e => {
                    console.log(e);
                    event.stopPropagation();
                });

            console.log("newPortfolioTicker", newPortfolioTicker);
            if (newPortfolioTicker !== undefined) {
                const rowIndex = props.rowIndex ? props.rowIndex : 0;
                props.updateAddedRows(rowIndex, newPortfolioTicker);
                handleCloseEdit();
            }
        }
    }

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    function getSuggestions(value) {
        if (value === null || value === undefined) {
            return [];
        }
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        TickerService.search(value)
            .then(data => {
                setSuggestions(data);
                // return data;
            })
            .catch(error => {
                console.log(error.response);
                setSuggestions([]);
            });

    }

    function getSuggestionValue(suggestion) {
        return `${suggestion.symbol}.${suggestion.exchange}`;
    }

    function renderSuggestion(suggestion) {
        return (
            <div>
                <span className="st-title">{suggestion.symbol}</span> <br/>
                <span className="small-font st-text-grey">{suggestion.securityName} - {suggestion.exchange}</span>
            </div>
        );
    }

    const onChange = (event, { newValue, method }) => {
        handleFieldChange({...fields, tickerSymbol: newValue});
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        getSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: 'Start to type...',
        value: fields.tickerSymbol,
        onChange: onChange
    };


    return (
        <>
            <button onClick={handleShowEdit}>
                {
                    props.mode === "edit" ?
                        <FiEdit2 style={{fontSize: 20}} className="hover:tw-text-green-500 dark:hover:tw-text-primary-green" />

                    :
                        <div className="tw-w-10 tw-h-10 tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light tw-rounded-full
                                        tw-flex tw-items-center tw-justify-center
                                        hover:tw-filter hover:tw-drop-shadow-blue-dark">
                            <FiPlus className="tw-w-6 tw-h-6 tw-text-white" />
                        </div>

                }

            </button>

            <Modal show={showEdit} onHide={handleCloseEdit} animation={false} style={{backgroundColor: "transparent", border: "none"}}>

                <form onSubmit={handleAdd} className="tw-shadow-none">

                    <div
                        className="tw-inline-block tw-align-bottom tw-bg-white dark:tw-bg-dark tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div className="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4 dark:tw-bg-dark">
                            <div className="sm:tw-flex sm:tw-items-start">
                                <div className="tw-mt-3 tw-text-left sm:tw-mt-0 sm:tw-ml-4">
                                    <h3 className="tw-text-lg tw-text-center tw-p-4 tw-leading-6 tw-font-medium tw-text-gray-900 dark:tw-text-primary-dark">
                                        {props.mode === "edit" ? "Edit Ticker" : "Add Ticker"}
                                    </h3>
                                    <div className="tw-mt-2">
                                        <span className="tw-text-sm tw-text-gray-500 dark:tw-text-primary-dark">
                                             <div className="tw-grid tw-grid-cols-2 tw-gap-x-0 tw-gap-y-4 sm:tw-gap-6">

                                                <label className="tw-block tw-text-sm tw-font-medium">
                                                    Symbol
                                                </label>
                                                <div className="">
                                                    <Autosuggest
                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        theme={theme}
                                                        id="tickerSymbol"
                                                    />
                                                </div>

                                                <label className="tw-block tw-text-sm tw-font-medium">
                                                    Trade Date
                                                </label>
                                                <div className="">
                                                    <input required type="date"
                                                           className="tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md"
                                                           id="tradeDate"
                                                           value={tradeDate}
                                                           onChange={(e) => setTradeDate(e.target.value)}
                                                    />
                                                  {/*  <DatePicker*/}
                                                  {/*    required*/}
                                                  {/*    selected={startDate}*/}
                                                  {/*    onChange={date => setStartDate(date)}*/}
                                                  {/*    isClearable*/}
                                                  {/*    dateFormat="dd/MM/yyyy"*/}
                                                  {/*    placeholderText="dd/MM/yyyy"*/}
                                                  {/*    className="tw-z-10 tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md"*/}
                                                  {/*    popperPlacement="top-end"*/}
                                                  {/*    popperModifiers={{*/}
                                                  {/*        offset: {*/}
                                                  {/*            enabled: true,*/}
                                                  {/*            offset: "5px, 10px"*/}
                                                  {/*        },*/}
                                                  {/*        preventOverflow: {*/}
                                                  {/*            enabled: true,*/}
                                                  {/*            escapeWithReference: false,*/}
                                                  {/*            boundariesElement: "viewport"*/}
                                                  {/*        }*/}
                                                  {/*    }}*/}
                                                  {/*    calendarContainer={MyContainer}*/}
                                                  {/*/>*/}

                                                </div>

                                                <label className="tw-block tw-text-sm tw-font-medium">
                                                    Quantity
                                                </label>
                                                <div>
                                                    <input required type="number"
                                                           id="quantity"
                                                           value={fields.quantity}
                                                           onChange={handleFieldChange}
                                                           className="tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md"
                                                    />
                                                </div>

                                                <label className="tw-block tw-text-sm tw-font-medium">
                                                    Purchase Price
                                                </label>
                                                <div>
                                                    <input required type="number"
                                                           id="purchasePrice"
                                                           value={fields.purchasePrice}
                                                           onChange={handleFieldChange}
                                                           className="tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md" />
                                                </div>
                                                 {/*{props.mode !== "edit" ?*/}
                                                 {/*<>*/}
                                                 <label className="tw-block tw-text-sm tw-font-medium">
                                                     Currency
                                                 </label>
                                                 <div>
                                                     <select id="currency"
                                                             value={fields.currency ? fields.currency : "USD"}
                                                             onChange={handleFieldChange}
                                                             className="tw-py-2 tw-px-4 tw-w-44 sm:tw-w-52 tw-border tw-rounded-md">
                                                         {CurrencyOptions.map((option, idx) => (
                                                             <option key={`${option}-${idx}`} value={option.value}>{option.label}</option>
                                                         ))}
                                                     </select>
                                                 </div>
                                                 {/*</> */}
                                                 {/*: "" }*/}
                                             </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tw-bg-gray-50 dark:tw-bg-darker tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                            <button type="submit"
                                    // className="tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-green-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-green-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-green-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
                                    className="tw-inline-flex tw-justify-center tw-w-full sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                            >
                                {props.mode === "edit" ? "Update" : "Add"}
                            </button>
                            <button type="button" onClick={handleCloseEdit}
                                    className="tw-mt-3 tw-w-full sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm tw-inline-flex tw-justify-center tw-rounded-3xl border tw-border-gray-300 tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>

            </Modal>
        </>
    );
}