import React, {useEffect, useRef, useState} from 'react';
import TickerService from "../../services/TickerService";
import {FiThumbsDown, FiThumbsUp} from "react-icons/fi";
import useDynamicHeightField from "../useDynamicHeightField";
import cn from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import Avatar from "../ui/Avatar";

const INITIAL_HEIGHT = 46;

export default function CommentBox({symbol, username}) {

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(100);
    const [hasMore, setHasMore] = useState(true);

    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState( "");

    const [isExpanded, setIsExpanded] = useState(false);
    const [commentValue, setCommentValue] = useState("");

    const outerHeight = useRef(INITIAL_HEIGHT);
    const textRef = useRef(null);
    const containerRef = useRef(null);
    useDynamicHeightField(textRef, commentValue);

    const onExpand = () => {
        if (!isExpanded) {
            outerHeight.current = containerRef.current.scrollHeight;
            setIsExpanded(true);
        }
    };

    const onChange = (e) => {
        setCommentValue(e.target.value);
    };

    const onClose = () => {
        setCommentValue("");
        setIsExpanded(false);
    };

    async function fetchComments() {
        if (page === totalPages) {
            setHasMore(false);
            return;
        }
        const comments = await TickerService.getComments(symbol, page)
            .then(data => {
                console.log(data);
                return data;
            })
            .catch(err => {
                console.log(err);
                return [];
            });

        if (comments && comments.content && comments.content.length > 0) {
            setItems(items.concat(comments.content));
            setPage(page + 1);
            setTotalPages(comments.totalPages);
            setHasMore(!comments.last);
        } else {
            setHasMore(false);
            setTotalPages(0);
        }
        return comments;
    }

    useEffect(() => {
        function fetchData() {
            fetchComments();
        }

        fetchData();
    }, []);

    async function addComment(e) {
        e.preventDefault();

        let postData = {
            symbol: symbol,
            username: username,
            commentContent: commentValue,
            upVotes: 0,
            downVotes: 0,
            approved: false
        }
        const comment = await TickerService.saveComment(postData)
            .then(data => {
                setSuccessMsg("Comments saved successfully");
                setErrorMsg("");
                return data;
            })
            .catch(error => {
                console.log(error);
                setErrorMsg(error.message);
                setSuccessMsg("");
            });

            setCommentValue("");
            console.log(comment);
            setItems([comment].concat(items));

    }

    function saveCommentSentiment(id, username, value) {
        console.log('save sentiment=' + id + ' ' + username + ' ' + value);
        TickerService.saveCommentSentiment(symbol, id, username, value)
            .then(response => {
                const newList = items.map((item) => {
                    if (item.id === response.id) {
                        return {
                            ...item,
                            upVotes: response.upVotes,
                            downVotes: response.downVotes
                        };
                    }

                    return item;
                });

                setItems(newList);
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    return (
        <>
            <div className="comment-box-container tw-w-full">
                <form
                    action="#"
                    method="POST"
                    onSubmit={addComment}
                    ref={containerRef}
                    className={cn("comment-box", {
                        expanded: isExpanded,
                        collapsed: !isExpanded,
                        modified: commentValue.length > 0
                    })}
                    style={{
                        minHeight: isExpanded ? outerHeight.current : INITIAL_HEIGHT
                    }}
                >
                    <div className="header">
                        <div className="user">
                            <span>{username}</span>
                        </div>
                    </div>
                    <label htmlFor="comment">Leave a comment here...</label>
                    <textarea
                        ref={textRef}
                        onClick={onExpand}
                        onFocus={onExpand}
                        onChange={onChange}
                        className="comment-field"
                        placeholder="Leave a comment here..."
                        value={commentValue}
                        name="comment"
                        id="comment"
                        maxLength="950"
                    />
                    <div className="actions">
                        <button type="button" className="cancel tw-rounded-3xl" onClick={onClose}>
                            Cancel
                        </button>
                        <button type="submit" className="tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light" disabled={commentValue.length < 1}>
                            Respond
                        </button>
                    </div>
                </form>
            </div>

            <InfiniteScroll
                next={fetchComments}
                hasMore={hasMore}
                loader={<p className="tw-text-center tw-text-lg tw-font-semibold tw-text-primary-purple">Loading...</p>}
                dataLength={items.length}
                height={610}
                endMessage={
                    <p className="tw-text-center tw-text-lg tw-font-semibold tw-text-primary-purple">
                        No more comments
                    </p>
                }
            >
                {items.map((item) =>
                    item.approved ?
                        <div className="comment-list" key={item.id}>
                            <div className="comment-header tw-mb-2">
                                <div className="tw-justify-start tw-flex tw-flex-wrap tw-items-center tw-space-x-2">
                                    <Avatar key={`avatar-friend-${item.username}`}
                                            username={item.username}
                                            className={`tw-w-8 tw-h-8`} />
                                    <span className="tw-font-semibold tw-text-lg">{item.username}</span> <span
                                    className="tw-text-xs tw-text-primary-purple">{moment(item.createdDate).format("DD/MM/YYYY @hh:mmA")}</span>
                                </div>
                            </div>
                            <div className="tw-pl-10">{item.commentContent}</div>

                            <div
                                className="tw-justify-start tw-flex tw-flex-wrap tw-items-center tw-space-x-4 tw-text-lg tw-pl-10 tw-mt-2">

                                <div>
                                    <button className="tw-mr-2"
                                            onClick={() => saveCommentSentiment(item.id, item.username, 1)}>
                                        <FiThumbsUp className="tw-text-green-500 dark:tw-text-primary-green
                                                    hover:tw-text-green-200 dark:hover:tw-text-green-200" /></button>

                                    <span
                                        className="tw-text-sm tw-font-light tw-text-gray-300 dark:tw-text-primary-light ">{item.upVotes}</span>
                                </div>
                                <div>
                                    <button className="tw-mr-2"
                                            onClick={() => saveCommentSentiment(item.id, item.username, 0)}>
                                        <FiThumbsDown className="tw-text-red-400 dark:tw-text-primary-pink hover:tw-text-red-200 dark:hover:tw-text-red-200" /></button>

                                    <span
                                        className="tw-text-sm tw-font-light tw-text-gray-300 dark:tw-text-primary-light">{item.downVotes}</span>
                                </div>
                            </div>
                        </div> : <div></div>
                    )
                }
            </InfiniteScroll>
        </>
    )
}