import React from "react";

/**
 * style:
 * "decimal" for plain number formatting.
 * "currency" for currency formatting.
 * "percent" for percent formatting
 * "unit" for unit formatting
 */
export const Price = (props) => {
    const numberFormat = new Intl.NumberFormat(navigator.language, {
        style: props.numberStyle ? props.numberStyle : undefined,
        currency: props.showSymbol ? (props.currency ? props.currency : 'USD') : undefined,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return <>{Number(props.children) < 0 ?
        <span className="tw-text-primary-pink">{props.includeBrackets?'(':''}{props.children?numberFormat.format(props.children):'-'}{props.includeBrackets?')':''}</span> :
        <span className={props.className}>{props.includeBrackets?'(':''}{props.children?numberFormat.format(props.children):'-'}{props.includeBrackets?')':''}</span>}
    </>
};