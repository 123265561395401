import React from "react";
import {FiAlertTriangle} from "react-icons/fi";
import "../index.css";

export default function NotFound() {
    return (

        <main
            className="tw-flex tw-items-center tw-justify-center tw-h-screen tw-bg-gray-100 dark:tw-bg-white-transparent dark:tw-text-light"
        >
            <div className="tw-p-4 tw-space-y-4">
                <div
                    className="tw-flex tw-flex-col tw-items-start tw-space-y-3 sm:tw-flex-row sm:tw-space-y-0 sm:tw-items-center sm:tw-space-x-3">
                    <p className="tw-font-semibold tw-text-danger-light tw-text-9xl dark:tw-text-danger">404</p>
                    <div className="tw-space-y-2">
                        <h1 id="pageTitle" className="tw-flex tw-items-center tw-space-x-2">
                            <FiAlertTriangle className="tw-w-6 tw-h-6 tw-text-danger-light dark:tw-text-danger" />
                            <span
                                className="tw-text-xl tw-font-medium tw-text-gray-600 sm:tw-text-2xl dark:tw-text-light">
                              Oops! Page not found.
                            </span>
                        </h1>
                        <p className="tw-text-base tw-font-normal tw-text-gray-600 dark:tw-text-gray-300">
                            The page you are looking for was not found.
                        </p>
                        <p className="tw-text-base tw-font-normal tw-text-gray-600 dark:tw-text-gray-300">
                            You may return to the
                            <a href="/"
                               className="tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light hover:tw-underline dark:tw-text-blue-500"> home
                                page</a>
                        </p>
                    </div>
                </div>
            </div>
        </main>

    );
}