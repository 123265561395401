import React from "react";


export default function Forum() {


    return (
        <>
            <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-border-b
                                lg:tw-py-6 dark:tw-border-primary-darker">
                <span className="tw-text-2xl tw-font-semibold">Forum</span>
            </div>

            {/*    Main component here*/}
        </>
    );
}