import {API} from 'aws-amplify';

const apiName = 'SubscriptionApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class SentimentService {

    checkout(userId, email, priceId) {
        tickersInit.body = {
            userId: userId,
            email: email,
            priceLookupKey: priceId
        }
        return API.post(apiName, `/checkout`, tickersInit);
    }

    checkoutSession(userId, sessionId) {
        return API.get(apiName, `/checkoutSession?userId=${userId}&sessionId=${sessionId}`, tickersInit);
    }

    customerPortal(userId){
        tickersInit.body = {
            userId: userId
        }
        return API.post(apiName, `/customerPortal`, tickersInit);
    }

    createCustomer(userId, email) {
        tickersInit.body = {
            userId: userId,
            email: email
        }

        return API.post(apiName, `/createCustomer`, tickersInit);
    }

    createSubscription(userId, priceLookupKey, paymentMethodId) {
        tickersInit.body = {
            userId: userId,
            priceLookupKey: priceLookupKey,
            paymentMethodId: paymentMethodId
        }

        return API.post(apiName, `/createSubscription`, tickersInit);
    }

    cancelSubscription(subscriptionId) {
        tickersInit.body = {
            subscriptionId: subscriptionId
        }

        return API.post(apiName, `/cancelSubscription`, tickersInit);
    }

    updateSubscription(userId, subscriptionId, newPriceLookupKey) {
        tickersInit.body = {
            userId: userId,
            subscriptionId: subscriptionId,
            newPriceLookupKey: newPriceLookupKey
        }

        return API.post(apiName, `/updateSubscription`, tickersInit);
    }

    invoicePreview(userId, subscriptionId, newPriceLookupKey) {
        return API.get(apiName, `/invoicePreview?userId=${userId}&subscriptionId=${subscriptionId}&newPriceLookupKey=${newPriceLookupKey}`, tickersInit);
    }

    subscriptions(userId) {
        return API.get(apiName, `?userId=${userId}`, tickersInit);
    }
}

export default new SentimentService();
