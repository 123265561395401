import React, {useCallback, useState} from "react";
import {RankingItem} from "./RankingItem";
import {AuditTypes, FriendStatuses, SubscriberStatus} from "./FriendEnums";
import UserService from "../../services/UserService";
import {NavLink} from "react-router-dom";

export const RankingList = ({friendsList, userId, retrieveFriends, type}) => {
    {
        const [items, setItems] = useState(friendsList);

        const updateStatus = useCallback( async (friendEmail, status) => {
            console.log(userId);
            console.log(friendEmail);
            console.log(status);
            await UserService.updateFriendStatus(userId, friendEmail, status).then().catch(err => console.log(err.message));
            retrieveFriends();
        }, []);

        const renderItem = (item, index) => {
            console.log(`${type}-${item.username}-${index}`);
            return (
                <>
                    {(item.status === FriendStatuses.APPROVED || item.status === SubscriberStatus.ACTIVE)
                    &&
                    <div className="tw-px-0 tw-py-1">
                        <NavLink
                            to={`/portfolio/public?followed=${item.username}${type === AuditTypes.SUBSCRIBERS ? '&from=sub' : ''}`}>
                            <RankingItem key={`${type}-${item.username}-${index}`} index={index}
                                         username={item.username}
                                         email={item.email}
                                         change={item.changePercent}
                                         status={item.status}
                                         updateStatus={updateStatus}
                            />
                        </NavLink>
                    </div>
                    }
                    {(item.status === FriendStatuses.SENT
                        || item.status === FriendStatuses.PENDING)
                    &&
                    <div className="tw-px-0 tw-py-1">
                        <RankingItem key={`${type}-${item.username}-${index}`} index={index}
                                     username={item.username}
                                     email={item.email}
                                     change={item.changePercent}
                                     status={item.status}
                                     updateStatus={updateStatus}
                        />
                    </div>

                    }
                </>
            );
        };
        return (<>
            <div className="tw-rounded">
                {items.map((it, i) => renderItem(it, i))}
            </div>
        </>);

    }
};
