import React, {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import '../index.css';
import {useAppContext} from "../libs/contextLib";
import {useFormFields} from "../libs/hooksLib";
import {onError} from "../libs/errorLib";
import LoaderButton from "../components/loaderButton";
import UserService from "../services/UserService";
import NotificationService from "../services/NotificationService";
import {FiAlertTriangle} from "react-icons/fi";
import SuccessMessage from "../components/successMessage";

// Amplify.configure({
//     Auth: {
//         region: 'us-west-2',
//         userPoolId: 'us-west-2_pLgXF8V6N',
//         userPoolWebClientId: '2loobfb6i0me9mfq4u9fudh10m'
//     }
// });

const passwordValidator = require('password-validator');

// create a password schema
const schema = new passwordValidator();

schema
    .is().min(8)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().symbols()
    .has().not().spaces();


class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            redirect: false,
            loading: false,
            email: ''
        };
    }

    render() {
        return (
            <SignUpForm/>
        );
    }
}

function SignUpForm() {
    const [fields, handleFieldChange] = useFormFields({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
        confirmationCode: ""
    });
    const history = useHistory();
    const [newUser, setNewUser] = useState(null);
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    const [validated, setValidated] = useState(false);
    const [confirmValidated, setConfirmValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false);


    useEffect(() => {
        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setIsLoading(false);
            });
        }
    }, [isLoading]);


    function simulateNetworkRequest() {
        return new Promise((resolve) => setTimeout(resolve, 2000));
    }

    async function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();
        setIsLoading(true);

        if (form.checkValidity() === false) {
            console.log('Error validation');
            event.preventDefault();
            event.stopPropagation();
            setShowAlert(true);
        } else if (fields.password !== fields.confirmPassword
            || !validateToNextPassword()) {
            console.log('Wrong pwd '+fields.password + ' '+fields.confirmPassword);
            setShowAlert(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            const newCognitoUser = await Auth.signUp({
                username: fields.username,
                password: fields.password,
                attributes: {
                    email: fields.email,
                    given_name: fields.firstName,
                    family_name: fields.lastName
                    // preferred_username: fields.username
                }
            }).then((data) => {
                console.log('Successfully signed up user! ' + fields.email);
                // this.setState({ redirect: true });
                // this.setState({ email: fields.email });
                // setIsLoading(false);
                setShowNotification(true);
                const postData = {
                    username: fields.username,
                    email: fields.email,
                    firstName: fields.firstName,
                    lastName: fields.lastName,
                    isPaidUser: false
                }
                UserService.save(postData);
            }).then(()=>{
                NotificationService.saveNotification(
                    fields.username,
                    "Welcome to Trades League",
                    "Thank you for creating your account, you can now enjoy the app."
                )
            }).catch(err => {
                console.log('Error signing up user ' + err.message);
                onError(err);
                // setIsLoading(false);
                // this.setState({
                //     loading: false
                // });
                event.preventDefault();
                event.stopPropagation();
            });

            if (newCognitoUser !== undefined) {
                setNewUser(newCognitoUser);
            }
        }
        setValidated(true);
        setIsLoading(false);
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0;
    }

    const validateToNextPassword = () => {
        const validationRulesErrors = schema.validate(fields.password, { list: true });
        if (validationRulesErrors.length > 0) {
            console.log(formatPasswordValidateError(validationRulesErrors));
            return false;
        }
        return true;
    };

    const formatPasswordValidateError = (errors) => {
        for (let i = 0; i < errors.length; i++) {
            console.log(errors[i]);
            if (errors[i] === 'min') {
                return 'password length should be a at least 8 characters';
            } else if (errors[i] === 'lowercase') {
                return 'password should contain lowercase letters';
            } else if (errors[i] === 'uppercase') {
                return 'password should contain uppercase letters';
            } else if (errors[i] === 'digits') {
                return 'password should contain digits';
            } else if (errors[i] === 'symbols') {
                return 'password should contain symbols';
            }
        }
    };

    function AlertDismissible() {

        if (showAlert) {
            return (

                <div className="tw-flex tw-items-center tw-justify-between tw-px-2 tw-py-2 tw-rounded-md tw-bg-red-100">

                    <div
                        className="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                        <FiAlertTriangle className="tw-h-6 tw-w-6 tw-text-red-600" />
                    </div>
                    <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                        <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-red-800" id="modal-headline">
                            You got an error!
                        </h3>
                        <div className="tw-mt-2">
                            <p className="tw-text-sm tw-text-red-600">
                                Your password doesn't match or doesn't follow the instruction.
                            </p>
                        </div>
                    </div>

                </div>
            );
        }
        // setShowAlert(false);
        return <div></div>;
    }

    function NotificationAlert() {
        if (showNotification) {
            return (
                <SuccessMessage title="Welcome to Trades League" text="Please check your email to validate your email, then " />
            );
        }
        return <div></div>;
    }

    function handlePasswordFocus() {
        setShowInstruction(true);
    }

    function renderConfirmationForm() {
        return (
            <>
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-py-20">

                        <div
                            className="tw-w-full tw-max-w-sm tw-px-4 tw-py-6 tw-space-y-6 tw-bg-white tw-rounded-md dark:tw-bg-white-transparent">
                            <h1 className="tw-text-xl tw-font-semibold tw-text-center">Confirm Email</h1>
                            <p className="tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 dark:tw-text-gray-400">
                                Please check your email for the confirmation code.
                            </p>
                            <form onSubmit={handleConfirmationSubmit} className="tw-space-y-6">
                                {/*validated={{confirmValidated}}*/}
                                <input
                                    className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md
                                                dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none
                                                focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                    autoFocus
                                    type="number"
                                    id="confirmationCode"
                                    name="confirmationCode"
                                    placeholder="Verification Code"
                                    value={fields.confirmationCode}
                                    onChange={handleFieldChange}
                                    required
                                />

                                <div>
                                    <LoaderButton
                                        type="submit" isLoading={isLoading}
                                        disabled={!validateConfirmationForm()}
                                        className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center
                                            tw-text-white tw-transition-colors tw-duration-200 tw-rounded-md tw-bg-primary
                                            hover:tw-bg-primary-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary
                                            focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                    >
                                        Confirm
                                    </LoaderButton>
                                </div>
                            </form>
                        </div>

                </div>
            </>

        );
    }

    function renderForm() {
        return (
            <>
                <div
                    className="tw-flex tw-flex-col tw-items-center tw-justify-center md:tw-p-8 tw-space-y-4
                    tw-antialiased tw-text-gray-900 dark:tw-text-light">

                    <main>
                        <div className="tw-w-full tw-max-w-sm tw-px-4 tw-py-6 tw-space-y-6 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                            {showNotification ?
                                <NotificationAlert />
                                : <>
                                    <h1 className="tw-text-xl tw-font-semibold tw-text-center">Sign Up To Share
                                        Trades</h1>
                                    <form
                                        // noValidate
                                        // validated={this.state.validated}
                                        className="padding-form border-blue"
                                        onSubmit={handleSubmit}
                                        className="tw-space-y-6">

                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            placeholder="First Name"
                                            value={fields.firstName}
                                            onChange={handleFieldChange}
                                            required
                                        />
                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Last Name"
                                            value={fields.lastName}
                                            onChange={handleFieldChange}
                                            required
                                        />
                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                            type="text"
                                            id="username"
                                            name="username"
                                            placeholder="Username"
                                            value={fields.username}
                                            onChange={handleFieldChange}
                                            required
                                        />

                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:tw-focus:ring-primary-darker"
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            value={fields.email}
                                            onChange={handleFieldChange}
                                            required
                                        />
                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            value={fields.password}
                                            onChange={handleFieldChange}
                                            onFocus={handlePasswordFocus}
                                            required
                                        />
                                        {showInstruction ?
                                            <div
                                                className="tw-flex tw-items-center tw-justify-between tw-px-2 tw-py-2 tw-rounded-md tw-bg-red-100">
                                                <label className="tw-flex tw-items-center tw-text-red-600">
                                                    Your password must be 8-20 characters long, contain uppercase and
                                                    lowercase
                                                    letters,
                                                    numbers
                                                    and
                                                    special characters.
                                                </label>
                                            </div>
                                            : ''}
                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={fields.confirmPassword}
                                            onChange={handleFieldChange}
                                            required
                                        />
                                        <AlertDismissible />
                                        <div className="tw-flex tw-items-center">
                                            <input id="tAndC" name="tAndC" type="checkbox"
                                                   required
                                                   className="tw-h-4 tw-w-4 focus:tw-ring-primary
                                               dark:focus:tw-ring-offset-darker tw-text-primary dark:tw-text-primary-lighter
                                               tw-border-gray-300 checked:tw-bg-primary-dark checked:tw-border-transparent" />
                                            <label htmlFor="tAndC"
                                                   className="tw-ml-3 tw-block tw-mb-0 tw-text-sm tw-font-medium">
                                                <NavLink to="/terms" target="_blank"
                                                         className="tw-text-primary hover:tw-text-primary-lighter dark:hover:tw-text-light">
                                                    Agree to terms and conditions
                                                </NavLink>
                                                <span> and </span>
                                                <NavLink to="/tokenUsage" target="_blank"
                                                         className="tw-text-primary hover:tw-text-primary-lighter dark:hover:tw-text-light">
                                                    Token Usage
                                                </NavLink>
                                            </label>
                                        </div>
                                        <div>
                                            <LoaderButton type="submit"
                                                          isLoading={isLoading}
                                                          disabled={isLoading}
                                                          className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center
                                                          tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light
                                                          hover:tw-filter hover:tw-drop-shadow-blue-dark
                                                          disabled:hover:tw-bg-primary focus:tw-outline-none
                                                          focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                            >
                                                Sign up
                                            </LoaderButton>
                                        </div>
                                    </form>

                                    <div className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
                                        Or <NavLink to="/login"
                                                    className="tw-transition-colors tw-duration-200 tw-text-primary hover:tw-text-primary-lighter dark:hover:tw-text-light focus:tw-outline-none focus:tw-ring-primary-darker active">
                                        login to your account!</NavLink>
                                    </div>
                                </>
                            }
                        </div>
                    </main>
                </div>

            </>
        );
    }

    return (
        <>
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </>
    );
}

export default SignUp;
