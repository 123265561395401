import React from "react";


export default function PrivacyPolicy() {

    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-mb-8">
                <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Privacy Policy Trades League</span>
            </div>

            <div className="tw-py-8 tw-px-2 tw-space-y-8 sm:tw-px-4 lg:tw-mr-20 lg:tw-ml-10 tw-text-justify
                            tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">

                <div>
                    By using TradesLeague.com or submitting your personal data, you are agreeing to accept the terms of
                    this privacy policy:
                </div>

                <div>
                    TradesLeague is the data controller responsible for your personal data and is the sole owner of the
                    TradesLeague.com website or any other related websites ("Website") and all of the information
                    collected on it. TradesLeague collects information from users in several ways through the Website
                    (explained in detail in this privacy policy).
                </div>
                <div>
                    We use your Personal Data only for providing and improving the Website. By accessing or otherwise
                    using the Website, you agree to the collection and use of your Personal Data and information
                    provided in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used
                    in this Privacy Policy have the same meanings as in our Terms and Conditions.
                </div>
                <div>
                    While using our Website, we may ask you to provide us with certain personally identifiable
                    information that can be used to contact or identify you ("Personal Data"). Personal Data may
                    include, but is not limited to, your name, email address, postal address and phone number.
                </div>
                <div>
                    You may provide your Personal Data to us and we may collect your Personal Data in certain
                    situations, such as (but not limited) to signing up as a member of the Site or subscribing for
                    membership subscription services on the Website.
                </div>
                <div>
                    The Personal Data which we collect from you may be collected, used, disclosed and/or processed for
                    various purposes, depending on the circumstances for which we may/will need to process your Personal
                    Data, including but not limited to:

                    <ul className="tw-list-disc tw-list-inside">
                        <li> consideration your application for membership and/or premium subscription plan;
                            processing, administering and managing your membership and/or premium subscription plan;
                        </li>
                        <li> processing any transactions or payments made by you and to maintain payment records;
                            administering and maintaining the functions and features of the Website;
                        </li>
                        <li> contacting you or communicating with you via email for the purposes of processing,
                            administering and managing your membership with us;
                        </li>
                        <li> administering lucky draws, contests or promotions which you have participated in or signed
                            up for;
                        </li>
                        <li> for publicity purposes and conducting research, analysis and development activities
                            (including but not limited to data analytics, surveys and/or profiling) to improve our
                            services and
                            facilities in order to enhance the services we provide to you, where you have consented for
                            us to do
                            so;
                        </li>
                        <li> for sending you marketing messages relating to our and our affiliates’ products and
                            services
                            by way of email,
                        </li>
                        <li> publicity and promotion with a view to marketing our business, where you have consented for
                            us to do so. In this regard, your Personal Data will be processed by us for the purpose of
                            conducting publicity and/or the development of promotional materials for use by us, such as
                            but not
                            limited to publication of your image and Personal Data on public media platforms such as the
                            Internet, magazines, our in-house notice boards, our newsletters, social media platforms
                            etc.;
                        </li>
                        <li> record-keeping purposes;
                        </li>
                        <li> responding to legal process, pursuing legal rights and remedies, defending litigation and
                            managing any complaints or claims;
                        </li>
                        <li> complying with any applicable law, regulation, legal process or government request; and
                            storing, hosting and/or backing up (whether for disaster recovery or otherwise) your
                            Personal Data,
                            whether within or outside Singapore,
                            (collectively, the "Purposes").
                        </li>
                    </ul>
                </div>

                <div>
                    Your Personal Data may be used, disclosed, maintained, accessed, processed and/or transferred to the
                    following third parties:
                    <ul className="tw-list-disc tw-list-inside">

                        <li> Third party service providers that process or will be processing your Personal Data on our
                            behalf including but not limited to those third party service providers which have been
                            engaged by
                            us to : (i) to provide and maintain any IT equipment used to store and access your personal
                            information; (ii) to operate and maintain features on the Site; or (iv) otherwise in
                            connection with
                            the provision of membership or premium subscription services to you;
                        </li>
                        <li> financial institutions, credit card companies and payment processors; and public and
                            governmental / regulatory authorities, courts and other alternative dispute forums.
                        </li>
                    </ul>
                </div>
                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Log Data</h6>

                    <div>
                        Like many site operators, we collect information that your browser sends whenever you visit our
                        Site ("Log Data"). This Log Data may include information such as your computer's internet
                        protocol ("IP") address, browser type, browser version, the pages of our Site that you visit,
                        the time and date of your visit, the time spent on those pages and other statistics.
                    </div>
                </div>

                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Cookies</h6>

                    We use such 'cookies' to collect information. You can instruct your browser to refuse all cookies or
                    to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able
                    to use some portions of our Site. We may use 'cookies' for the following purposes:
                    <ul className="tw-list-disc tw-list-inside">
                        <li> to improve your user experience on this Site;
                        </li>
                        <li> to remember your preferences;
                        </li>
                        <li> to help us understand how this Site is performing;
                        </li>
                        <li> to monitor traffic to this Site; and
                        </li>
                        <li> to enable this Site to function properly.
                        </li>
                        <li> Personal Data (if any) that we collect from you through cookies may be passed to our third
                            party
                            service providers, whether located in Singapore or elsewhere, for one or more of the
                            Purposes (see
                            above), for managing and/or administering our website, or for the purpose of data
                            hosting/storage/backup.
                        </li>
                    </ul>
                </div>
                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Third party websites</h6>

                    Users may find content on our Website that link to the sites and services of our partners,
                    suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content
                    or links that appear on these sites and are not responsible for the practices employed by websites
                    linked to or from our Website. In addition, these sites or services, including their content and
                    links, may be constantly changing. These sites and services may have their own privacy policies and
                    customer service policies. Browsing and interaction on any other website, including websites which
                    have a link to our Website, is subject to that website's own terms and policies.
                </div>
                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Updates</h6>
                    We may update this privacy policy at our discretion from time to time by posting a new version on
                    our Website. You should check our Website occasionally to ensure any changes made are suitable for
                    you.

                </div>

            </div>

        </>
    );
}