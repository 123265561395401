import {useEffect, useState} from 'react'

export default function useDarkMode() {
    // TODO Choose between the one in settings and localstorage
    const [theme, setTheme] = useState(localStorage && localStorage.getItem("theme") ?
        localStorage.getItem("theme") : 'tw-light');
    // Remove prefix when removing bootstrap
    const colorTheme = theme === 'tw-light' ? 'tw-dark' : 'tw-light';

    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(colorTheme);
        root.classList.add(theme);
        if (localStorage) {
            localStorage.setItem("theme", theme);
        }
    }, [theme], colorTheme);
    return [colorTheme, setTheme];
}