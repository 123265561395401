import React from "react";
import {FiSearch, FiX} from "react-icons/fi";
import Autosuggest from 'react-autosuggest';
import {NavLink} from "react-router-dom";
import TickerService from "../services/TickerService";
import withUseHistory from "./historyComponent";

const theme = {
    container: {
        // position: 'relative'
    },
    input: "tw-w-96 tw-py-2 tw-pl-10 tw-pr-4 tw-border tw-rounded-full dark:tw-bg-white-transparent dark:tw-border-transparent dark:tw-text-light focus:tw-outline-none focus:tw-ring",
    inputFocused: {
        outline: 'none'
    },
    inputOpen: 'tw-rounded-bl-none tw-rounded-br-none tw-ring-transparent',
    suggestionsContainer: {
        display: 'none'
    },
    suggestionsContainerOpen: {
        display: 'block',
        position: 'absolute',
        top: 75,
        width: '24rem',
        border: '1px solid #ced4da',
        backgroundColor: '#fff',
        // fontFamily: 'Helvetica, sans-serif',
        // fontWeight: 300,
        // fontSize: 16,
        // borderBottomLeftRadius: 9999,
        // borderBottomRightRadius: 9999,
        zIndex: 150
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    suggestion: {
        cursor: 'pointer',
        padding: '10px'
    },
    suggestionHighlighted: {
        backgroundColor: '#ddd'
    }
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => (
        suggestion.symbol
);

const renderSuggestion = suggestion => (
    <div>
    <NavLink to={`/ticker/${suggestion.symbol}.${suggestion.exchange}`}>
        <div>
            <span className="st-title">{suggestion.symbol}</span> <br/>
            <span className="small-font st-text-grey">{suggestion.securityName} - {suggestion.exchange}</span>
        </div>
    </NavLink>
    </div>

);

class SearchBarAuto extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            suggestions: []
        };

        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onClearFragment = this.onClearFragment.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    onChange(event) {
        const target = event.target;
        const newValue = target.value;
        console.log(newValue);
        this.setState({
            value: newValue
        });
    }

    onSuggestionsFetchRequested() {
        const inputValue = this.state.value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength < 2 ? [] : this.getSuggestions(this.state.value);
    }

    getSuggestions(fragment) {
        TickerService.search(fragment)
            .then(data => {
                this.setState({
                    suggestions: data
                });
                console.log(data);
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    onSuggestionSelected(event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        // event.preventDefault();
        console.log(suggestionValue);
        this.setState({
            value: suggestionValue
        });
        this.props.history.push(`/ticker/${suggestionValue}.${suggestion.exchange}`);

    }

    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    }

    onClearFragment() {
        this.onSuggestionsClearRequested();
        this.setState({
            value: ''
        });
    }

    render() {

        const inputProps = {
            placeholder: 'Search...',
            value: this.state.value,
            onChange: this.onChange
        };

        return (
            <>
                <div className="tw-flex tw-items-center tw-justify-center">
                    <div
                        className="tw-relative tw-w-100 tw-flex-shrink-0 tw-text-gray-400
                        dark:tw-focus-within:tw-text-light focus-within:tw-text-gray-700">

                        <span className="tw-absolute tw-inset-y-0 tw-inline-flex tw-items-center tw-px-4">
                            <FiSearch className="tw-w-5 tw-h-5" fill="none" />
                        </span>

                        <Autosuggest
                            autoFocus
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={this.onSuggestionSelected}
                            inputProps={inputProps}
                            highlightFirstSuggestion={true}
                            theme={theme}
                        />
                        {this.state.value ?
                            <span
                                className="tw-absolute tw-right-0 tw-top-10 tw-cursor-pointer tw-inline-flex tw-items-center tw-px-4">
                                <FiX onClick={this.onClearFragment} className="tw-w-5 tw-h-5" />
                            </span>
                            : ''}
                    </div>
                </div>

                </>
        );
    }
}

export default withUseHistory(SearchBarAuto);
