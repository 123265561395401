import React, {useRef, useEffect, useCallback} from "react";
import {useOnClickOutside} from "../libs/useOnClickOutside";
import {FiX} from "react-icons/fi";

const SlidePanel = ({show, children, close}) => {

    let sideDrawerClasses = "tw-fixed tw-inset-0 tw-z-20 tw-h-full tw-w-full tw-max-w-xs tw-bg-white " +
        "dark:tw-bg-darker dark:tw-text-light sm:tw-max-w-md focus:tw-outline-none ";
    let drawerClasses = sideDrawerClasses +
        "tw-transition tw-duration-300 tw-ease-in-out tw-transform sm:tw-duration-500 tw--translate-x-full";
    let closeBtnClasses = "tw-hidden";

    if (show) {
        drawerClasses = sideDrawerClasses + "tw-translate-x-0 tw-transition tw-duration-300 tw-ease-in-out tw-transform sm:tw-duration-500";
        closeBtnClasses = "";
    }

    const escFunction = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                close();
            }
        },
        [close]
    );

    const reference = useRef();
    useOnClickOutside(reference, close);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    return (
        <>

            <div
                className={`tw-fixed tw-inset-0 tw-z-10 tw-bg-primary-darker tw-bg-gray-500 tw-bg-opacity-50 tw-transition-opacity ${closeBtnClasses}`}
                style={{opacity: 0.5}}
                aria-hidden="true"
            ></div>
            <div className={drawerClasses} ref={reference}>

                <div className={`tw-absolute tw-right-0 vp-2 tw-transform tw-translate-x-full ${closeBtnClasses}`}>
                    {/* Close button */}
                    <button
                        type="button"
                        onClick={close}
                        className="tw-p-2 tw-text-gray-300 dark:tw-text-white tw-rounded-md focus:tw-outline-none focus:tw-ring"
                    >
                        <FiX className="tw-w-5 tw-h-5" />
                    </button>
                </div>
                <div className="tw-flex tw-flex-col tw-h-screen">
                    {/* Panel header */}
                    <div className="tw-flex-shrink-0">
                        <div
                            className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-pt-4 tw-border-b dark:tw-border-primary-darker">
                            <h2 id="notificationPanelLabel" className="tw-pb-4 tw-font-semibold">Notifications</h2>
                            <div className="tw-space-x-2">

                            </div>
                        </div>
                    </div>

                    {/* Panel content */}
                    <div className="tw-flex-1 tw-pt-4 tw-overflow-y-hidden hover:tw-overflow-y-auto">
                        <div className="tw-space-y-4">
                            {/* Content */}
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SlidePanel;
