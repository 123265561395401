import {API, Auth} from 'aws-amplify';

const apiName = 'WatchlistApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class PortfolioService {

    add(username, symbol) {
        tickersInit.body = {
            username: username,
            symbol: symbol,
            order: 1
        }

        return API.post(apiName, `/add`, tickersInit);
    }

    delete(id) {
        return API.del(apiName, `/delete/${id}`, tickersInit);
    }

    getWatchlist(username, sort) {
        return API.get(apiName, `/${username}?sort=${sort}`, tickersInit);
    }

    hasSymbol(username, symbol) {
        return API.get(apiName, `/symbol?username=${username}&symbol=${symbol}`, tickersInit);
    }

    updateWatchlistOrder(id, username, symbol, newOrder) {
        tickersInit.body = {id: id, username: username, symbol: symbol, order: newOrder};
        return API.put(apiName, `/updateOrder`, tickersInit);
    }

}

export default new PortfolioService();
