import React from "react";
import {Price} from "../Price";
import {AiOutlineTrophy} from "react-icons/ai";
import {FiInfo} from "react-icons/fi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export const PersonalRankingItem = ({
                                   type,
                                   ranking,
                                   change
                               }) => {

    return (

        <div className={`tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-white tw-rounded-3xl 
                        dark:tw-bg-white-transparent tw-bg-no-repeat
                        ${change < 0 ? 'tw-bg-fireworks dark:tw-bg-fireworks-bg-red tw-bg-right' : 'tw-bg-fireworks-yellow dark:tw-bg-fireworks-bg-green tw-bg-right'}`}>
            <div>
                <div className="tw-flex tw-items-center tw-justify-between tw-text-primary-blueText dark:tw-text-light">
                    <h6 className="tw-text-lg tw-font-semibold tw-leading-6">
                        Your {type} Ranking
                    </h6>
                    <OverlayTrigger
                        id="weekly-tooltip-id"
                        placement="right"
                        delay={{show: 250, hide: 350}}
                        overlay={<Tooltip>Public performance, this is what your friends and other users see</Tooltip>}
                    >
                        <div className="tw-ml-2 tw-mb-2 tw-text-xl">
                            <FiInfo />
                        </div>
                    </OverlayTrigger>
                </div>
                <span className="tw-text-xl tw-font-semibold tw-text-gray-500 dark:tw-text-light">
                        {ranking ? ranking.toLocaleString() : ''}
                </span>
                <span
                    className={`tw-inline-block tw-px-2 tw-py-px tw-ml-2 tw-text-sm tw-rounded-md
                                ${change < 0 ? 'tw-text-primary-pink' : 'tw-text-green-500 dark:tw-text-primary-green'}`}>
                    <Price showSymbol={false} numberStyle={`percent`}>
                        {change}
                    </Price>
                </span>
            </div>

            <div>
                <span>
                    {/*<AiOutlineTrophy*/}
                    {/*    className="tw-w-10 tw-h-10 tw-text-gray-300 dark:tw-text-primary-dark" />*/}
                </span>
            </div>
        </div>
    );
};