import {Auth} from "aws-amplify";
import UserService from "../services/UserService";

export async function getUsername() {
    const userInfo = await getCurrentUserInfo()
        .then(userInfo => {
            // console.log(userInfo.attributes.email);
            return userInfo;
        })
        .catch(err => {
                console.log(err);
                return null;
            }
        );

    if (userInfo === null) {
        return null;
    }
    if (userInfo.username) {
        return userInfo.username
    } else {
        const userDbResponse = await retrieveUser(userInfo.username, userInfo.attributes.email);
        // console.log("in getUsername getCurrentUserInfo", userDbResponse);
        return userDbResponse.username
    }
}

export async function getCurrentUserInfo() {

    const userObject = await Auth.currentUserInfo()
        .then(userInfo => {
            // console.log("getCurrentUserInfo", userInfo);
            return userInfo;
        })
        .catch(err => {
                console.log(err);
            }
        );

    return userObject;
}

export async function retrieveUser(username, email) {
    const userDbResponse = await UserService.retrieveUser(username, email)
        .then(r => {
                return r
            }
        )
        .catch(e => {
            if (e.response != null && e.response.status !== 404) {
                console.log("error 404 getUserFromDb=", e.message);
            }
            console.log("error getUserFromDb=", e.message);
            return null;
        });
    return userDbResponse;
}