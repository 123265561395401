import React from "react";


export default function Terms() {

    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-mb-8">
                <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Terms & Conditions</span>
            </div>

            <div className="tw-py-8 tw-px-2 tw-space-y-8 sm:tw-px-4 lg:tw-mr-20 lg:tw-ml-10 tw-text-justify
                            tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                <div>
                    Please scroll down and read this agreement in its entirety before you use any of our products or services or become a member of TradesLeague.
                </div>
                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Disclaimer</h6>
                    Your affirmative act of using our website located at www.tradesleague.com (and all associated sites linked to it) or services ("TradesLeague") signifies that you agree to the following terms and conditions of use ("Terms of Use"). If you do not agree, do not use TradesLeague.
                    All information in TradesLeague is to be used for informational and educational purposes only. It is imperative that you perform your own analysis and due diligence before making any investment decisions.
                    The information contained in TradesLeague is provided on an "as is" basis with no guarantees of completeness, accuracy, or timeliness. Any action that you take upon the information on our site, and other websites linked from our site, is strictly at your own risk and we will not be liable or responsible for any losses or damages that may arise from the use of our site.
                    We may change these Terms of Use at any time. If you continue to use TradesLeague after we post changes to these Terms of Use, you are signifying your acceptance of the new terms. You will always have access to our Terms of Use and will be able to check it at any time. By reloading this page, you will have the most up-to-date version available to users.

                    <h6 className="tw-font-semibold tw-mt-4">Usage</h6>
                    Without limiting any other provision in these Terms, you agree not to, and will not assist, encourage, or enable others to use the Site to:
                    <br/>(a) sell, rent or otherwise sub-license material from the Site;
                    <br/>(b) reproduce, duplicate, copy or otherwise exploit material on our Site for any commercial purpose;
                    <br/>(c) access, monitor or copy any content or information of this Site using any robot, spider, scraper or other automated means or any manual process for any purpose;
                    <br/>(d) take any action that imposes, or may impose, in our discretion, an unreasonable load on our infrastructure;
                    <br/>(e) use any device, software or routine that interferes with the proper working of the Site, or otherwise attempt to interfere with the proper working of the Site;
                    <br/>(f) reverse engineer, decompile, or disassemble, any part of the Site;
                    <br/>(g) frame, mirror, reformat, or otherwise incorporate any part of this Site into any other website without our prior written authorization;
                    <br/>(h) link to the Site from a Site or transmit any material that is illegal, libelous, fraudulent, profane, vulgar, offensive, false, disparaging, defamatory, obscene, sexually explicit, racist, that promotes violence, racial hatred, or terrorism, or that we deem, in our sole discretion, to be otherwise objectionable;
                    <br/>(i) transmit any content that contain viruses, spyware, adware, or other items of a destructive nature;
                    <br/>(j) advertise or promote goods or services without our permission (including, without limitation, by sending spam); or
                    <br/>(k) impersonate any person or entity or otherwise misrepresent your affiliation or the origin of materials you transmit.

                    <br/>TradesLeague may include links to third party websites. Some of these sites may contain materials that are objectionable, unlawful, or inaccurate. You agree that TradesLeague shall not be held liable for any trading activities or other activities that occur on any website you access through links on TradesLeague. We provide these links as a convenience, and do not endorse the content or services offered by these other sites.
                    <br/><br/>AAny dealings that you have with advertisers found on TradesLeague are between you and the advertiser and you acknowledge and agree that we are not liable for any loss or claim you may have against an advertiser.
                    Unauthorized soliciting on TradesLeague is strictly prohibited and may result in penalties, including, but not limited to, temporary or permanent bans of the account found in violation, and any appropriate and available legal action for monetary and other damages.
                    TradesLeague cannot and does not represent or guarantee that any of the information available through our services, website and members is accurate, reliable, current, complete or appropriate for your needs. Various information available through our services or on TradesLeague may be specially obtained by TradesLeague from professional businesses or organizations, such as exchanges, news providers, market data providers and other content who are believed to be sources of reliable information (collectively, the "Data Providers"). Nevertheless, due to various factors, including the inherent possibility of human and mechanical error, the accuracy, completeness, timeliness, results obtained from use, and correct sequencing of information available through our services and website are not and cannot be guaranteed by TradesLeague. We make no warranty and assume no obligation or liability for scripts, indicators, ideas and other content of third parties. Your use of any third-party scripts, indicators, ideas and other content is at your sole risk.
                    Decisions to buy, sell, hold or trade in securities, commodities and other investments involve risk and are best made based on the advice of qualified financial professionals. Any trading in securities or other investments involves a risk of substantial losses. The practice of "Day Trading" involves particularly high risks and can cause you to lose substantial sums of money. Before undertaking any trading program, you should consult a qualified financial professional. Please consider carefully whether such trading is suitable for you in light of your financial condition and ability to bear financial risks. Under no circumstances shall TradesLeague be liable for any loss or damage you or anyone else incurs as a result of any trading or investment activity that you or anyone else engages in based on any information or material you receive through TradesLeague or our services or our members. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown in the TradesLeague website or shown by any of its members. In fact, there are frequently sharp differences between hypothetical performance results and actual results subsequently achieved by any particular trading program.

                    <h6 className="tw-font-semibold tw-mt-4">Members/Users/Subscribers</h6>
                    Certain services, such as saving portfolio, publishing them or the ability to comment on charts, are available only to registered users of the TradesLeague website and require you to sign in with a username and password to use them. If you register as a user of any of the features of TradesLeague, during the registration process you may be prompted to click "Register Now", "Submit" or a similar button; your clicking on such button will further confirm your agreement to be legally bound by these Terms of Use. You represent that you are of legal age to form a binding contract and are not a person barred from receiving TradesLeague services under the laws of the British Virgin Island or others applicable jurisdiction.
                    Upon your request, your account can be deleted from TradesLegue. You may delete your account at any time by visiting your Profile Settings page. If you requested your account be deleted, we will delete the personal data associated with your account, however certain personal data will be retained if it has been integrated into data that is integral to our systems and site, including data in communications you have sent via our site.
                    You agree to indemnify and hold TradesLeague and our subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of content you submit, post, transmit, modify or otherwise make available through the TradesLeague services, your use of the TradesLeague services, your connection to the TradesLeague services, your violation of the Terms of Use, or your violation of any rights of another.

                    <h6 className="tw-font-semibold tw-mt-4">Non-Professional subscriber status</h6>

                    You use market data solely for personal use, not for your business or any other entity.
                    <br/>You are not registered or qualified with the Securities Exchange Commission (SEC) or the Commodities Futures Trading Commission (CFTC).
                    <br/>You are not registered or qualified with any securities agency, any securities exchange, association or regulatory body in any country.
                    <br/>You do not perform any functions that are similar to those that require an individual to register or qualify with the SEC, the CFTC, any other securities agency, any securities exchange, or association or regulatory body, or any commodities or futures contract market, or association or regulatory body.
                    <br/>You are not engaged as an investment advisor (as that term is defined in Section 202 (a) (11) of the Investment Advisor's Act of 1940) or asset manager and you are not engaged to provide investment advice to any individual or entity.
                    <br/>You are not subscribing to the service in your capacity as a principal, officer, partner, employee, or agent of any business or on behalf of any other individual.
                    <br/>You use your own capital, not provided by any other individual or entity in the conduct of your trading.
                    <br/>You do not conduct trading for the benefit of a corporation, partnership, or other entity.
                    <br/>You have not entered into any agreement to share the profit of your trading activities or receive compensation for your trading activities.

                    <h6 className="tw-font-semibold tw-mt-4">Security</h6>

                    You accept responsibility for the confidentiality and use of any username and email address that use to register for your access to and use of our services. You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify TradesLegue of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session. TradesLegue cannot and will not be liable for any loss or damage arising from your failure to comply.
                    TradesLegue considers private information on the site (source code of protected or invite-only scripts, etc.) to be confidential to you. TradesLegue protects such private information from unauthorized use, access, or disclosure in the same manner that it protects personal data (please refer to our Privacy Policy for more information).

                    <h6 className="tw-font-semibold tw-mt-4">Token transfer and cancellation of service</h6>

                    Users can follow users and get access to their portfolios with a Token transfer.
                    We do not offer refunds of this transfer.
                    The platform will transfer back the Tokens minus a platform fees of 10% to Users who got followed.

                    <h6 className="tw-font-semibold tw-mt-4">Marketing Materials</h6>

                    By creating an account with TradesLeague, you agree that TradesLeague can use your email address to send you marketing materials, service-related notices, important information messages, special offers, etc. You can unsubscribe from this by clicking on the link provided in the emails.

                    <h6 className="tw-font-semibold tw-mt-4">Disclaimer of warranty</h6>

                    To the maximum extent permitted by applicable law, TradeLeague is provided "as is" without warranties, conditions, representations or guaranties of any kind, either expressed, implied, statutory or otherwise, including but not limited to, any implied warranties or conditions of merchantability, satisfactory quality, fitness for a particular purpose and non-infringement, with respect to the use of this website and its services, or the accuracy, completeness, timeliness or correctness of its content, in any way and for any purpose. TradesLeague does not warrant the operation of its offerings will be uninterrupted or error free. You bear the entire risk as to the results, quality and performance of the service should the service prove defective. No oral or written information or advice given by TradesLeague shall create a warranty.

                    <h6 className="tw-font-semibold tw-mt-4">Exclusion of consequential damages</h6>

                    To the maximum extent permitted by applicable law, in no event and under no legal theory shall TradesLeague or any other person who has been involved in the creation, production, or delivery of TradesLeague and its offerings be liable to you or any other person for any general, direct, indirect, special, incidental, consequential, exemplary or other damages of any character arising out of the use of or inability to use the service, including but not limited to, personal injury, loss of data, loss of profits, loss of data or output from the service being rendered inaccurate, failure of the TradesLeague service to operate with any other programs, server downtime, damages for loss of goodwill, business interruption, computer failure or malfunction, or any and all other damages or losses of whatever nature, even if TradesLeague has been advised of the possibility of such damages.

                    <h6 className="tw-font-semibold tw-mt-4">Limitation of Liability</h6>

                    You agree that the TradesLeague parties have no liability for all damages, losses, and causes of action under this agreement.

                    <h6 className="tw-font-semibold tw-mt-4">Governing law</h6>

                    These Terms of Use (and any further rules, polices, or guidelines incorporated by reference) shall be governed and construed in accordance with the laws of the British Virgin Island, without giving effect to any principles of conflicts of law. If any provision is deemed by a court of competent jurisdiction to be unlawful or unenforceable, it will not affect the validity and enforceability of the remaining provisions.

                </div>
                <div>
                    <h6 className="tw-font-semibold tw-mt-4">Data</h6>
                    <a href="https://iexcloud.io" target="_blank"
                    className="tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light">Data provided by IEX Cloud and EOD historical data</a>
                    <div>
                        Please use this <a href="https://iexcloud.io/s/bea9ac6c" target="_blank"
                                           className="tw-transition-colors tw-duration-200 tw-text-primary-lighter hover:tw-text-primary dark:hover:tw-text-light">referral link</a> if you think of subscribing to their service.
                    </div>
                </div>

            </div>

        </>
    );
}