import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "../../pages/home";
import Login from "../../pages/login";
import SignUp from "../../pages/signUp";
import Contact from "../../pages/contact";
import NotFound from "../../pages/notFound";
import ResetPassword from "../../pages/resetPassword";
import Ticker from "../../pages/ticker";
import ImportPortfolio from "../Portfolio/ImportPortfolio";
import Portfolio from "../../pages/portfolio";
import Settings from "../../pages/settings";
import RouteAuthenticated from "./RouteAuthenticated";
import RouteUnauthenticated from "./RouteUnauthenticated";
import League from "../../pages/league";
import Forum from "../../pages/forum";
import Stocks from "../../pages/stocks";
import PortfolioPublic from "../../pages/portfolioPublic";
import Terms from "../../pages/terms";
import PrivacyPolicy from "../../pages/privacyPolicy";
import Wallet from "../../pages/wallet";
import Token from "../../pages/token";
import TokenUsage from "../../pages/tokenUsage";

class Routes extends React.Component {

    render() {
        return (

            <Switch>
                <RouteUnauthenticated exact path="/login">
                    <Login />
                </RouteUnauthenticated>
                <RouteUnauthenticated exact path="/signUp">
                    <SignUp />
                </RouteUnauthenticated>
                <RouteUnauthenticated exact path="/contact">
                    <Contact />
                </RouteUnauthenticated>
                <RouteUnauthenticated exact path="/login/reset">
                    <ResetPassword />
                </RouteUnauthenticated>
                <Route exact path="/terms">
                    <Terms />
                </Route>
                <Route exact path="/privacyPolicy">
                    <PrivacyPolicy />
                </Route>
                <Route exact path="/tokenUsage">
                    <TokenUsage />
                </Route>


                <RouteAuthenticated path="/" exact>
                    <Home />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/settings">
                    <Settings />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/ticker/:tickerSymbol">
                    <Ticker />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/importPortfolio">
                    <ImportPortfolio />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/portfolio">
                    <Portfolio />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/portfolio/public">
                    <PortfolioPublic />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/league">
                    <League />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/forum">
                    <Forum />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/stocks">
                    <Stocks />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/wallet">
                    <Wallet />
                </RouteAuthenticated>
                <RouteAuthenticated exact path="/token">
                    <Token />
                </RouteAuthenticated>

                {/* Errors */}
                <RouteUnauthenticated>
                    <NotFound />
                </RouteUnauthenticated>
            </Switch>

        );
    }
}

export default Routes;