import React from 'react';

class Contact extends React.Component {

    render() {
        return (
            <div>
                {'Contact Page'}
            </div>
        );
    }
}

export default Contact;
