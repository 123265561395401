import React from "react";
import {FiLoader} from "react-icons/fi";

const Loader = () => {
    return (
        <div className="tw-inline-block tw-mr-3">
            <FiLoader className="tw-animate-spin tw-h-6 tw-w-6 tw-text-yellow-500" />
        </div>
    );
};

export default Loader;

