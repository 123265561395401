import React, {useState} from 'react';

import Modal from "react-bootstrap/Modal";
import {FiTrash2, FiAlertTriangle} from "react-icons/fi";
import PortfolioService from "../../services/PortfolioService";
import {TickerModal} from "./TickerModal";

export function TableAction(props) {
    const [showWarning, setShowWarning] = useState(false);

    const handleCloseWarning = () => setShowWarning(false);
    const handleShowWarning = () => setShowWarning(true);
    const handleDelete =  () => {
        console.log(props.row.id + " " + props.rowIndex);
        const error = false;
        PortfolioService.deleteTicker(props.row.id, props.row.username)
            .then(data => {
                console.log("deleteTicker success");
                props.updateRows(props.row.id);
            })
            .catch(error => {
                console.log(error.response);
                error = true;
            });

        if (!error) {

            handleCloseWarning();
        }
    };

    return (
        <>
            <div style={{
                textAlign: "center",
                cursor: "pointer",
                lineHeight: "normal"
            }} className="tw-flex">


                <TickerModal mode={`edit`} row={props.row} username={props.row.username} rowIndex={props.rowIndex} updateAddedRows={props.updateUpdatedRow}/>

                <button className="tw-ml-4 hover:tw-text-primary-pink" onClick={handleShowWarning}>
                    <FiTrash2 style={{fontSize: 20}} />
                </button>
            </div>

            <Modal show={showWarning} onHide={handleCloseWarning} animation={false} style={{backgroundColor: "transparent", border: "none"}} >

                <div
                    className="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4 dark:tw-bg-dark">
                        <div className="sm:tw-flex sm:tw-items-start">
                            <div
                                className="tw-mx-auto tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10">
                                <FiAlertTriangle className="tw-h-6 tw-w-6 tw-text-red-600" />
                            </div>
                            <div className="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                                <h3 className="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900 dark:tw-text-light" id="modal-headline">
                                    Remove Ticker
                                </h3>
                                <div className="tw-mt-2">
                                    <p className="tw-text-sm tw-text-gray-500 dark:tw-text-light">
                                        Are you sure you want to remove {props.row.tickerSymbol} from your portfolio?
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse dark:tw-bg-darker">
                        <button type="button" onClick={handleDelete}
                                className="tw-w-full tw-inline-flex tw-justify-center tw-rounded-3xl tw-border tw-border-transparent tw-shadow-sm tw-px-4 tw-py-2 tw-bg-red-600 tw-text-base tw-font-medium tw-text-white hover:tw-bg-red-700 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red-500 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                            Delete
                        </button>
                        <button type="button" onClick={handleCloseWarning}
                                className="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-3xl border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}