import React from "react";
import {Price} from "../Price";
import {NavLink} from "react-router-dom";


export function getSum(columnData) {
    return (
        <Price>{columnData.reduce((acc, item) => acc + item, 0)}</Price>);
}

export function getPercentSum(columnData) {
    return (
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false}>{columnData.reduce((acc, item) => acc + item, 0)}</Price>);
}

export function getPercentSumRows(columnData, dataRows) {
    return (
        // weighted value of daily change
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false}>
            {dataRows.reduce((previousValue, currentValue) => previousValue + (currentValue.dailyChangePercent * currentValue.percentOfPortfolio), 0)}
        </Price>);
}

export function getReturnUSDPercent(cell, row) {
    return (
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false} className={`tw-text-green-500 dark:tw-text-primary-green`}>
            {row.totalReturnUSD / row.totalUSD}
        </Price>);
}

export function getReturnUSDPercentSumRows(columnData, dataRows) {
    return (
        // weighted value of return change
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false} className={`tw-text-green-500 dark:tw-text-primary-green`}>
            {dataRows.filter(({totalUSD}) => totalUSD != null).reduce((sum, currentValue) =>
                sum + ((currentValue.totalReturnUSD / currentValue.totalUSD) * currentValue.percentOfPortfolio)
                , 0)}
        </Price>);
}

export function percentFormatter(cell) {
    return (
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false} className={`tw-text-green-500 dark:tw-text-primary-green`}>{cell}</Price>);
}

export function percentWithoutColourFormatter(cell) {
    return (
        <Price showSymbol={false} numberStyle={`percent`} includeBrackets={false}>{cell}</Price>);
}

export function currencyFormatterColour(cell, row) {
    return <><Price className={`tw-text-green-500 dark:tw-text-primary-green`}>{cell}</Price></>;
}

export function currencyFormatter(cell, row) {
    return <><Price>{cell}</Price></>;
}

export function symbolFormatter(cell, row) {
    return (
        <NavLink to={`/ticker/${cell}`} className="tw-transition-colors tw-duration-200 tw-text-primary-light hover:tw-text-primary dark:hover:tw-text-light">
            {cell}
            <div className="tw-text-sm tw-text-gray-500 dark:tw-text-light dark:hover:tw-text-lighter">{row.tickerName}</div>
        </NavLink>
    );
}

export function totalFormatter(cell, row) {
    return (
        <Price>{row.latestPrice * row.quantity}</Price>
    );
}

function formatToDate(dateString) {
    if (dateString === null || dateString === undefined) {
        return '';
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return new Date(year, month - 1, day).toLocaleDateString();
}

export function dateFormatter(cell) {
    return (
        formatToDate(cell)
    );
}