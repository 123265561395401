import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {SupportedExchanges} from "./SupportedExchanges";


export function ExchangesModal(props) {
    const [showExModal, setShowExModal] = useState(false);

    const handleCloseExModal = () => setShowExModal(false);
    const handleShowExModal = () => setShowExModal(true);


    return (
        <>
            <div className="tw-cursor-pointer tw-font-extrabold tw-transition-colors tw-duration-200 tw-text-primary-blueText dark:tw-text-primary-light hover:tw-text-primary-lighter dark:hover:tw-text-light"
                 onClick={handleShowExModal}>List of Supported Exchanges</div>

            <Modal show={showExModal} onHide={handleCloseExModal} animation={false}
                   style={{backgroundColor: "transparent", border: "none"}}>

                <div
                    className="tw-inline-block tw-align-bottom tw-bg-white dark:tw-bg-dark tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4 dark:tw-bg-white-transparent">
                        <div className="sm:tw-flex sm:tw-items-start">
                            <div className="tw-mt-3 tw-text-left sm:tw-mt-0 sm:tw-ml-4">
                                <h3 className="tw-text-lg tw-text-center tw-p-4 tw-leading-6 tw-font-medium tw-text-gray-500 dark:tw-text-light">
                                    List of Supported Exchanges
                                </h3>
                                <div className="tw-mt-2">
                                    <span className="tw-text-sm tw-text-gray-500 dark:tw-text-primary-light">
                                        <div className="dark:tw-text-light tw-mb-2">
                                            If you'd like to add a security from another market, add Exchange's ID from the list below like SYMBOL_NAME.EXCHANGE_ID
                                            <br/>
                                            For example, AAPL.MX for AAPL in the Mexican Stock Exchange.
                                        </div>
                                        <dl className="tw-transition-opacity tw-duration-1500 tw-delay-500 tw-flex tw-flex-wrap
                                                       tw-divide-y tw-divide-gray-200 tw-border-b tw-border-gray-200">
                                            <div className="tw-w-full tw-flex-none tw-flex tw-items-baseline tw-px-4 tw-py-2">
                                                <dt className="tw-w-2/3 tw-flex-none tw-uppercase sm:tw-text-sm tw-font-semibold tw-tracking-wide">Exchange's name</dt>
                                                <dd className="tw-uppercase tw-font-semibold tw-tracking-wide">Exchange's ID</dd>
                                            </div>
                                            {SupportedExchanges.map((option, idx) => (
                                                <div key={`${option.exchangeId}-${idx}`}
                                                     className="tw-w-full tw-flex tw-items-center tw-px-4 tw-py-2">
                                                    <dt className="tw-w-2/3 tw-flex-none tw-uppercase sm:tw-text-sm tw-font-semibold tw-tracking-wide">{option.label}</dt>
                                                    <dd className="tw-text-sm sm:tw-text-base">{option.exchangeId}</dd>
                                                </div>
                                            ))}
                                        </dl>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tw-bg-gray-50 dark:tw-bg-darker tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
                        <button type="button" onClick={handleCloseExModal}
                                className="tw-mt-3 tw-w-full sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm tw-inline-flex tw-justify-center tw-rounded-3xl border tw-border-gray-300 tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}