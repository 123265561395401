import React from 'react';
import LoaderButton from "../components/loaderButton";
import {Auth} from "aws-amplify";
import {onError} from "../libs/errorLib";
import SuccessMessage from "../components/successMessage";


class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            confirmCode: 0,
            newConfirmPassword: "",
            newPassword: "",
            validated: false,
            isLoading: false,
            codeSent: false,
            confirmed: false,
            confirmFormValidated: false,
            isConfirming: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmForm = this.handleConfirmForm.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        console.log(this.state.email);
        this.setState({isLoading: true});

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({isLoading: false});
        } else {
            console.log("valid");
            // setIsConfirming(true);

            try {
                await Auth.forgotPassword(this.state.email);
                this.setState({codeSent: true});
            } catch (error) {
                onError(error);
                this.setState({isLoading: false});
            }
        }
        // Check that if bootstrap validation doesnt work
        this.setState({validated: true});
    };

    handleConfirmForm = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        this.setState({isConfirming: true});

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({isConfirming: false});
        } else {
            console.log("valid");
            // setIsConfirming(true);

            try {
                await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmCode, this.state.newPassword);
                this.setState({confirmed: true});
            } catch (error) {
                onError(error);
                this.setState({isConfirming: false});
            }
        }
        this.setState({confirmFormValidated: true});
    };

    render() {

        return (
            <>
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center sm:tw-py-20">

                    <div
                        className="tw-w-full tw-max-w-sm tw-px-4 tw-py-8 tw-space-y-6 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                        {!this.state.codeSent ?

                            <>
                                <h1 className="tw-text-xl tw-font-semibold tw-text-center">Forgot password</h1>
                                <p className="tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 dark:tw-text-gray-400">
                                    Forgot your password? Enter your email and set a new password.
                                </p>
                                <form onSubmit={this.handleSubmit} className="tw-space-y-6">
                                    {/*validated={this.state.validated}*/}
                                    <input
                                        className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md
                                                dark:tw-bg-white-transparent dark:tw-border-gray-700 focus:tw-outline-none
                                                focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        required
                                    />

                                    <div>
                                        <LoaderButton
                                            type="submit" isLoading={this.state.isLoading}
                                            className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center
                                                      tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light
                                                      hover:tw-filter hover:tw-drop-shadow-blue-dark
                                                      disabled:hover:tw-bg-primary focus:tw-outline-none
                                                      focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                        >
                                            Reset Password
                                        </LoaderButton>
                                    </div>
                                </form>
                            </>

                            : !this.state.confirmed ?

                                <>
                                    <h1 className="tw-text-xl tw-font-semibold tw-text-center">Confirm password</h1>
                                    <p className="tw-text-sm tw-font-medium tw-text-center tw-text-gray-500 dark:tw-text-gray-400">
                                        Please check your email for the confirmation code.
                                    </p>
                                    <form onSubmit={this.handleConfirmForm} className="tw-space-y-6">
                                        {/*validated={this.state.validated}*/}
                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md
                                                dark:tw-bg-darker dark:tw-border-gray-700 focus:tw-outline-none
                                                focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                            type="number"
                                            name="confirmCode"
                                            placeholder="Verification Code"
                                            value={this.state.confirmCode}
                                            onChange={this.handleChange}
                                            required
                                        />

                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md
                                                dark:tw-bg-darker dark:tw-border-gray-700 focus:tw-outline-none
                                                focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                            type="password"
                                            name="newPassword"
                                            placeholder="New Password"
                                            value={this.state.newPassword}
                                            onChange={this.handleChange}
                                            required
                                        />

                                        <input
                                            className="tw-w-full tw-px-4 tw-py-2 tw-border tw-rounded-md
                                                dark:tw-bg-darker dark:tw-border-gray-700 focus:tw-outline-none
                                                focus:tw-ring focus:tw-ring-primary-100 dark:focus:tw-ring-primary-darker"
                                            type="password"
                                            name="newConfirmPassword"
                                            placeholder="Confirm Password"
                                            value={this.state.newConfirmPassword}
                                            onChange={this.handleChange}
                                            required
                                        />

                                        <div>
                                            <LoaderButton
                                                type="submit" isLoading={this.state.isConfirming}
                                                className="tw-w-full tw-flex tw-px-4 tw-py-2 tw-font-medium tw-text-center
                                                          tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light
                                                          hover:tw-filter hover:tw-drop-shadow-blue-dark
                                                          disabled:hover:tw-bg-primary focus:tw-outline-none
                                                          focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker"
                                            >
                                                Confirm
                                            </LoaderButton>
                                        </div>
                                    </form>

                                </>
                                : <SuccessMessage title="Your password has been reset." />
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default ResetPassword;
