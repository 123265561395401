import React from 'react';

const Roadmap = () => (

    <section id="roadmap" className="tw-px-2 md:tw-px-32 py-10 md:tw-py-20">
        <div className="container tw-mx-auto tw-text-center">
            <p className="tw-text-3xl tw-font-medium tw-text-center tw-text-primary-blueText dark:tw-text-primary-lighter tw-mb-8">Roadmap</p>

            <div className="container">
                <div className="tw-flex tw-flex-col md:tw-grid tw-grid-cols-9 tw-mx-auto tw-p-2 ">
                    {/*left*/}
                    <div className="tw-flex tw-flex-row-reverse md:tw-contents">
                        <div className="tw-col-start-1 tw-col-end-5 tw-p-4 tw-rounded-3xl tw-my-4 tw-ml-auto">
                            <p className="tw-text-xl tw-font-medium tw-leading-normal tw-text-primary-green"></p>
                            <div className="tw-leading-tight tw-text-justify tw-text-white">
                                <ul className="tw-list-none tw-list-inside tw-space-y-1 tw-pl-4 tw-pt-4">
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Platform design</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Prototype design</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Back-end implementation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Stocks and Cryptocurrencies API integration</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Social features creation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Portfolio importation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Dashboards creation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Test phase</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="tw-col-start-5 tw-col-end-6 md:tw-mx-auto tw-relative tw-mr-10">
                            <div className="tw-h-full tw-w-6 tw-flex tw-items-center tw-justify-center">
                                <div className="tw-h-full tw-w-px tw-bg-primary-green tw-pointer-events-none"></div>
                            </div>
                            <div
                                className="tw-w-6 tw-h-6 tw-absolute tw-top-1/2 tw--mt-3 tw-ml-5 md:tw--ml-12 tw-rounded-full"
                            >
                                <div className="diamond"><span className="tw-font-semibold">Q1’21</span></div>
                            </div>
                        </div>
                    </div>
                    {/* right */}
                    <div className="tw-flex md:tw-contents">
                        <div className="tw-col-start-5 tw-col-end-6 tw-mr-10 md:tw-mx-auto tw-relative">
                            <div className="tw-h-full tw-w-6 tw-flex tw-items-center tw-justify-center">
                                <div className="tw-h-full tw-w-px tw-bg-gradient-to-b tw-from-primary-green tw-to-white tw-pointer-events-none"></div>
                            </div>
                            <div className="tw-w-6 tw-h-6 tw-absolute tw-top-1/2 tw--mt-3 tw-ml-5 tw-rounded-full">
                                <div className="diamond"><span className="tw-font-semibold">Q2’21</span></div>
                            </div>
                        </div>
                        <div className="tw-col-start-6 tw-col-end-10 tw-p-4 tw-rounded-3xl tw-my-4 tw-mr-auto">
                            <h3 className="tw-font-semibold tw-text-lg tw-mb-1 tw-text-primary-green"></h3>
                            <div className="tw-leading-tight tw-text-justify">
                                <ul className="tw-list-none tw-list-inside tw-space-y-1 tw-pl-4">
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Whitepaper creation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Launch on Binance Smart Chain</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Token implementation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Wallets integration</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">PancakeSwap listing</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Logo creation</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Design 1.0 of the platform</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Landing Page creation and live</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Token release plan and distribution</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Social media: Telegram group, Twitter account</span></li>
                                    <li className="checkmark"><span className="tw-text-gray-500 dark:tw-text-white tw-ml-8">Private testing</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* left */}
                    <div className="tw-flex tw-flex-row-reverse md:tw-contents">
                        <div className="tw-col-start-1 tw-col-end-5 tw-p-4 tw-rounded-3xl tw-my-4 tw-ml-auto">
                            <h3 className="tw-font-semibold tw-text-lg tw-mb-1 tw-text-primary-green"></h3>
                            <div className="tw-leading-tight tw-text-justify">
                                <ul className="tw-list-disc tw-list-inside tw-space-y-1 tw-pl-4">
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Forum creation</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Staking function for the token</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Voting section on the platform</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Marketing campaigns</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Chat room for discussions with friends</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Exchanges listing</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">HODL features</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="tw-col-start-5 tw-col-end-6 md:tw-mx-auto tw-relative tw-mr-10">
                            <div className="tw-h-full w-6 tw-flex tw-items-center tw-justify-center">
                                <div className="tw-h-full tw-w-px tw-bg-gradient-to-b tw-from-white tw-to-primary-pink tw-pointer-events-none"></div>
                            </div>
                            <div className="tw-w-6 tw-h-6 tw-absolute tw-top-1/2 tw--mt-3 tw-ml-5 md:tw--ml-14 tw-rounded-full">
                                <div className="diamond pink"><span className="tw-font-semibold">Q3’21</span></div>
                            </div>
                        </div>
                    </div>
                    {/* right */}
                    <div className="tw-flex md:tw-contents">
                        <div className="tw-col-start-5 tw-col-end-6 tw-mr-10 md:tw-mx-auto tw-relative">
                            <div className="tw-h-full tw-w-6 tw-flex tw-items-center tw-justify-center">
                                <div className="tw-h-full tw-w-px tw-bg-primary-pink tw-pointer-events-none"></div>
                            </div>
                            <div className="tw-w-6 tw-h-6 tw-absolute tw-top-1/2 tw--mt-3 tw-ml-5 tw-rounded-full">
                                <div className="diamond pink"><span className="tw-font-semibold">Q4’21</span></div>
                            </div>
                        </div>
                        <div className="tw-col-start-6 tw-col-end-10 tw-p-4 tw-rounded-3xl tw-my-4 tw-mr-auto">
                            <h3 className="tw-font-semibold tw-text-lg tw-mb-1 tw-text-primary-green"></h3>
                            <div className="tw-leading-tight tw-text-justify">
                                <ul className="tw-list-disc tw-list-inside tw-space-y-1 tw-pl-4">
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Platform design V2.0</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Comments section under stock</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Daily competitions for token holders with token rewards</span></li>
                                    <li className="tw-text-primary-pink"><span className="tw-text-gray-500 dark:tw-text-white">Launch of the mobile App</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

);

export default Roadmap;
