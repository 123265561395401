import React from "react";
import {useParams} from 'react-router-dom';
import ChartWrapper from "../components/Ticker/ChartWrapper";

function Ticker() {
    const {tickerSymbol} = useParams();

    return (
        <ChartWrapper key={`chart-wrapper-id-${tickerSymbol}`} valSymbol={tickerSymbol} />
    );

}

export default Ticker;