import React, {useEffect, useState} from "react";

import SearchBarAuto from "../components/SearchBarAuto";
import TickerChartContainer from "../components/Ticker/TickerChartContainer";
import {QuoteItem} from "../components/Ticker/QuoteItem";
import Loader from "../components/ui/Loader";
import TickerService from "../services/TickerService";
import {TopTypes} from "../components/TopFive/TopTypes";
import {FiActivity, FiTrendingDown, FiTrendingUp} from "react-icons/fi";
import {TopFive} from "../components/TopFive/TopFive";

const symbols = "SPY,QQQ,DIA,BTC-USD.CC,ETH-USD.CC,EURUSD.FOREX";

export default function Stocks() {

    const [quotes, setQuotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [topGainers, setTopGainers] = useState([]);
    const [topLosers, setTopLosers] = useState([]);
    const [topMostActive, setTopMostActive] = useState([]);


    useEffect(() => {
        async function fetchData() {

            TickerService.batch(symbols)
                .then(data => {
                    setQuotes(data);
                })
                .catch(err => {
                    console.log(err.message);
                    setQuotes([]);
                });

            setIsLoading(false);

            retrieveTopList(TopTypes.GAINERS).then(d => setTopGainers(d));
            retrieveTopList(TopTypes.LOSERS).then(d => setTopLosers(d));
            retrieveTopList(TopTypes.MOST_ACTIVE).then(d => setTopMostActive(d));
        }

        fetchData();

    }, []);

    async function retrieveTopList(type) {

        const list = await TickerService.getTopList(type)
            .then(data => {

                console.log(data);
                return data;
            })
            .catch(error => {
                console.log(error.message);
                return [];
            });
        return list;
    }


    return (
        <>
            <div className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between
                            tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-pb-4 lg:tw-pb-8">
                <span className="tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Market</span>
                <SearchBarAuto />
            </div>

            {isLoading ? <div className="tw-text-center tw-py-20"><Loader /></div> :
                <div
                    className="tw-grid tw-grid-cols-2 tw-py-4 tw-gap-2 tw-space-y-0 tw-px-2 sm:tw-px-4 sm:tw-grid-cols-3 lg:tw-pr-20 lg:tw-pl-10 lg:tw-gap-8">
                    {quotes && quotes.length > 0 &&
                    quotes.map((it, index) =>
                        <QuoteItem key={`stocks-quote-${it.symbol}-${index}`}
                                   symbol={it.symbol}
                                   sector={it.sector}
                                   primaryExchange={it.primaryExchange}
                                   companyName={it.companyName}
                                   price={it.latestPrice}
                                   change={it.changePercent} />
                    )}
                </div>
            }
            <div
                className="tw-row-start-1 tw-row-span-1 tw-grid tw-grid-cols-1 tw-gap-8 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-py-4">

                <div className="tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent tw-p-4 tw-h-76">
                    <div className="tw-mb-2 tw-text-primary-blueText dark:tw-text-primary-purple"><span
                        className="tw-text-xl tw-font-semibold">S&P 500 </span> <span
                        className="tw-text-sm">SPY index</span></div>
                    <TickerChartContainer symbol="SPY" />
                </div>
            </div>
            {/* Top list */}
            <div
                className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">

                { /* Most Actives */}
                <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                    {/*header*/}
                    <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                            Most Actives
                        </span>
                        <div
                            className="tw-flex tw-text-white dark:tw-text-darker dark:hover:tw-text-light hover:tw-text-gray-500">
                            <span className="tw-text-xl tw-text-primary-blueText dark:tw-text-primary-light">
                                {/*<FiActivity />*/}
                            </span>

                        </div>

                    </div>
                    <div className="tw-relative tw-p-4 tw-h-72">
                        {/*Content*/}
                        {topMostActive && topMostActive.length ?
                            <TopFive key={`top-five-gainers-id`} top5List={topMostActive} />
                            : <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Nothing to display</div>
                        }
                    </div>
                </div>
                { /*Gainers */}
                <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                    {/*header*/}
                    <div
                        className="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                            Top 5 Gainers
                        </span>
                        <div className="tw-flex tw-items-center tw-space-x-2">
                            <span className="tw-text-xl tw-text-primary-blueText dark:tw-text-primary-light">
                                {/*<FiTrendingUp />*/}
                            </span>
                        </div>
                    </div>
                    <div className="tw-relative tw-p-4 tw-h-72">
                        {/*Content*/}
                        {topGainers && topGainers.length ?
                            <TopFive key={`top-five-gainers-id`} top5List={topGainers} />
                            : <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">No gainers</div>
                        }
                    </div>
                </div>
                { /* Losers */}
                <div className="tw-col-span-1 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                    {/*header*/}
                    <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                        <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText dark:tw-text-primary-light">
                            Top 5 Losers
                        </span>
                        <div className="tw-flex tw-items-center tw-space-x-2">
                            <span className="tw-text-xl tw-text-primary-blueText dark:tw-text-primary-light">
                                {/*<FiTrendingDown />*/}
                            </span>
                        </div>
                    </div>
                    <div className="tw-relative tw-p-4 tw-h-72">
                        {/*Content*/}
                        {topLosers && topLosers.length ?
                            <TopFive key={`top-five-losers-id`} top5List={topLosers} />
                            : <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">No losers</div>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}