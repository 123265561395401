import React from 'react';
import '../home.css';
import '../index.css';
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Watchlist} from "../components/Watchlist/Watchlist";
import WatchlistService from "../services/WatchlistService";
import PortfolioService from "../services/PortfolioService";
import {Price} from "../components/Price";
import {FiAward, FiBookOpen, FiDollarSign, FiInfo} from "react-icons/fi";
import withUseAppContext from "../components/contextComponent";
import {NavLink} from "react-router-dom";
import {getCurrentUserInfo, retrieveUser} from "../libs/getUsername";
import Loader from "../components/ui/Loader";
import SearchBarAuto from "../components/SearchBarAuto";
import {Friends} from "../components/Friends/Friends";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {TopRanking} from "../components/TopRanking/TopRanking";
import {RankingTypes} from "../components/TopRanking/RankingTypes";
import Tooltip from "react-bootstrap/Tooltip";


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            watchlist: [],
            watchlistId: 0,
            purchasePriceSum: 0,
            currentPriceSum: 0,
            totalReturn: 0,
            totalReturnPercent: 0.0,
            top10Weekly: [],
            errorMessage: '',
            isLoading: true
        }

        this.getUsername = this.getUsername.bind(this);
        this.retrieveWatchlist = this.retrieveWatchlist.bind(this);
        this.getPortfolio = this.getPortfolio.bind(this);
        this.retrieveTopWeekly = this.retrieveTopWeekly.bind();
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        });
        this.getUsername().then(r => {
                this.setState({
                    isLoading: false
                })
                this.retrieveWatchlist("order");
                this.getPortfolio();
                this.retrieveTopWeekly();

            }
        );
    }

    getUsername = async () => {
        const userInfo = await getCurrentUserInfo()
            .then(userInfo => {
                // console.log(userInfo.attributes.email);
                return userInfo;
            })
            .catch(err => {
                    console.log(err);
                    return null;
                }
            );

        if (userInfo === null) {
            return null;
        }

        if (userInfo.username) {
            this.setState({
                username: userInfo.username
            });
        } else {
            const userDbResponse = await retrieveUser(userInfo.username, userInfo.attributes.email);
            console.log("in getUsername getCurrentUserInfo");
            console.log(userDbResponse);
            this.setState({
                username: userDbResponse.username
            });
        }
    }

    getPortfolio = async () => {
        if (this.state.username) {
            let username = this.state.username;
            await PortfolioService.getPortfolio(username)
                .then(r => {
                        const purchasePriceSum = r.reduce((acc, item) => acc + ((item['purchasePriceUSD'] * item['quantity']) || 0), 0);
                        const currentPriceSum = r.reduce((acc, item) => acc + (item['totalUSD'] || 0), 0);
                        const totalReturn = r.reduce((acc, item) => acc + (item['totalReturnUSD'] || 0), 0);
                        this.setState({
                            purchasePriceSum: purchasePriceSum,
                            currentPriceSum: currentPriceSum,
                            totalReturn: totalReturn,
                            totalReturnPercent: totalReturn != null && purchasePriceSum != null ? totalReturn / purchasePriceSum : 0.0
                        });
                    }
                )
                .catch(e => {
                    if (e.response != null && e.response.status !== 404) {
                        this.setState({
                            errorMessage: "Error getting portfolio. refresh page!"
                        });
                    }
                    console.log(e);
                });
        }
    }

    retrieveWatchlist(sort) {
        if (this.state.username) {
            WatchlistService.getWatchlist(this.state.username, sort)
                .then(data => {
                    console.log("retrieveWatchlist");
                    console.log(data);
                    this.setState({
                        watchlist: data,
                        watchlistId: Math.floor(Math.random() * 100)
                    });
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    }

    getWatchlist(sort, e) {
        e.preventDefault();
        this.retrieveWatchlist(sort);
    }

    retrieveTopWeekly = async () => {
        {
            if (this.state.username) {
                await PortfolioService.getTop10(RankingTypes.ALL_TIME_DAILY)
                    .then(r => {
                            console.log(r);
                            this.setState({
                                top10Weekly: r
                            });
                        }
                    )
                    .catch(e => {
                        if (e.response != null && e.response.status !== 404) {
                            console.log(e.message);
                        }
                        this.setState({
                            top10Weekly: []
                        });
                    });
            }
        }
    }


    render() {

        return (
            <>
                {this.state.isLoading ?
                    <div className="tw-text-center tw-py-20"><Loader /></div> :
                    <>
                    {
                        this.state.username ?
                            <>
                                <div
                                    className="tw-grid tw-grid-cols-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-pb-4 lg:tw-pb-8">
                                    <span className="tw-pb-2 sm:tw-pb-0 tw-text-4xl tw-font-extrabold tw-text-gray-500 dark:tw-text-light">Dashboard</span>
                                    <SearchBarAuto />
                                </div>

                                <div
                                    className="tw-row-start-1 tw-row-span-1 tw-grid tw-grid-rows-3 tw-grid-cols-1 tw-gap-8 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-pb-4 lg:tw-grid-rows-3 xl:tw-grid-rows-3 lg:tw-grid-cols-3 xl:tw-grid-cols-3">

                                    {/* Return */}
                                    <div
                                        className="tw-col-span-1 tw-row-span-3 tw-bg-white dark:tw-bg-gradient-blue tw-bg-no-repeat tw-bg-right-bottom
                                                   tw-rounded-3xl tw-relative tw-p-4 tw-h-82">
                                        <div
                                            className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-md">
                                            <div>
                                                <h6 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-primary-blueText dark:tw-text-primary-light">
                                                    Initial Investment
                                                </h6>
                                                <span className="tw-text-3xl tw-font-semibold tw-text-gray-500 dark:tw-text-light">
                                                    <Price showSymbol={true} numberStyle={`currency`}>
                                                        {this.state.purchasePriceSum}
                                                    </Price>
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-md">
                                            <div>
                                                <h6 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-primary-blueText dark:tw-text-primary-light">
                                                    Current Value
                                                </h6>
                                                <span className="tw-text-3xl tw-font-semibold tw-text-gray-500 dark:tw-text-light">
                                                    <Price showSymbol={true} numberStyle={`currency`}>
                                                        {this.state.currentPriceSum}
                                                    </Price>
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-rounded-md">
                                            <div>
                                                <h6 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-primary-blueText dark:tw-text-primary-light">
                                                    Total Return
                                                </h6>
                                                <span className="tw-text-3xl tw-font-semibold tw-text-gray-500 dark:tw-text-light">
                                                    <Price showSymbol={true} numberStyle={`currency`}>
                                                        {this.state.totalReturn}
                                                    </Price>
                                                </span>
                                                <span
                                                    className={`tw-inline-block tw-px-2 tw-py-px tw-ml-2 tw-text-base tw-text-green-500 dark:tw-text-primary-lighter`}>
                                                    <Price showSymbol={false} numberStyle={`percent`}>
                                                        {this.state.totalReturnPercent}
                                                    </Price>
                                                </span>
                                            </div>
                                        </div>
                                        {/* Circles */}
                                        <div
                                            className="tw-w-24 tw-h-24 tw-absolute tw--right-12 tw-top-10 tw-border-l tw-rounded-l-full tw-border-blue-400 tw-border-opacity-80"></div>
                                        <div
                                            className="tw-w-40 tw-h-40 tw-absolute tw--right-20 tw-top-4 tw-border-l tw-rounded-l-full tw-border-blue-400 tw-border-opacity-80"></div>
                                        {/*<div*/}
                                        {/*    className="tw-w-40 tw-h-40 tw-absolute tw-right-0 tw--bottom-20 tw-border-t tw-rounded-t-full tw-border-blue-400 tw-border-opacity-60"></div>*/}
                                        {/*<div*/}
                                        {/*    className="tw-w-24 tw-h-24 tw-absolute tw-right-8 tw--bottom-12 tw-border-t tw-rounded-t-full tw-border-blue-400 tw-border-opacity-60"></div>*/}
                                        {/*<div*/}
                                        {/*    className="tw-w-12 tw-h-12 tw-absolute tw-right-14 tw--bottom-6 tw-border-t tw-rounded-full tw-border-blue-400 tw-border-opacity-60"></div>*/}
                                    </div>

                                    <div
                                        className="tw-col-span-1 tw-row-span-3 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                                        { /* Watchlist */}
                                        <div className="tw-col-span-1">
                                            {/*header*/}
                                            <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                                                <span className="tw-text-lg tw-font-semibold tw-text-primary-blueText
                                                                 dark:tw-text-primary-light">
                                                    Watchlist
                                                </span>
                                                <div
                                                    className="tw-flex tw-text-white dark:tw-text-darker dark:hover:tw-text-light hover:tw-text-gray-500">
                                                    <div className="">
                                                    <span className="tw-text-xs">
                                                        Sort by:
                                                    </span>
                                                    </div>
                                                    <div>
                                                        <button type="button"
                                                                onClick={this.getWatchlist.bind(this, "symbol")}
                                                                className="tw-text-gray-500 dark:tw-text-light hover:tw-bg-blue-200 dark:hover:tw-bg-gradient-blue-light dark:hover:tw-text-light tw-rounded-md tw-text-xs tw-font-medium tw-px-1">
                                                            A-Z
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button type="button"
                                                                onClick={this.getWatchlist.bind(this, "order")}
                                                                className="tw-text-gray-500 dark:tw-text-light hover:tw-bg-blue-200 dark:hover:tw-bg-gradient-blue-light dark:hover:tw-text-light tw-rounded-md tw-text-xs tw-font-medium tw-px-1">
                                                            My sort
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tw-relative tw-p-4 tw-h-72">
                                                {/*Content*/}
                                                {this.state.watchlist.length ?
                                                    <DndProvider backend={HTML5Backend}>
                                                        <Watchlist key={`watchlist-home-id${this.state.watchlistId}`}
                                                                   watchlist={this.state.watchlist} />
                                                    </DndProvider>
                                                    : <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Nothing there</div>}
                                            </div>
                                        </div>

                                    </div>
                                    <div
                                        className="tw-col-span-1 tw-row-span-3 tw-bg-white tw-rounded-3xl dark:tw-bg-white-transparent">
                                        <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-text-primary-blueText dark:tw-text-primary-light">
                                            <div className="tw-flex tw-items-center tw-justify-between tw-font-semibold">
                                                <span className="tw-text-lg">
                                                    Best Performing Portfolios
                                                </span>
                                                <OverlayTrigger
                                                    id="weekly-tooltip-id"
                                                    placement="right"
                                                    delay={{show: 250, hide: 350}}
                                                    overlay={<Tooltip>
                                                        Best Performing Portfolios ranking is calculated as today or previous day market's close value
                                                    </Tooltip>}
                                                >
                                                    <div className="tw-ml-2">
                                                        <FiInfo />
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="tw-flex tw-items-center tw-space-x-2">
                                                <span className="tw-text-xl">
                                                    {/*<FiAward />*/}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="tw-relative tw-p-4 tw-h-72">
                                            {this.state.top10Weekly && this.state.top10Weekly.length > 0 &&
                                            <TopRanking key={`top-ten-weekly-home-id`} topRankingList={this.state.top10Weekly} />}

                                            {(!this.state.top10Weekly || this.state.top10Weekly.length == 0) &&
                                            <div className="tw-text-primary-purple tw-h-28 tw-flex tw-items-end tw-justify-center">Not enough data yet</div>}
                                        </div>
                                    </div>
                                </div>

                                {/* Friends */}
                                <div
                                    className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">
                                    { /* Friends list */}
                                    {this.state.username && <Friends />}
                                </div>

                                {/*<div*/}
                                {/*    className="tw-grid tw-grid-cols-1 tw-p-4 tw-px-2 sm:tw-px-4 lg:tw-pr-20 lg:tw-pl-10 tw-space-y-8 lg:tw-gap-8 lg:tw-space-y-0 lg:tw-grid-cols-3">*/}
                                {/*    /!*Articles*!/*/}
                                {/*    <div className="tw-col-span-3 tw-bg-white tw-rounded-md dark:tw-bg-darker">*/}
                                {/*        <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b*/}
                                {/*                        dark:tw-border-primary">*/}
                                {/*            <span className="tw-text-lg tw-font-semibold tw-text-gray-500*/}
                                {/*                             dark:tw-text-light">*/}
                                {/*                Latest Articles*/}
                                {/*            </span>*/}
                                {/*            <div className="tw-flex tw-items-center tw-space-x-2">*/}
                                {/*                <span className="tw-text-xl tw-text-gray-500 dark:tw-text-light ">*/}
                                {/*                    <FiBookOpen />*/}
                                {/*                </span>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="tw-p-4">*/}
                                {/*            <ul className="tw-list-disc tw-list-inside">*/}
                                {/*                <li>article 1</li>*/}
                                {/*                <li>article 2</li>*/}
                                {/*                <li>article 3</li>*/}
                                {/*                <li>article 4</li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>
                            : <div
                                className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-4 tw-border-b lg:tw-py-6 dark:tw-border-primary-darker">

                        <span className="tw-text-xl tw-font-semibold ">
                            Welcome to Trades Shares, before you can use the application, please create a username in the
                            <NavLink to="/settings" className="tw-transition-colors tw-duration-200 tw-text-primary-lighter
                            hover:tw-text-primary dark:hover:tw-text-light"> settings page</NavLink>.
                        </span>
                            </div>
                    }
                    </>
                }
            </>
        );
    }
}

export default withUseAppContext(Home);
