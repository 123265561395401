export const FriendStatuses = {
    PENDING: 'PENDING',
    SENT: 'SENT',
    APPROVED: 'APPROVED',
    DENIED: 'DENIED',
    DELETED: 'DELETED'
}

export const SubscriberStatus = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
}

export const AuditTypes = {
    FRIENDS: 'FRIENDS',
    SUBSCRIBERS: 'SUBSCRIBERS'
}

export const PortfolioActions = {
    ADDED: 'ADDED',
    UPDATED: 'UPDATED',
    REMOVED: 'REMOVED'
}
