import {API} from 'aws-amplify';
import { v1 as uuidv1 } from 'uuid';

const apiName = 'TickersApi';
const tickersInit = {
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {  // OPTIONAL
        // name: 'param',
    },
};

class TickerService {

    getStats(symbol) {
        return API.get(apiName, `/stats/${symbol}`, tickersInit);
    }

    getHistory(symbol, range) {
        return API.get(apiName, `/chart/${symbol}/${range}`, tickersInit);
    }

    batch(symbols) {
        return API.get(apiName, `/batch?symbols=${symbols}`, tickersInit);
    }

    search(fragment) {
        return API.get(apiName, `/search/${fragment}`, tickersInit);
    }

    news(symbol) {
        return API.get(apiName, `/news/${symbol}`, tickersInit);
    }

    getLast(symbols, sector) {
        return API.get(apiName, `/last?symbols=${symbols}&sector=${sector}`, tickersInit);
    }

    getTopList(type) {
        return API.get(apiName, `/list?type=${type}`, tickersInit);
    }

    getComments(symbol, page) {
        return API.get(apiName, `/comments?symbol=${symbol}&page=${page}&size=5&sort=createdDate,desc`, tickersInit);
    }

    saveComment(data) {
        tickersInit.body = {
            id: uuidv1(),
            symbol: data.symbol,
            username: data.username,
            commentContent: data.commentContent,
            upVotes: data.upVotes,
            downVotes: data.downVotes,
            approved: data.approved
        }
        return API.post(apiName, `/comments/add`, tickersInit);
    }

    saveCommentSentiment(symbol, commentId, username, value) {
        tickersInit.body = {
            symbol: symbol,
            id: commentId,
            username: username,
            value: value
        }

        return API.post(apiName, `/comments/sentiments/save`, tickersInit);
    }
}

export default new TickerService();
