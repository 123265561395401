import Amplify,  {Auth, API} from "aws-amplify";
import awsExports from "./aws-exports";

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,

        // REQUIRED - Amazon Cognito Region
        region: `${process.env.REACT_APP_COGNITO_REGION}`,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: `${process.env.REACT_APP_COGNITO_REGION}`,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID}`,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // TODO set to true
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'your_cognito_domain',
        //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    },
    API: {
        endpoints: [
            {
                name: "TickersApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/tickers`,
                custom_header: async () => {
                    return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
                        'Content-Type': 'application/json'
                    }
                    // Check if it's better to use AccessToken https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-idp-settings.html
                    // Need to config Cognito
                    // Alternatively, with Cognito User Pools use this:
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            },
            {
                name: "PortfoliosApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/portfolios`,
                custom_header: async () => {
                    return {
                        'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    }
                }
            },
            {
                name: "SentimentsApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/sentiments`,
                custom_header: async () => {
                    return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
                        'Content-Type': 'application/json'
                    }
                }
            },
            {
                name: "WatchlistApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/watchlist`,
                custom_header: async () => {
                    return { 'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
                        'Content-Type': 'application/json'
                    }
                }
            },
            {
                name: "UsersApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/users`
            },
            {
                name: "NotificationsApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/notifications`
            },
            {
                name: "SubscriptionApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/subscriptions`,
                custom_header: async () => {
                    return {
                        'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
                        'Content-Type': 'application/json'
                    }
                }
            },
            {
                name: "AuditsApi",
                endpoint: `${process.env.REACT_APP_API_URL}/ms-share-trades/api/audits`,
                custom_header: async () => {
                    return {
                        'Authorization': `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                        'Access-Control-Allow-Headers': 'Origin, Content-Type, Access-Control-Allow-Headers, Authorization, X-Auth-Token',
                        'Content-Type': 'application/json'
                    }
                }
            }
        ]
    },
    awsExports,
    Storage: {
        AWSS3: {
            bucket: 'avatars.tradesleague.com', //REQUIRED -  Amazon S3 bucket name
            region: 'us-west-2', //OPTIONAL -  Amazon service region
        }
    }
    // ssr: true
});

const currentConfig = Auth.configure(awsExports);