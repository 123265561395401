import React, {useEffect, useState} from 'react';
import AuditService from "../../services/AuditService";
import InfiniteScroll from 'react-infinite-scroll-component';
import {AuditItem} from "./AuditItem";


export const Audit = ({userId, type}) => {
    {
        const [items, setItems] = useState([]);
        const [page, setPage] = useState(0);
        const [totalPages, setTotalPages] = useState(100);
        const [hasMore, setHasMore] = useState(true);

        async function fetchPortfolioAudit() {

            if (page === totalPages) {
                setHasMore(false);
                return;
            }
            const audits = await AuditService.retrievePortfolioAudit(userId, type, page)
                .then(d => {
                    console.log(d);
                    return d;
                })
                .catch(err => {
                    console.log(err.message);
                    return [];
                });

            if (audits && audits.content && audits.content.length > 0) {
                setItems(items.concat(audits.content));
                setPage(page + 1);
                setTotalPages(audits.totalPages);
                setHasMore(!audits.last);
            } else {
                setHasMore(false);
                setTotalPages(0);
            }
            return audits;
        }

        useEffect(() => {
            function fetchData() {
                fetchPortfolioAudit();
            }

            fetchData();

        }, [userId]);

        const renderItem = (item, index) => {
            // console.log(`audit-item-${item.username}-${index}`);
            return (
                <AuditItem key={`audit-item-${type}-${item.username}-${index}`} index={index} item={item} />
            );
        };

        return (<>

            <InfiniteScroll
                dataLength={items.length}
                next={fetchPortfolioAudit}
                hasMore={hasMore}
                loader={<p className="tw-text-center tw-text-lg tw-font-semibold">Loading...</p>}
                height={310}
                endMessage={
                    <p className="tw-text-center tw-text-lg tw-text-primary-purple">
                        No more activity
                    </p>
                }
            >
                <table className="tw-table-auto tw-w-full tw-items-center">
                    <thead className="tw-text-primary-purple tw-sticky tw-top-0 tw-bg-white dark:tw-bg-blue-middle dark:tw-bg-opacity-100">
                        <tr>
                            <th>Users’ activity</th>
                            <th>Quantity</th>
                            <th>Purchased price</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody className="tw-text-gray-500 dark:tw-text-light">
                        {items.map((it, i) => renderItem(it, i))}
                    </tbody>
                </table>
            </InfiniteScroll>



        </>);

    }
};