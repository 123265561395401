import React, {useCallback, useEffect, useState} from "react";
import NotificationService from "../../services/NotificationService";
import Loader from "../ui/Loader";
import NotificationItem from "./NotificationItem";
import {Auth} from "aws-amplify";

export default function Notifications(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const {parentState} = props;

    // initial value of child settings from parent
    const [childStateSettings, setChildStateSettings] = useState(parentState);

    const fetchNotifications = useCallback(async () => {

        console.log("in header fetchNotifications " + props.username);
        const response = await NotificationService.retrieveNotifications(props.username)
            .then(r => {
                    console.log(r);
                    return r
                }
            )
            .catch(e => {
                if (e.response != null && e.response.status !== 404) {
                    console.log("error 404 getUserFromDb=" + e);
                }
                console.log("error getUserFromDb=" + e);
                return [];
            });

        console.log("response");
        console.log(response);
        setNotifications(response)
        setIsLoading(false)
        return response;
    }, [])

    useEffect(() => {
        setIsLoading(true);
        fetchNotifications().then();
    }, [fetchNotifications]);

    return (
        <>
            {isLoading ? <Loader /> : ""}
            {notifications.length ?
                <>

                    {notifications.map((n, index) => (
                        <NotificationItem key={n.id + '_' + index} notification={n} />
                    ))}

                </>
                : "You don't have any notification"}
        </>
    );
};
