import React, {useRef, useState} from "react";
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import Storage from "@aws-amplify/storage";
import {useAppContext} from "../../libs/contextLib";


function AvatarEdit(props) {
    const [imageURL, setImageURL] = useState('');
    const [image, setImage] = useState('./logo192.png');
    const [scale, setScale] = useState("1");
    const [rotate, setRotate] = useState(0);
    const [borderRadius, setBorderRadius] = useState(100);
    const [width, setWidth] = useState(150);
    const [height, setHeight] = useState(150);
    const [backgroundColor, setBackgroundColor] = useState('#ced4da');
    const editor = useRef(null);
    const { authenticatedUser } = useAppContext();

    const handleNewImage = (e) => {
        setImage(e.target.files[0]);
    }

    const onClickSave = async () => {
        if (editor && editor.current) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            // const canvas = editor.current.getImage().toDataURL();
            //
            // // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            // const canvasScaled = this.editor.getImageScaledToCanvas()
            // console.log(canvasScaled);
            //
            // const dataURL = canvas.toDataURL();
            // console.log(dataURL);


            const img = editor.current.getImageScaledToCanvas().toDataURL();
            const response = await fetch(img);
            const blob = await response.blob();

            console.log("img2", image);

            if (blob.size > 6291456) {
                alert("Image must be under 5MB");
                return;
            }

            if (editor.current.getImageScaledToCanvas() && image) {
                try {
                    // const name = image.name;
                    // const lastDot = name.lastIndexOf('.');
                    // const ext = name.substring(lastDot + 1);

                    const {key} = await Storage.put(`${authenticatedUser.username}/avatar-${authenticatedUser.username}.png`, blob, {
                        level: 'public',
                        contentType: image.type,
                        // acl: 'public-read',
                        cacheControl: 'no-cache'
                    }).catch(err => console.log(err));

                    console.log('S3 Object key', key);
                    const signedURL = await Storage.get(key, {level: 'public', cacheControl: 'no-cache' });
                    console.log('S3 Object signedURL', signedURL);

                    setImageURL(key);
                    props.saveImage(signedURL);

                } catch (err) {
                    console.log('Error uploading avatar:', err);
                }
            }

            // const base64str = img.split('base64,')[1];
            // const decoded = atob(base64str);
            //
            // console.log("FileSize: " + decoded.length);
            //
            // if (decoded.length > 6291456) {
            //     alert("Image must be under 5MB");
            //     return;
            // }

            // props.saveImage(img);
        }
    }


    // const setEditorRef = (e) => (editor = e)


    return (
        <>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    setImage(acceptedFiles[0])
                }}
                noClick
                multiple={false}
                style={{
                    width: width,
                    height: height,
                    marginBottom: '35px',
                    border: '1px solid #ced4da'
                }}
            >
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}>
                        <AvatarEditor
                            ref={editor}
                            image={image}
                            width={width}
                            height={height}
                            scale={parseFloat(scale)}
                            border={50}
                            borderRadius={borderRadius}
                            backgroundColor={backgroundColor}
                            color={[255, 255, 255, 0.6]} // RGBA
                            rotate={rotate}
                        />
                        <br />
                        <div className="tw-px-4">
                            New Avatar:
                            <input
                                name="newImage"
                                type="file"
                                accept='image/png,image/jpeg,image/jpg,image/gif'
                                onChange={handleNewImage}
                                {...getInputProps()}
                                style={{display: 'initial'}}
                                className="tw-px-4 tw-py-2 tw-font-medium tw-text-center"
                            />
                            <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 2MB
                            </p>
                        </div>
                    </div>
                )}
            </Dropzone>
            <br />
            <div
                className="tw-px-4 tw-py-3 tw-bg-gray-50 tw-text-right tw-rounded-b-3xl sm:tw-px-6 dark:tw-border-t dark:tw-border-primary-dark dark:tw-bg-white-transparent">
                <input type="button" onClick={onClickSave} value="Upload"
                       className="tw-px-4 tw-py-2 tw-font-medium tw-text-center tw-text-white tw-transition-colors tw-duration-200 tw-rounded-3xl tw-bg-gradient-blue-purple dark:tw-bg-gradient-blue-light hover:tw-filter hover:tw-drop-shadow-blue-dark focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary focus:tw-ring-offset-1 dark:focus:tw-ring-offset-darker" />
            </div>
            {/*<img src={imageURL} />*/}
        </>
    )

}

export default AvatarEdit