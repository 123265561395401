import React from 'react';
import {useAppContext} from "../libs/contextLib";
import {useHistory} from "react-router-dom";

export default function withUseAppContext(Component) {
    return function (props) {
        const {userHasAuthenticated, authenticatedUser, setAuthenticatedUser} = useAppContext() || {};
        const history = useHistory();
        return <Component userHasAuthenticated={userHasAuthenticated}
                          authenticatedUser={authenticatedUser}
                          setAuthenticatedUser={setAuthenticatedUser}
                          history={history} {...props} />;
    };
}
