import React from "react";
import {Price} from "../Price";
import {PortfolioActions} from "./FriendEnums";

function formatToDate(dateString) {
    if (dateString === null || dateString === undefined) return '';

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return new Date(year, month - 1, day).toLocaleDateString();
}

export const AuditItem = ({
                              index,
                              item
                          }) => {

    return (
        <tr>
            <td className="tw-col-span-2">
                <span className="tw-font-semibold">{item.username}</span>
                <span className="tw-text-primary-purple">
                    {item.action === PortfolioActions.ADDED && ' added '}
                    {item.action === PortfolioActions.UPDATED && ' updated '}
                    {item.action === PortfolioActions.REMOVED && ' removed '}
                </span>
                <span className="tw-font-semibold">{item.tickerSymbol}</span>
            </td>
            <td>
                {item.action === PortfolioActions.REMOVED ? '' : item.quantity}
            </td>
            <td>
                {item.action === PortfolioActions.REMOVED ? '' :
                    <Price showSymbol={true} numberStyle={`currency`}>{item.purchasePrice}</Price>
                }
            </td>
            <td>
                {item.action === PortfolioActions.ADDED || item.action === PortfolioActions.UPDATED ?
                    formatToDate(item.tradeDate) : ''
                }
                {item.action === PortfolioActions.REMOVED ?
                    new Date(item.createdDate).toLocaleDateString() : ''
                }
            </td>
        </tr>
    );
};
